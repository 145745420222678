import React, { useState, useRef } from "react";
import {
  CustomForm,
  CustomInput,
  CustomNumberInput2,
  CustomSizeM2,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleDeleteNew,
  handlEdit,
  handleRoomChange,
  // handleRoomSizeError,
  handleSaveRoom,
} from "./commonFunctions";
import { Toast } from "primereact/toast";
import {
  deleteRoomAction,
  getRoomAction,
} from "store/actions/buildingUnitActions";
import { useDispatch } from "react-redux";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import CrossIcon from "assets/images/svg/cross-icon.svg"
import SaveIcon from "assets/images/svg/Save-icon.svg"
import SizeM2 from "assets/images/svg/SizeM2.svg"
 
const AddRooms = ({
  // type,
  allRooms,
  setAllRooms,
  // errors,
  // setErrors,
  maxSize,
}) => {
  const msgs = useRef(null);
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const roomInputObj = {
    id: "",
    doors: 1,
    size: "",
    name: "",
    status: "",
  };

  // const errorObj = {
  //   doors: "",
  //   size: "",
  // };

  const ignoreInputs = ["status", "id"];
  const optional = ["name"];
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected energy meter from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteRoom = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteRoomAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(allRooms, setAllRooms, idToDelete?.index);
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allRooms, setAllRooms, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getRoomDetails = (id, index) => {
    dispatch(
      getRoomAction(id, (res) => {
        const info = res?.data?.results;
        if (res?.success) {
          handleCancelNew(info, index, allRooms, setAllRooms);
        }
      })
    );
  };

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(roomInputObj, allRooms, setAllRooms)}
      />
    </div>
  );

  return (
    <>
      <CustomForm title={"Rooms"} header={header}>
        <Toast ref={msgs} />
        <div className="overflow-auto">
          <table className="financial-table editor-container w-12 mt-3 table-error-align">
            <thead>
              <tr>
                <th>Room ID</th>
                <th>Doors</th>
                <th>Size </th>
                <th>Name (optional)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="" className="cell-width">
              {allRooms?.map((room, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td className={`pl-3 ${room.status === 'save' ? 'p-disabled' : ''}`}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                      <td>
                        <CustomNumberInput2
                          data={room}
                          col={12}
                          max={9}
                          onChange={(e) =>
                            handleRoomChange(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allRooms,
                              setAllRooms,
                              ignoreInputs,
                              optional,
                              maxSize
                            )
                          }
                          name="doors"
                          disabled={room?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomSizeM2
                          data={room}
                          col={12}
                          max={9}
                          onChange={(e) => {
                            handleRoomChange(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allRooms,
                              setAllRooms,
                              ignoreInputs,
                              optional,
                              maxSize
                            );
                          }}
                          name="size"
                          disabled={room?.status === "save" ? true : false}
                          ignoreLabel
                          spanIcon={SizeM2}
                        />
                      </td>
                      <td>
                        <CustomInput
                          data={room}
                          col={12}
                          onChange={(e) =>
                            handleRoomChange(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allRooms,
                              setAllRooms,
                              ignoreInputs,
                              optional,
                              maxSize
                            )
                          }
                          name="name"
                          disabled={room?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      {room?.status === "save" ? (
                        <td style={{ "minWidth": "20px" }}>
                          <CustomOverlay ref={openAction}>
                            <ul className="no-style p-0">
                              {/* {type === "Edit" && ( */}
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(allRooms, setAllRooms, index)
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              {/* )} */}
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  setIdToDelete({ id: room?.id, index: index });
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td style={{ "minWidth": "20px" }}>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() =>
                                handleSaveRoom(
                                  index,
                                  allRooms,
                                  setAllRooms,
                                  ignoreInputs,
                                  optional,
                                  maxSize
                                )
                              }
                            >
                             <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (room.id) {
                                  getRoomDetails(room.id, index);
                                } else {
                                  handleDeleteNew(allRooms, setAllRooms, index);
                                }
                              }}
                            >
                              <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>
                    {/* {(room?.formErrors?.doors ||
                      room?.formErrors?.size ||
                      room?.formErrors?.name) && (
                      <tr>
                        <td></td>
                        <td>
                          <small className="p-error">
                            {room?.formErrors?.doors}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {room?.formErrors?.size}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {room?.formErrors?.name}
                          </small>
                        </td>
                      </tr>
                    )} */}
                    <CustomModal
                      isOpen={deleteModal}
                      setIsOpen={setDeleteModal}
                      heading={"Delete"}
                      body={modalContent}
                      submitLabel={"Confirm"}
                      onSubmit={handleDeleteRoom}
                      loading={loading}
                    />
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
    </>
  );
};

export default AddRooms;
