import React from "react";
import { CustomForm } from "shared/AllInputs";
import {
  CustomAmountViewInput,
  CustomNumberViewInput,
  CustomViewInput,
} from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "assets/images/icons/editicon.png";
import { handleFormatDate } from "utils/commonFunctions";
import CustomMaturityTable from "../Tables/CustomMaturityTable";
import { canAccess } from "services/auth";
import ViewContractAndUnitFiles from "./ViewContractAndUnitFiles";
import EuroIcon from "assets/images/svg/euroIcon.svg";
// import ViewBankDetails from "./ViewBankDetails";
import ViewFurniture from "./ViewFurniture";
import CustomBankAccountTable from "views/Tables/CustomBankAccountTable";
import SizeM2 from "assets/images/svg/SizeM2.svg";
import MonthsIcon from "assets/images/svg/MonthsIcon.svg"

const ViewContractualInfo = ({
  type,
  data,
  // propertyId,
  allMaturities,
  setAllMaturities,
  allBankAccounts,
  setAllBankAccounts,
  allFurniture,
  setAllFurniture,
  landlordId
}) => {
  const navigate = useNavigate();
  const params = useParams();
  // const maturityColumns = useMemo(
  //   () => [
  //     {
  //       name: "Extension",
  //       accessor: "extension",
  //     },
  //     {
  //       name: "Notice Period",
  //       accessor: "notice_period",
  //     },
  //   ],
  //   []
  // );

  // const actions = () => {
  //   return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  // };

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(
              `/real-estate/property/edit/contractual-info/${params?.id}`
            )
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm
        title="Contractual info"
        header={canAccess("change_property") && header()}
      >
        {/* <Messages className="w-full" ref={msgs} /> */}
        {/* <Toast ref={msgs} /> */}
        <CustomNumberViewInput
          name="existingContract"
          data={data}
          label={"Contract ID"}
        />
        <CustomNumberViewInput
          name="movingDate"
          value={handleFormatDate(data?.movingDate)}
          label={"Moving in date"}
        />
        {/* <CustomViewInput name="estateCompany" data={data} /> */}
        <CustomViewInput
          name="contractualSize"
          data={data}
          spanIcon={SizeM2}
          // spanLabel={
          //   <span>
          //     m<sup>2</sup>
          //   </span>
          // }
        />
        {/* <CustomNumberViewInput
          name="contractualSize"
          data={data}
          label="Contractual Size"
          spanLabel={
            <span>
              m<sup>2</sup>
            </span>
          }
        /> */}
        <CustomNumberViewInput
          name="contractualNo"
          data={data}
          label="Bedroom"
        />
      </CustomForm>

      {/* <ViewBankDetails
        type="property"
        data={data}
        allBankAccounts={allBankAccounts}
        setAllBankAccounts={setAllBankAccounts}
      /> */}
      <CustomBankAccountTable
        action={"property"}
        // id={data?.id}
        landlordId={landlordId}
        title="Banking Details"
        allBankAccounts={allBankAccounts}
        setAllBankAccounts={setAllBankAccounts}
        contractId={data?.id}
      />
      <br />
      <ViewFurniture
        type="property"
        data={data}
        allFurniture={allFurniture}
        setAllFurniture={setAllFurniture}
      />
      <br />
      <CustomForm title="Maturity">
        <CustomViewInput
          name="maturityType"
          data={data}
          label={"Type of Maturity"}
        />
        {data?.maturityType === "None" || data?.maturityType === null ? null : (
          <>
            <CustomNumberViewInput
              name="maturityDate"
              value={handleFormatDate(data?.maturityDate)}
              label="First Maturity Date"
            />
            {data?.maturityType === "Automatic Renewal" && (
              <>
                {" "}
                <CustomNumberViewInput
                  name="maturityExtension"
                  data={data}
                  spanIcon={MonthsIcon}
                />
                <CustomNumberViewInput
                  name="noticePeriod"
                  data={data}
                  spanIcon={MonthsIcon}
                  label="Notice Period"
                />
              </>
            )}
          </>
        )}
      </CustomForm>
      {allMaturities?.length ? (
        <>
          <CustomMaturityTable
            action={type}
            id={data?.id}
            title={"Optional Maturity"}
            allMaturities={allMaturities}
            setAllMaturities={setAllMaturities}
          />
        </>
      ) : null}
      <CustomForm title="Deposits">
        <CustomViewInput name="depositType" data={data} />
        {(data?.depositType != "None" && data?.amount) ? (
          <>
            <CustomAmountViewInput name="amount" data={data} icon={EuroIcon} />
          </>
        ) : (
          data?.depositType != "None" && (
            <CustomAmountViewInput
              inputClass="pl-5"
              name="amount"
              data={data}
            />
          )
        )}
      </CustomForm>
      <ViewContractAndUnitFiles files={data?.contract_documents} />
    </>
  );
};

export default React.memo(ViewContractualInfo);
