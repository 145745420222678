import React from "react";
import PropertyContainer from "./PropertyContainer";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import { TabPanel, TabView } from "primereact/tabview";
import BuildingUnit from "views/CommonComponents/BuildingUnit";
import LandlordInfo from "views/CommonComponents/LandlordInfo";
import ContractualInfoForm from "views/CommonComponents/ContractualInfoForm";
import AddRooms from "shared/Components/AddRooms";
import AddEnergyMeters from "shared/Components/AddEnergyMeters";
import RentAndCost from "views/CommonComponents/RentAndCost";
import AddPropertyForm from "views/CommonComponents/AddPropertyForm";
// import BankingDetailForm from "views/CommonComponents/BankingDetailForm";
// import FurnitureForm from "views/CommonComponents/FurnitureForm";
import AddContacts from "shared/Components/AddContacts";
import PropertyAnnualStatementList from "views/CommonViewComponents/AnnualStatement";
import BuildingStucture from "views/CommonComponents/BuildingStucture";
import LandlordFilesComponent from "views/CommonComponents/LandlordFilesComponent";
import ContractAndUnitFilesComponent from "views/CommonComponents/ContractAndUnitFilesComponents";
import CustomModal from "shared/Modals/CustomModal";

export default function PropertyFrom({ type }) {
  const {
    //common
    loading,
    // skipAble,
    activeIndex,
    handleTabChange,
    disableTabs,
    setTabToActive,

    //add property
    data,
    handleChange,
    onSubmit,
    propertyIdOptions,
    propertyId,
    setSelectedAddress,
    propertyIdChange,

    //building structure
    buildingStuctureData,
    BuildingStuctureHandleChange,
    buildingOptions,
    // fileKey,
    // sturcureDocuments,
    // handleStructureFileChange,
    handleStructureFileDelete,
    isWarning, 
    setIsWarning,
    BuildingStuctureSubmit,

    //building unit
    buildingUnitData,
    BuildingUnitChange,
    handleUnitFileDelete,
    // buildingUnitDocuments,
    // unitfileKey,

    //rooms
    allRooms,
    setAllRooms,

    //energy metters
    allEnergyMeters,
    setAllEnergyMeters,

    //landlord
    landlordOptions,
    LandlordChange,
    landLordData,
    setLandlordAddress,
    landlordIdChange,
    handleLandlordFileDelete,

    //bank accounts
    allBankAccounts,
    setAllBankAccounts,

    //contacts
    allContacts,
    setAllContacts,

    //furniture
    allFurniture,
    setAllFurniture,

    //contractual
    ContractOptions,
    contractualData,
    ContractualChange,
    allMaturities,
    setAllMaturities,
    maturityErrors,
    setMaturityErrors,
    contractIdChange,
    // isContactExist,
    handleContractFileDelete,

    //rent and cost
    rentAndCostData,
    RentAndCostChange,

    //extra cost
    allExtraCost,
    setAllExtraCost,
    navigate,
  } = PropertyContainer();

  return (
    <Page
      title={`${type === "Add" ? "Add New" : "View"} Property`}
      description={`Let’s ${type} your Property Details`}
      navigateLink={"/real-estate/property/list"}
    >
      <CustomCard>
        <AddPropertyForm
          type={type}
          data={data}
          handleChange={handleChange}
          propertyId={propertyId}
          propertyIdOptions={propertyIdOptions}
          activeIndex={activeIndex}
          setSelectedAddress={setSelectedAddress}
          propertyIdChange={propertyIdChange}
        />
        <TabView
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            handleTabChange(e?.index);
            // setTabToActive(e?.index);
            // skipAble.includes(activeIndex)? handleTabChange(e?.index) : onSubmit()
          }}
        >
          <TabPanel header="Building Structure">
            <BuildingStucture
              data={buildingStuctureData}
              handleChange={BuildingStuctureHandleChange}
              buildingOptions={buildingOptions}
              // fileKey={fileKey}
              // sturcureDocuments={sturcureDocuments}
              // fileChange={handleStructureFileChange}
              fileDelete={handleStructureFileDelete}
            />
          </TabPanel>

          <TabPanel header="Building Unit" disabled={disableTabs.includes(1)}>
            <BuildingUnit
              data={buildingUnitData}
              handleChange={BuildingUnitChange}
              // buildingUnitDocuments={buildingUnitDocuments}
              // fileChange={handleStructureFileChange}
              // fileDelete={handleStructureFileDelete}
              // fileKey={unitfileKey}
            />
            <br />
            <AddRooms
              maxSize={buildingUnitData?.size}
              allRooms={allRooms}
              setAllRooms={setAllRooms}
            />
            <br />
            <AddEnergyMeters
              allEnergyMeters={allEnergyMeters}
              setAllEnergyMeters={setAllEnergyMeters}
            />
            <br />
            <ContractAndUnitFilesComponent
              data={buildingUnitData?.building_document}
              handleChange={BuildingUnitChange}
              fileDelete={handleUnitFileDelete}
            />
          </TabPanel>

          <TabPanel header="Landlord Info" disabled={disableTabs.includes(2)}>
            <LandlordInfo
              data={landLordData}
              handleChange={LandlordChange}
              landlordOptions={landlordOptions}
              landlordIdChange={landlordIdChange}
              setLandlordAddress={setLandlordAddress}
            />
            <br />
            {/* <BankingDetailForm
              title="Banking Details"
              data={landLordData}
              handleChange={LandlordChange}
              allBankAccounts={allBankAccounts}
              setAllBankAccounts={setAllBankAccounts}
            />
            <br />
            <FurnitureForm
              title="Landlord Furniture"
              data={landLordData}
              handleChange={LandlordChange}
              allFurniture={allFurniture}
              setAllFurniture={setAllFurniture}
            />
            <br /> */}
            <AddContacts
              title="Contacts"
              allContacts={allContacts}
              setAllContacts={setAllContacts}
              landlordId={landLordData?.id}
              propertyId={propertyId}
              mode="add"
            />
            <br />
            <LandlordFilesComponent
              data={landLordData}
              handleChange={LandlordChange}
              fileDelete={handleLandlordFileDelete}
            />
          </TabPanel>

          <TabPanel header="Contractual Info" disabled={disableTabs.includes(3)}>
            <ContractualInfoForm
              landlordId={landLordData?.id}
              propertyId={propertyId}
              ContractOptions={ContractOptions}
              data={contractualData}
              handleChange={ContractualChange}
              allMaturities={allMaturities}
              setAllMaturities={setAllMaturities}
              maturityErrors={maturityErrors}
              setMaturityErrors={setMaturityErrors}
              contractIdChange={contractIdChange}
              // isContactExist={isContactExist}
              fileDelete={handleContractFileDelete}
              allBankAccounts={allBankAccounts}
              setAllBankAccounts={setAllBankAccounts}
              allFurniture={allFurniture}
              setAllFurniture={setAllFurniture}
            />
            {/* <AddLegalAllowance
              allLegalAllowance={allLegalAllowance}
              setAllLegalAllowance={setAllLegalAllowance}
            /> */}
          </TabPanel>

          <TabPanel header="Rent & Cost" disabled={disableTabs.includes(4)}>
            <RentAndCost
              data={rentAndCostData}
              handleChange={RentAndCostChange}
              allExtraCost={allExtraCost}
              setAllExtraCost={setAllExtraCost}
              movingInDate={contractualData?.movingDate}
              // costErrors={costErrors}
              // setCostErrors={setCostErrors}
            />
          </TabPanel>

          <TabPanel header="Annual Statement" disabled={disableTabs.includes(5)}>
            <PropertyAnnualStatementList propertyId={propertyId} />
          </TabPanel>
        </TabView>
      </CustomCard>
      <Buttonlayout>
        <CustomButton
          onClick={onSubmit}
          label="Save & Next"
          loading={loading}
        />
        <CustomButtonOutlined
          label="Cancel"
          onClick={() => navigate("/real-estate/property/list")}
        />
        {/* {skipAble.includes(activeIndex) && (
          <CustomButtonOutlined
            label="Skip"
            onClick={() => handleTabChange(activeIndex + 1)}
          />
        )} */}
      </Buttonlayout>
      <CustomModal
        isOpen={isWarning}
        setIsOpen={setIsWarning}
        heading={"Warning"}
        body={() => (
          <>Please review and ensure the building information is correct.</>
        )}
        submitLabel={"Continue"}
        onSubmit={BuildingStuctureSubmit}
      />
    </Page>
  );
}
