import React, { useEffect, useState } from "react";
import { CustomDropDown, CustomForm } from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "shared/Components/commonFunctions";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import { getOfficeListAction } from "store/actions/officeActions";
import { PositionOptions } from "shared/AllDropDownOptions";
export default function EmployeeOfficeTable({
  title,
  allValues,
  setAllValues,
  onUpdate,
  onDelete,
}) {
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state?.user);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  useEffect(() => {
    if (profile?.company) {
      getOfficeList(profile?.company);
    }
  }, [profile]);

  const getOfficeList = (company) => {
    dispatch(
      getOfficeListAction(company, (res) => {
        if (res?.success) {
          let options = res?.data.results.map((office) => {
            return { name: office?.name, value: office?.id };
          });
          setOfficeList(options)
          // setOfficeOptions(options);
        }
      })
    );
  };

  const OfficeEditor = (options) => {
    const formErrors = formValidation("office", options.value, allValues);
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="office"
        col={12}
        options={officeOptions}
        ignoreLabel
        errorMessage={formErrors["office"]}
        optionLabel="name"
      />
    );
  };

  const PositionEditor = (options) => {
    const formErrors = formValidation("position", options.value, allValues);
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="position"
        col={12}
        options={PositionOptions}
        ignoreLabel
        errorMessage={formErrors["position"]}
      />
    );
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              className="cursor-pointer"
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <img src={CrossIcon} />
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => {
                    setIdToDelete({
                      id: rowData?.id,
                      index: options?.rowIndex,
                    });
                    setDeleteModal(true);
                  }}
                >
                  <i
                    className="pi pi-trash text-dark"
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const rowEditValidator = async (rowData, options) => {
    const { id, office, position } = rowData;
    const officeError = formValidation("office", office?.value, allValues);
    const positionError = formValidation("position", position, allValues);
    if (!officeError["office"] && !positionError["position"]) {
      let payload = {
        office: office.value,
        position: position,
      };
      onUpdate(id, payload, (res) => {
        if (res.success) {
          const info = res?.data?.results
          let data = {
            id: id,
            position: info?.position,
            office: { name: info?.office?.name, value: info?.office?.id },
          }
          let values = [...allValues];
          values[options.rowIndex] = data;
          setAllValues(values);
          setEditingRows({});
        }
      });
    } else {
      return false;
    }
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const handleDeleteBankAccount = () => {
    onDelete(idToDelete?.id, setLoading, (res) => {
      if (res) {
        handleDelete(allValues, setAllValues, "", "", idToDelete?.index);
        setIdToDelete({ id: "", index: "" });
        setDeleteModal(false);
      }
    });
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected office from the list? This
        action cannot be undone.
      </span>
    );
  };

  useEffect(() => {
    const options = officeList?.map((option) => {
      const isDisabled = allValues?.some(office => office?.office?.value === option.value);
      return {
        ...option,
        disabled: isDisabled,
      };
    });

    setOfficeOptions(options);
  }, [allValues, officeList]);

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12 properties-table-list table-error-align"
        value={allValues}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="office.value"
          header="Office"
          body={(option) => option?.office?.name}
          editor={(options) => OfficeEditor(options)}
          bodyStyle={{ width: "45%" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="position"
          header="Position"
          body={(options) => {
            const position = PositionOptions.find(item => item?.value === options?.position);
            return position ? position.name : null;
          }}
          bodyStyle={{ width: "45%" }}
          editor={(options) => PositionEditor(options)}
        // className="firstletter_capital"
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          body={(rowData, options) => actions(rowData, options)}
          bodyStyle={{ width: "10%" }}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteBankAccount}
        loading={loading}
      />
    </CustomForm>
  );
}
