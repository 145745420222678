import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { Dropdown } from "primereact/dropdown";
import { debounce } from "lodash";
import CustomCard from "shared/CustomCard";
import CustomModal from "shared/Modals/CustomModal";
import {
  CustomDropDown,
  CustomInput,
  CustomRatings,
  CustomSearch,
  CustomTextArea,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomOverlay from "shared/CustomOverlay";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import {
  archiveHandymanAction,
  changeServiceStatusActon,
  // deleteHandymanAction,
  getHandymanListAction,
} from "store/actions/HandymanActions";
import { Tooltip } from "primereact/tooltip";
import { truncateText } from "utils/regex";
import {
  getAllHandymanServiceListAction,
  getSingleHandymanServiceListAction,
} from "store/actions/handymanServiceAction";
import { setHandymanSlice } from "store/slices/handymanSlice";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { canAccess } from "services/auth";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import ArchiveIcon from "assets/images/icons/archive.svg"
import { showToastAction } from "store/slices/commonSlice";

const HandymanList = () => {
  const openAction = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState({ id: "", action: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalFilters = ["name", "service"];
  const [handymanList, setHandymanList] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const [filters2, setFilters2] = useState({
    name: { value: "" },
    service: { value: "" },
  });
  const [filters, setFilters] = useState({
    name: "",
    service: "",
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { handyman } = useSelector((state) => state?.handyman);
  const [serviceList, setServicesList] = useState([]);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [handymanServiceList, setHandymanServiceList] = useState([]);
  const [blockData, setBlockData] = useState({
    service: "",
    reason: "",
  });
  // const [columns, setColumns] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [baseStatus, setBaseStatus] = useState("")
  // {
  //   name: "Address",
  //   accessor: "address",
  //   value: true,
  //   filter: true,
  //   body: (options) => addressBodyTemplate(options),
  //   filterElement: (option) => AddressFilter(option),
  // },
  const columnOptionsInitial = useMemo(
    () => [
      // { selectionMode: 'multiple', accessor: '', value: true, name: "" },
      {
        name: "Name",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        disabled: true,
        filterElement: (option) => NameFilter(option),
      },

      {
        name: "Services",
        accessor: "service",
        value: true,
        filter: true,
        filterMatchMode: "in",
        body: (options) => serviceBodyTemplate(options),
        filterElement: (options) => ServiceTypeFilter(options, serviceList),
      },
      {
        name: "Ratings",
        accessor: "rating",
        sortable: false,
        value: true,
        body: (options) => RatingsBodyTemplate(options),
      },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zipcode", value: true },
      { name: "City", accessor: "city", value: true },
      { name: "City Division", accessor: "division", value: true },
      { name: "Country", accessor: "country", value: true },
    ],
    [serviceList, handymanList, serviceList, handyman]
  );

  const [columns, setColumns] = useState([]);
  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  useEffect(() => {
    setColumnOptions(columnOptionsInitial);
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, [columnOptionsInitial]);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  useEffect(() => {
    if (profile?.company) {
      getServiceList();
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex align-items-center justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-7 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.name?.value,
                filters?.address?.value,
                filters?.service?.value,
                baseStatus
              );
            }}
            isSearch={isSearch}
            placeholder="Search Handyman"
          />
        </div>
        {canAccess("add_handyman") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Handyman"
            extraClassNames="mr-3"
            onClick={() => navigate("/real-estate/maintenance/handyman/add")}
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-download cursor-pointer text-dark"></i>
              Import
            </li>
            <li className="flex gap-2 text-xs font-medium mb-3">
              <i className="pi pi-upload cursor-pointer text-dark"></i> Export
            </li>
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedItems([])
                getHandymanList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.name?.value,
                  // filters?.address,
                  filters?.service?.value,
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedItems?.length || 0})`}
              </li>
            </> : <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("archived")
                setSelectedItems([])
                getHandymanList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.name?.value,
                  // filters?.address,
                  filters?.service?.value,
                  "archived"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Show Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedItems?.length || 0})`}
              </li>
            </>}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const NameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          // value={filters.name}
          name="name"
          onChange={({ name, value }) => {
            setFilters((prev) => ({ ...prev, name: value }));
            options.filterCallback(value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const ServiceTypeFilter = (options, list) => {
    return (
      <div>
        <CustomDropDown
          col={12}
          value={options.value}
          options={list}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, service: e.value }));
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const RatingsBodyTemplate = (options) => {
    const tooltipId = `ratings-tooltip-${options?.id}`;
    return (
      <div>
        <Tooltip
          className="tooltip"
          position="top"
          target={`.${tooltipId}`}
          content={options.rating?.average_rating || 0}
        />
        <span
          className={`${tooltipId} cursor-pointer`}
          data-pr-classname="tooltip"
        >
          <span
            className={{
              color: selectedItems.some((item) => item?.id == options?.id)
                ? "text-blue-700"
                : null,
            }}
          >
            <CustomRatings
              value={options.rating?.average_rating || 0}
              name="rating"
              disabled
              cancel={false}
              ignoreLabel={true}
              count={options.rating?.count || 0}
            />
          </span>
        </span>
      </div>
    );
  };

  // const AddressFilter = (options) => {
  //   return (
  //     <div>
  //       <CustomInput
  //         col={12}
  //         value={filters?.address}
  //         name="address"
  //         onChange={(e) => {
  //           // options.filterCallback(e.value);
  //           setFilters((prev) => ({ ...prev, address: e.value }));
  //         }}
  //         optionLabel="name"
  //         className="p-column-filter"
  //         ignoreLabel
  //       />
  //     </div>
  //   );
  // };

  const serviceBodyTemplate = (options) => {
    const tooltipId = `service-tooltip-${options?.id}`;
    return (
      <>
        <Tooltip
          className="tooltip"
          target={`.${tooltipId}`}
          content={options?.service}
        />
        <span
          className={`${tooltipId} cursor-pointer`}
          data-pr-classname="tooltip"
        >

          {truncateText(options?.service, 20)}
        </span>
      </>
    );
  };

  // const addressBodyTemplate = (options) => {
  //   const tooltipId = `address-tooltip-${options?.id}`;
  //   return (
  //     <div>
  //       <Tooltip
  //         className="tooltip"
  //         target={`.${tooltipId}`}
  //         content={options?.address}
  //       />
  //       <span
  //         className={tooltipId}
  //         data-pr-classname="tooltip"
  //         style={{ cursor: "pointer" }}
  //       >
  //         <span
  //         // style={{
  //         //   color: options?.id === handyman?.id ? "white" : null,
  //         // }}
  //         >
  //           {truncateText(options?.address, 20)}
  //         </span>
  //       </span>
  //     </div>
  //   );
  // };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/maintenance/handyman/view/${d.id}`);
              dispatch(setHandymanSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              getSingleHandymanServiceList(d?.id);
              setIsBlockModal(true);
            }}
          >
            <i className="pi pi-ban text-dark"></i> Block
          </li>
          {/* {canAccess("delete_handyman") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            >
              <i className="pi pi-trash text-dark"></i> Delete
            </li>
          )} */}
          {canAccess("delete_handyman") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete({ id: [d?.id], action: d?.base_status === "archived" ? "restore" : "archived" });
                setDeleteModal(true);
              }}
            >
              <img src={ArchiveIcon} />
              {d?.base_status === "archived" ? "Unarchive" : "Archive"}
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to  ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "handymans" : "handyman"} from the list?`}
      </span>
    );
  };

  const handleHandymanDelete = () => {
    dispatch(
      archiveHandymanAction(
        profile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getHandymanList(profile?.company, rows, page, search, "", "", baseStatus);
          setSelectedItems([])
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  const getHandymanList = (
    company,
    rows,
    page,
    search,
    name,
    // address,
    service,
    baseStatus
  ) => {
    dispatch(
      getHandymanListAction(
        company,
        rows,
        page,
        search,
        name,
        // address,
        service,
        baseStatus,
        (res) => {
          const formattedData = res?.options.map((option) => ({
            ...option,
            // rating: option.rating?.average_rating,
            service: option.service.map((s) => s.name).join(", "),
          }));
          setHandymanList(formattedData);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/maintenance/handyman/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    name,
    // address,
    service,
    baseStatus
  ) => {
    setIsSearch(true);
    getHandymanList(
      company,
      rows,
      page,
      search,
      name,
      // address,
      service,
      baseStatus
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getHandymanList(
        profile?.company,
        rows,
        page,
        search,
        filters?.name?.value,
        // filters?.address,
        filters?.service?.value,
        baseStatus
      );
    }
  }, [
    profile?.company,
    // rows,
    // page,
    // first,
    // filters?.name?.value,
    // filters?.address?.value,
    // filters?.service?.value,
  ]);

  const onPageChange = (e) => {
    // navigate(
    //   `/real-estate/maintenance/handyman/list?page=${e.page + 1}&rows=${e.rows}`
    // );
    getHandymanList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.name,
      // filters?.address,
      filters?.service,
      baseStatus
    );
    setFirst(e.first);
  };

  const handleServiceChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, blockData);
    setBlockData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.name,
          };
        });
        setServicesList(options);
      })
    );
  };

  const getSingleHandymanServiceList = (id) => {
    dispatch(
      getSingleHandymanServiceListAction(id, (res) => {
        const options = res?.map((item) => {
          return {
            name: item?.service?.name,
            value: item?.id,
          };
        });
        setHandymanServiceList(options);
      })
    );
  };

  const handleChangeServiceStatus = () => {
    if (showFormErrors(blockData, setBlockData, ["reason"])) {
      dispatch(
        changeServiceStatusActon(
          blockData.service,
          "Block",
          blockData?.reason,
          setLoading,
          dispatch,
          (res) => {
            setIsBlockModal(false);
            setBlockData({
              service: "",
              reason: "",
            });
          }
        )
      );
    }
  };

  const blockModalContent = () => {
    return (
      <>
        <CustomDropDown
          data={blockData}
          onChange={handleServiceChange}
          name="service"
          col={12}
          options={handymanServiceList}
          // label={"Select Service"}
          required
        />
        <CustomTextArea
          extraClassName="w-full"
          data={blockData}
          onChange={handleServiceChange}
          name="reason"
          label="Write a reason for the block?"
        />
      </>
    );
  };

  const cancelBlockService = () => {
    setBlockData({
      service: "",
      reason: "",
    });
  };

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getHandymanList(
      profile?.company,
      rows,
      page,
      "",
      filters?.name,
      filters?.service,
      baseStatus
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getHandymanList(
      profile?.company,
      rows,
      page,
      "",
      field === "name" ? "" : filters?.name,
      field === "service" ? "" : filters?.service,
      baseStatus
    );
    options.filterClearCallback("");
  };

  const archiveUnarchiveMultiple = (items, action) => {
    if (items?.length) {
      const selectedIds = items?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    } else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
      }))
    }
  }

  return (
    <div>
      <CustomCard title="Handyman List" header={header()}>
        <CustomTableContainer
          list={handymanList?.length ? handymanList : []}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={handyman?.id}
          slice={setHandymanSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters2}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Name"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleHandymanDelete}
        loading={loading}
      />
      <CustomModal
        isOpen={isBlockModal}
        setIsOpen={setIsBlockModal}
        heading={"Block Service"}
        body={blockModalContent}
        submitLabel={"Save"}
        onSubmit={handleChangeServiceStatus}
        onCancel={cancelBlockService}
        loading={loading}
      />
    </div>
  );
};

export default HandymanList;
