// For Property landlord contacts
export const initialContactsInput = {
    type: "",
    name: "",
    role: "",
    mobile: "",
    office_phone: "",
    email: "",
    property: [],
}

//tenant family 
export const InitialFamilyInputs = (id) => {
    return {
        id:"",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        tenant: id || null,
        status: ""
    }
}