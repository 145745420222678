import Dashboard from "views/Dashboard";
import AddEmployee from "views/Administrator/Employees/Employee/AddEmployee";
import EmployeesList from "views/Administrator/Employees/EmployeesList";
import ViewEmployee from "views/Administrator/Employees/Employee/ViewEmployee";
import AddOffice from "views/Administrator/Office/OfficeForms/AddOffice";
import OfficeList from "views/Administrator/Office/OfficeList";
import AddOfficeUnit from "views/Administrator/OfficeUnit/OfficeUnitForms/AddOfficeUnit";
import OfficeUnitList from "views/Administrator/OfficeUnit/OfficeUnitList";

import Reports from "views/Reports";
import AddRole from "views/Administrator/Roles/RolesForm/AddRoles";
import RolesList from "views/Administrator/Roles/RolesList";
import AddTenant from "views/Tenants/TenantsForm/AddTenant";
import TenantsList from "views/Tenants/TenantsList";
import EditEmployee from "views/Administrator/Employees/Employee/EditEmployee";
import ViewOfficeUnit from "views/Administrator/OfficeUnit/OfficeUnitForms/ViewOfficeUnit";
import EditOfficeUnit from "views/Administrator/OfficeUnit/OfficeUnitForms/EditOfficeUnit";
import ViewOffice from "views/Administrator/Office/OfficeForms/ViewOffice";
import EditOffice from "views/Administrator/Office/OfficeForms/EditOffice";
import ViewRole from "views/Administrator/Roles/RolesForm/ViewRoles";
import EditRoles from "views/Administrator/Roles/RolesForm/EditRoles";
import PropertyList from "views/RealEstate/Properties/PropertyList";
import AddProperty from "views/RealEstate/Properties/PropertyForms/AddProperty";
import ViewEditProperty from "views/RealEstate/Properties/ViewPropertyForms/EditViewProperty";
import ContractList from "views/RealEstate/Contract/ContractList";
import BuildingList from "views/RealEstate/Building/BuildingList";
import AddContract from "views/RealEstate/Contract/ContractForms/AddContract";
import ViewContractForm from "views/RealEstate/Contract/ViewContractForms/ViewContractForm";
import EditContract from "views/RealEstate/Contract/ContractForms/EditContract";
import AddBuilding from "views/RealEstate/Building/BuildingForms/AddBuilding";
import EditBuilding from "views/RealEstate/Building/BuildingForms/EditBuilding";
import ViewBuilding from "views/RealEstate/Building/BuildingForms/ViewBuilding";
import LandlordList from "views/RealEstate/Landlord/LandlordList";
import AddLandlord from "views/RealEstate/Landlord/LandlordForms/AddLandlord";
import ViewLandlord from "views/RealEstate/Landlord/LandlordForms/ViewLandlord";
import HandymanServiceList from "views/Settings/HandymanServices/HandymanServiceList";
import HandymanList from "views/RealEstate/Maintenance/Handyman/HandymanList";
import AddHandyman from "views/RealEstate/Maintenance/Handyman/HandymanForms/AddHandyman";
import EditHandyman from "views/RealEstate/Maintenance/Handyman/HandymanForms/EditHandyman";
import EditLandlord from "views/RealEstate/Landlord/LandlordForms/EditLandlord";
// import ViewHandymanForms from "views/RealEstate/Maintenance/Handyman/ViewHandymanForms/ViewHandymanForm";
import ViewHandyman from "views/RealEstate/Maintenance/Handyman/HandymanForms/ViewHandymanForm";
// import DamageList from "views/RealEstate/Maintenance/Damage/DamageList";
// import AddDamage from "views/RealEstate/Maintenance/Damage/DamageForms/AddDamageReport";
import DamageHistory from "views/RealEstate/Maintenance/Damage/DamageHistory";
import DamageReportList from "views/RealEstate/Maintenance/Damage/DamageList";
// import ViewDamage from "views/RealEstate/Maintenance/Damage/DamageForms/ViewDamageReport";
import AddDamageReport from "views/RealEstate/Maintenance/Damage/DamageForms/AddDamageReport";
import AddDamageForm from "views/CommonComponents/AddDamageForm";
import ViewDamageReport from "views/RealEstate/Maintenance/Damage/DamageForms/ViewDamageReport";
import AdvancedFilterDemo from "views/RealEstate/Maintenance/Damage/advancefilter";
import MaintenaceList from "views/RealEstate/Maintenance/Maintenance/MaintenanceList";
import ViewMaintenance from "views/RealEstate/Maintenance/Maintenance/MaintenanceForms/ViewMaintenance";
import AnnualStatementList from "views/RealEstate/AnnualStatement/AnnualStatementList";
import AddAnnualStatement from "views/RealEstate/AnnualStatement/AnnualStatementForms/AddAnnualStatement";
import ViewAnnualStatement from "views/RealEstate/AnnualStatement/AnnualStatementForms/ViewAnnualStatement";
import TerminateContract from "views/RealEstate/Properties/TerminateContract";
import ViewTenantForm from "views/Tenants/ViewTenantsForm/ViewTenantForm";
import ServicesSetupList from "views/Settings/ServicesSetupList";

export const PrivateRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    codename: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "/reports",
    name: "Reports",
    codename: "",
    element: <Reports />,
  },
  {
    path: "/tenants/list",
    name: "Tenants List",
    codename: "view_tenant",
    element: <TenantsList />,
  },
  {
    path: "/tenants/add",
    name: "Add Tenant",
    codename: "add_tenant",
    element: <AddTenant />,
  },
  {
    path: "/settings/services-setups",
    name: "Services List",
    codename: "view_services",
    element: <ServicesSetupList />,
  },
  {
    path: "/tenants/view/:id",
    name: "View Tenant",
    codename: "view_tenant",
    element: <ViewTenantForm />,
  },
  {
    path: "/administrator/office-unit/list",
    name: "Office Unit List",
    codename: "view_officeunit",
    element: <OfficeUnitList />,
  },
  {
    path: "/administrator/office-unit/add",
    name: "Add Office Unit",
    codename: "add_officeunit",
    element: <AddOfficeUnit />,
  },
  {
    path: "/administrator/office-unit/view/:id",
    name: "View Office Unit",
    codename: "view_officeunit",
    element: <ViewOfficeUnit />,
  },
  {
    path: "/administrator/office-unit/edit/:id",
    name: "Edit Office Unit",
    codename: "change_officeunit",
    element: <EditOfficeUnit />,
  },
  {
    path: "/administrator/office/list",
    name: "Office List",
    codename: "view_office",
    element: <OfficeList />,
  },
  {
    path: "/administrator/office/view/:id",
    name: "View Office",
    codename: "view_office",
    element: <ViewOffice />,
  },
  {
    path: "/administrator/office/add",
    name: "Add Office",
    codename: "add_office",
    element: <AddOffice />,
  },
  {
    path: "/administrator/office/edit/:id",
    name: "Edit Office",
    codename: "change_office",
    element: <EditOffice />,
  },
  {
    path: "/administrator/employees/list",
    name: "Employees List",
    codename: "view_employee",
    element: <EmployeesList />,
  },
  {
    path: "/administrator/employees/add",
    name: "Add Employee",
    codename: "add_employee",
    element: <AddEmployee />,
  },
  {
    path: "/administrator/employees/view/:id",
    name: "View Employee",
    codename: "view_employee",
    element: <ViewEmployee />,
  },
  {
    path: "/administrator/employees/edit/:id",
    name: "Edit Employee",
    codename: "change_employee",
    element: <EditEmployee />,
  },
  {
    path: "/administrator/roles/list",
    name: "Roles List",
    codename: "view_companyrole",
    element: <RolesList />,
  },
  {
    path: "/administrator/roles/add",
    name: "Add Role ",
    codename: "add_companyrole",
    element: <AddRole />,
  },
  {
    path: "/administrator/roles/view/:id",
    name: "View Role ",
    codename: "view_companyrole",
    element: <ViewRole />,
  },
  {
    path: "/administrator/roles/edit/:id",
    name: "Edit Role ",
    codename: "change_companyrole",
    element: <EditRoles />,
  },
  {
    path: "/real-estate/property/list",
    name: "Property List",
    codename: "view_property",
    element: <PropertyList />,
  },
  //add property
  {
    path: "/real-estate/property/add/building-structure",
    name: "Add Property",
    codename: "add_property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/building-unit",
    name: "Add Property",
    codename: "add_property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/landlord-info",
    name: "Add Property",
    codename: "add_property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/contractual-info",
    name: "Add Property",
    codename: "add_property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/rent-and-cost",
    name: "Add Property",
    codename: "add_property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/annual-statement",
    name: "Add Property",
    codename: "add_property",
    element: <AddProperty />,
  },
  //view property
  {
    path: "/real-estate/property/view/property/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/building-structure/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/building-unit/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/landlord-info/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/contractual-info/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/rent-and-cost/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/annual-statement/:id",
    name: "View Property",
    codename: "view_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/terminate-contract/:id",
    name: "Terminate Contract",
    codename: "view_property",
    element: <TerminateContract />,
  },

  //edit property
  {
    path: "/real-estate/property/edit/property/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/building-structure/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/building-unit/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/landlord-info/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/contractual-info/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/rent-and-cost/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/annual-statement/:id",
    name: "Edit Property",
    codename: "change_property",
    element: <ViewEditProperty />,
  },

  //contract
  {
    path: "/real-estate/contract/list",
    name: "Contract List",
    codename: "view_contract",
    element: <ContractList />,
  },
  {
    path: "/real-estate/contract/add",
    name: "Add Contract",
    codename: "add_contract",
    element: <AddContract />,
  },
  {
    path: "/real-estate/contract/view/:id",
    name: "View Contract",
    codename: "view_contract",
    element: <ViewContractForm />,
  },
  {
    path: "/real-estate/contract/view/:id/:page",
    name: "View Contract",
    codename: "view_contract",
    element: <ViewContractForm />,
  },
  {
    path: "/real-estate/contract/edit/:id",
    name: "Edit Contract",
    codename: "change_contract",
    element: <EditContract />,
  },

  //Building
  {
    path: "/real-estate/building/list",
    name: "building List",
    codename: "view_building",
    element: <BuildingList />,
  },
  {
    path: "/real-estate/building/add",
    name: "Add Building",
    codename: "add_building",
    element: <AddBuilding />,
  },
  {
    path: "/real-estate/building/view/:id",
    name: "View Building",
    codename: "view_building",
    element: <ViewBuilding />,
  },
  {
    path: "/real-estate/building/edit/:id",
    name: "Edit Building",
    codename: "change_building",
    element: <EditBuilding />,
  },
  //Landlord
  {
    path: "/real-estate/landlord/list",
    name: "Landlord List",
    codename: "view_landlord",
    element: <LandlordList />,
  },
  {
    path: "/real-estate/landlord/add",
    name: "Add Landlord",
    codename: "add_landlord",
    element: <AddLandlord />,
  },
  {
    path: "/real-estate/landlord/edit/:id",
    name: "Edit Landlord",
    codename: "change_landlord",
    element: <EditLandlord />,
  },
  {
    path: "/real-estate/landlord/view/:id",
    name: "View Landlord",
    codename: "view_landlord",
    element: <ViewLandlord />,
  },

  //Handyman
  {
    path: "/settings/handyman-services",
    name: "Handyman Services",
    codename: "view_services",
    element: <HandymanServiceList />,
  },
  {
    path: "/real-estate/maintenance/handyman/list",
    name: "Handyman List",
    codename: "view_handyman",
    element: <HandymanList />,
  },
  {
    path: "/real-estate/maintenance/handyman/add",
    name: "Add Handyman",
    codename: "add_handyman",
    element: <AddHandyman />,
  },
  {
    path: "/real-estate/maintenance/handyman/view/:id",
    name: "View Handyman",
    codename: "view_handyman",
    element: <ViewHandyman />,
  },
  {
    path: "/real-estate/maintenance/handyman/edit/:id",
    name: "Edit Handyman",
    codename: "change_handyman",
    element: <EditHandyman />,
  },

  //damage report
  {
    path: "/real-estate/maintenance/damage/list",
    name: "Damage List",
    codename: "view_damagereport",
    element: <DamageReportList />,
  },
  {
    path: "/real-estate/maintenance/damage-report/add",
    name: "Add Damage",
    codename: "add_damagereport",
    element: <AddDamageReport />,
  },
  {
    path: "/real-estate/maintenance/damage-report/view/:id",
    name: "View Damage",
    codename: "view_damagereport",
    element: <ViewDamageReport />,
  },

  //damage
  {
    path: "/real-estate/maintenance/damage-report/damage/edit/:id",
    name: "Edit Damage",
    codename: "change_damage",
    element: <AddDamageForm />,
  },
  // {
  //   path: "/real-estate/maintenance/damage/report-damage",
  //   name: "Damage History",
  // codename: "",//   
  // element: <AddDamage />,
  // },

  //damage history
  {
    path: "/real-estate/maintenance/damage/damage-history/:id",
    name: "Damage History",
    codename: "view_damagereport",
    element: <DamageHistory />,
  },
  {
    path: "/real-estate/maintenance/damage-report/advance",
    name: "Damage History",
    codename: "",
    element: <AdvancedFilterDemo />,
  },

  //maintenance
  {
    path: "/real-estate/maintenance/list",
    name: "Maintenance List",
    codename: "view_damage",
    element: <MaintenaceList />,
  },
  {
    path: "/real-estate/maintenance/view/:id",
    name: "Maintenance List",
    codename: "view_damage",
    element: <ViewMaintenance />,
  },

  //annualstatement
  {
    path: "/real-estate/annualstatement/list",
    name: "Maintenance List",
    codename: "view_annualstatement",
    element: <AnnualStatementList />,
  },
  {
    path: "/real-estate/annualstatement/add",
    name: "Add Maintenance",
    codename: "add_annualstatement",
    element: <AddAnnualStatement />,
  },
  {
    path: "/real-estate/annualstatement/add/:propertyId",
    name: "Add Maintenance",
    codename: "change_property",
    element: <AddAnnualStatement />,
  },
  {
    path: "/real-estate/annualstatement/view/:id",
    name: "View Maintenance",
    codename: "view_annualstatement",
    element: <ViewAnnualStatement />,
  },
  {
    path: "/real-estate/annualstatement/view/:id/:propertyId",
    name: "View Maintenance",
    codename: "view_property",
    element: <ViewAnnualStatement />,
  },
];
