import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import {
  CustomCalander,
  CustomDropDown,
  CustomInput,
  CustomSearch,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { canAccess } from "services/auth";
import {
  employeeArchiveAction,
  // employeeDeleteAction,
  employeesListAction,
} from "store/actions/EmployeeActions";
import { useDispatch, useSelector } from "react-redux";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { debounce } from "lodash";
// import { PositionOptions } from "shared/AllDropDownOptions";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { handleFormatDate, reformatDateString } from "utils/commonFunctions";
import { getRolesListAction } from "store/actions/rolesActions";
import { setEmployeeSlice } from "store/slices/employeeSlice";
import ArchiveIcon from "assets/images/icons/archive.svg"
import { showToastAction } from "store/slices/commonSlice";

const EmployeesList = () => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState(null);
  // const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [employeesList, setEmployeesList] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", action: "" });
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [sortField, setSortField] = useState({ field: "", order: "" });
  const [search, setSearch] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { employee } = useSelector((state) => state.employee);
  const [baseStatus, setBaseStatus] = useState("")
  const globalFilters = [
    "employee_id",
    "user__email",
    "user__first_name",
    "user__phone_number",
    "role__name",
    // "position",
    "created_at",
  ];
  const [filters2, setFilters2] = useState({
    employee_id: { value: "" },
    name: { value: "" },
    user__email: { value: "" },
    user__first_name: { value: "" },
    user__phone_number: { value: "" },
    role__name: { value: "" },
    // position: { value: "" },
    created_at: { value: "" },
  });
  const [filters, setFilters] = useState({
    employee_id: "",
    user__email: "",
    user__first_name: "",
    user__phone_number: "",
    role__name: "",
    // position: "",
    created_at: "",
  });

  useEffect(() => {
    if (profile?.company) {
      handleGetRolesList(profile?.company);
    }
  }, [profile]);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const ActionTemplate = () => {
    return (
      <>
        <p className="flex gap-2 align-items-center">Action</p>
      </>
    );
  };

  const handleGetRolesList = (company) => {
    dispatch(
      getRolesListAction(company, dispatch, (res) => {
        if (res?.success) {
          let options = res?.data?.results.map((role) => {
            return { name: role?.name, value: role?.id };
          });
          setRolesList(options);
        }
      })
    );
  };

  const EmployeeIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="employee_id"
          // onChange={handleChange}
          onChange={({ name, value }) => {
            handleChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          placeholder="Enter Employee ID"
          ignoreLabel
        />
      </div>
    );
  };

  const EmailFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="user__email"
          // onChange={handleChange}
          onChange={({ name, value }) => {
            handleChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          placeholder="Enter Email"
          ignoreLabel
        />
      </div>
    );
  };

  const NameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="user__first_name"
          // onChange={handleChange}
          onChange={({ name, value }) => {
            handleChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          placeholder="Enter Name"
          ignoreLabel
        />
      </div>
    );
  };

  const PhoneNoFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="user__phone_number"
          onChange={({ name, value }) => {
            handleChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          placeholder="Enter Phone Number"
          ignoreLabel
        />
      </div>
    );
  };

  const RoleFilter = (options, list) => {
    return (
      <div>
        <CustomDropDown
          col={12}
          value={options.value}
          options={list}
          onChange={({ name, value }) => {
            handleChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          name="role__name"
          optionLabel="name"
          placeholder="Select Role"
          className="p-column-filter"
        />
      </div>
    );
  };

  // const PositionFilter = () => {
  //   return (
  //     <div>
  //       <CustomDropDown
  //         col={12}
  //         data={filters}
  //         options={PositionOptions}
  //         onChange={handleChange}
  //         name="position"
  //         optionLabel="name"
  //         placeholder="Type of Position"
  //         className="p-column-filter"
  //       />
  //     </div>
  //   );
  // };

  const ActiveFromFilter = (options) => {
    return (
      <div>
        <CustomCalander
          col={12}
          value={options.value}
          name="created_at"
          onChange={({ name, value }) => {
            handleChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          optionLabel="name"
          placeholder="Select Active From"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Employee ID",
        accessor: "employee_id",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (options) => EmployeeIDFilter(options),
      },
      {
        name: "Name",
        accessor: "user__first_name",
        value: true,
        filter: true,
        filterElement: (options) => NameFilter(options),
      },
      {
        name: "Email",
        accessor: "user__email",
        value: true,
        filter: true,
        filterElement: (options) => EmailFilter(options),
      },
      {
        name: "Phone No",
        accessor: "user__phone_number",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => PhoneNoFilter(options),
      },
      {
        name: "Role",
        accessor: "role__name",
        value: true,
        filter: true,
        filterElement: (options) => RoleFilter(options, rolesList),
      },
      // {
      //   name: "Position",
      //   accessor: "position",
      //   value: true,
      //   filter: true,
      //   filterElement: () => PositionFilter(),
      // },
      {
        name: "Active From",
        accessor: "created_at",
        value: true,
        filter: true,
        filterElement: (options) => ActiveFromFilter(options),
      },
    ],
    [rolesList, filters, employeesList]
  );

  const handleChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const getEmployeesList = (
    company,
    rows,
    page,
    search,
    id,
    email,
    name,
    phoneNumber,
    role,
    // position,
    createdAt,
    baseStatus
  ) => {
    dispatch(
      employeesListAction(
        company,
        rows,
        page,
        search,
        id,
        email,
        name,
        phoneNumber,
        role,
        // position,
        createdAt,
        baseStatus,
        (res) => {
          if (res?.success) {
            let options = res?.data.results?.map((employee) => {
              return {
                ...employee,
                id: employee?.id,
                employee_id: employee?.employee_id,
                user__first_name: `${employee?.user?.first_name} ${employee?.user?.last_name}`,
                user__email: employee?.user?.email,
                user__phone_number: employee?.user?.phone_number,
                role__name: employee?.role?.name,
                // position: "position",
                created_at: handleFormatDate(reformatDateString(employee?.user?.created_at)),
              };
            });
            setCount(res?.data?.count);
            setEmployeesList(options);
            setIsSearch(false);
            navigate(`/administrator/employees/list?page=${page}&rows=${rows}`);
          }
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    id,
    email,
    name,
    phoneNumber,
    role,
    // position,
    createdAt,
    baseStatus
  ) => {
    setIsSearch(true);
    getEmployeesList(
      company,
      rows,
      page,
      search,
      id,
      email,
      name,
      phoneNumber,
      role,
      // position,
      createdAt,
      baseStatus
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setFirst(0);
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                rows,
                page,
                value,
                filters?.employee_id,
                filters?.user__email,
                filters?.user__first_name,
                filters?.user__phone_number,
                filters?.role__name,
                // filters?.position,
                filters?.created_at,
                baseStatus
              );
            }}
            isSearch={isSearch}
          />
        </div>
        {canAccess("add_employee") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Employee"
            onClick={() => navigate("/administrator/employees/add")}
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedItems([])
                getEmployeesList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.employee_id,
                  filters?.user__email,
                  filters?.user__first_name,
                  filters?.user__phone_number,
                  filters?.role__name,
                  // filters?.position,
                  filters?.created_at,
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedItems?.length || 0})`}
              </li>
            </> : <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("archived")
                setSelectedItems([])
                getEmployeesList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.employee_id,
                  filters?.user__email,
                  filters?.user__first_name,
                  filters?.user__phone_number,
                  filters?.role__name,
                  // filters?.position,
                  filters?.created_at,
                  "archived"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Show Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedItems?.length || 0})`}
              </li>
            </>}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/administrator/employees/view/${d.id}`);
              dispatch(setEmployeeSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {/* {canAccess("delete_employee") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer" onClick={() => {
              setIdToDelete(d?.id);
              setDeleteModal(true);
            }}>
              <i
                className="pi pi-trash  text-dark"

              ></i>{" "}
              Delete
            </li>
          )} */}
          {canAccess("delete_employee") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete({ id: [d?.id], action: d?.base_status === "archived" ? "restore" : "archived" });
                setDeleteModal(true);
              }}
            >
              <img src={ArchiveIcon} />
              {d?.base_status === "archived" ? "Unarchive" : "Archive"}
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const handleEmployeeDelete = () => {
    dispatch(
      employeeArchiveAction(
        profile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          // getEmployeesList(profile?.company, rows, page, search);
          getEmployeesList(
            profile?.company,
            rows,
            page,
            search,
            "",
            "",
            "",
            "",
            "",
            // filters?.position,
            "",
            baseStatus
          );
          setSelectedItems([])
        }
      )
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "employees" : "employee"} from the list?`}
      </span>
    );
  };

  const onPageChange = (e) => {
    // navigate(`/administrator/employees/list?page=${e.page + 1}&rows=${e.rows}`);
    getEmployeesList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.employee_id,
      filters?.user__email,
      filters?.user__first_name,
      filters?.user__phone_number,
      filters?.role__name,
      // filters?.position,
      filters?.created_at,
      baseStatus
    );
    setFirst(e.first);
  };

  useEffect(() => {
    if (profile?.company) {
      getEmployeesList(
        profile?.company,
        rows,
        page,
        search,
        filters?.employee_id,
        filters?.user__email,
        filters?.user__first_name,
        filters?.user__phone_number,
        filters?.role__name,
        // filters?.position,
        filters?.created_at,
        baseStatus
      );
    }
  }, [profile?.company]);

  const handleSort = (e) => {
    if (sortField?.order === "-") {
      setSortField({ field: e?.sortField, order: "" });
    } else {
      setSortField({ field: e?.sortField, order: "-" });
    }
  };

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getEmployeesList(
      profile?.company,
      rows,
      1,
      "",
      filters?.employee_id,
      filters?.user__email,
      filters?.user__first_name,
      filters?.user__phone_number,
      filters?.role__name,
      // filters?.position,
      filters?.created_at,
      baseStatus
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getEmployeesList(
      profile?.company,
      rows,
      1,
      "",
      field === "employee_id" ? "" : filters?.employee_id,
      field === "user__email" ? "" : filters?.user__email,
      field === "user__first_name" ? "" : filters?.user__first_name,
      field === "user__phone_number" ? "" : filters?.user__phone_number,
      field === "role__name" ? "" : filters?.role__name,
      // field === "position" ? "" : filters?.position,
      field === "created_at" ? "" : filters?.created_at,
      baseStatus
    );
    options.filterClearCallback("");
  };

  const archiveUnarchiveMultiple = (items, action) => {
    if (items?.length) {
      const selectedIds = items?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    } else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
      }))
    }
  }

  return (
    <div>
      <CustomCard title="Employees List" header={header()}>
        <CustomTableContainer
          list={employeesList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={employee?.id}
          slice={setEmployeeSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleEmployeeDelete}
        loading={loading}
      />
    </div>
  );
};

export default EmployeesList;
