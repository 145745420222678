import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomDropDown, CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import { useDispatch, useSelector } from "react-redux";
import { canAccess } from "services/auth";
import ArchiveIcon from "assets/images/icons/archive.svg"
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { setListDetailSlice } from "store/slices/listSlices";
import { TenantStatusTypes, TenantTypes } from "shared/AllDropDownOptions";
import CustomModal from "shared/Modals/CustomModal";
import { debounce } from "lodash";
import { showToastAction } from "store/slices/commonSlice";
import { archiveTenantAction, getTenantListAction } from "store/actions/TenantsActions";
import { setTenantSlice } from "store/slices/tenantSlice";
import { getStatusColors } from "utils/commonFunctions";

export default function TenantsList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const openAction = useRef(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { profile } = useSelector((state) => state.user);
  const { tenant } = useSelector((state) => state?.tenant);
  const [first, setFirst] = useState(0);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const [count, setCount] = useState(0);
  const [tenantsList, setTenantsList] = useState([])
  const [idToDelete, setIdToDelete] = useState({ id: "", action: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState();
  const [baseStatus, setBaseStatus] = useState("")
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const globalFilters = [
    "tenant_id",
    "tenant_name",
    "tenant_type",
    "status",
  ];

  const [filters, setFilters] = useState({
    tenant_id: "",
    tenant_name: "",
    tenant_type: "",
    status: "",
  });

  const [filters2, setFilters2] = useState({
    tenant_id: { value: "" },
    status: { value: "" },
    tenant_name: { value: "" },
    tenant_type: { value: "" },
  });

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Tenant ID",
        accessor: "tenant_id",
        value: true,
        frozen: true,
        filter: true,
        filterElement: (option) => CommonTextFilter(option, "tenant_id"),
      },
      {
        name: "Tenant Name",
        accessor: "tenant_name",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (option) => CommonTextFilter(option, "tenant_name"),
      },
      {
        name: "Tenant Type",
        accessor: "tenant_type",
        ssortable: false,
        value: true,
        filter: true,
        filterElement: (option) => CommonDropdownFilter(option, "tenant_type", TenantTypes),
      },
      {
        name: "Status",
        accessor: "status",
        value: true,
        sortable: false,
        filter: false,
        className: "capitalize",
        body: (option) => {
          return <span className={getStatusColors(option?.status)}>{option?.status}</span>;
        },
        filterElement: (option) => CommonDropdownFilter(option, "status", TenantStatusTypes),
      },
      {
        name: "Phone",
        accessor: "phone_number",
        sortable: false,
        value: true,
      },
      {
        name: "Property ID",
        accessor: "property_id",
        sortable: false,
        value: true,
      },
      {
        name: "Office",
        accessor: "social_office.name",
        value: true,
        sortable: false,
      },
    ],
    [tenant]
  );

  const [columns, setColumns] = useState([]);
  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  useEffect(() => {
    setColumnOptions(columnOptionsInitial);
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, [columnOptionsInitial]);

  useEffect(() => {
    if (profile?.company) {
      getTenantsList(
        profile?.company,
        rows,
        page,
        search,
        filters?.tenant_id,
        filters?.tenant_name,
        filters?.tenant_type,
        filters?.status,
        baseStatus
      );
    }
  }, [profile?.company]);

  const CommonTextFilter = (options, inputName) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name={inputName}
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value })
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const CommonDropdownFilter = (options, inputName, dropdownOptions) => {
    return (
      <div>
        <CustomDropDown
          col={12}
          value={options.value}
          options={dropdownOptions}
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value })
            options.filterCallback(value);
          }}
          optionLabel="name"
          name={inputName}
          placeholder="Select Type"
          className="p-column-filter"
        />
      </div>
    );
  };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value || "" }));
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    tenantId,
    tenantName,
    tenantType,
    status,
    baseStatus
  ) => {
    setIsSearch(true);
    getTenantsList(
      company,
      rows,
      page,
      search,
      tenantId,
      tenantName,
      tenantType,
      status,
      baseStatus
    );
  };

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setFirst(0);
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.tenant_id,
                filters?.tenant_name,
                filters?.tenant_type,
                filters?.status,
                baseStatus
              );
            }}
            isSearch={isSearch}
            placeholder="Search Tenant"
          />
        </div>
        {canAccess("add_landlord") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Tenant"
            onClick={() => navigate("/tenants/add")}
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedItems([])
                getTenantsList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.tenant_id,
                  filters?.tenant_name,
                  filters?.tenant_type,
                  filters?.status,
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedItems?.length || 0})`}
              </li>
            </> : <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("archived")
                setSelectedItems([])
                getTenantsList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.tenant_id,
                  filters?.tenant_name,
                  filters?.tenant_type,
                  filters?.status,
                  "archived"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Show Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedItems?.length || 0})`}
              </li></>}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/tenants/view/${d.id}`);
              dispatch(setTenantSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {canAccess("delete_landlord") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete({ id: [d?.id], action: d?.base_status === "archived" ? "restore" : "archived" });
                setDeleteModal(true);
              }}
            >
              <img src={ArchiveIcon} />
              {d?.base_status === "archived" ? "Unarchive" : "Archive"}
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  const onPageChange = (e) => {
    getTenantsList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.tenant_id,
      filters?.tenant_name,
      filters?.tenant_type,
      filters?.status,
      baseStatus
    );
    dispatch(setListDetailSlice({ page: e.page + 1, row: e.rows }));
    setFirst(e.first);
  };

  const getTenantsList = (
    company,
    rows,
    page,
    search,
    tenantId,
    tenantName,
    tenantType,
    status,
    baseStatus
  ) => {
    dispatch(
      getTenantListAction(
        company,
        rows,
        page,
        search,
        tenantId,
        tenantName,
        tenantType,
        status,
        baseStatus,
        (res) => {
          setTenantsList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(`/tenants/list?page=${page}&rows=${rows}`);
        }
      )
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "tenants" : "tenant"} from the list?`}
      </span>
    );
  };

  const handleArchiveUnarchiveTenant = () => {
    dispatch(
      archiveTenantAction(
        profile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getTenantsList(profile?.company, rows, page, search, filters?.tenant_id, filters?.tenant_name, filters?.tenant_type, filters?.status, baseStatus);
          setSelectedItems([])
        }
      )
    );
  };

  const archiveUnarchiveMultiple = (items, action) => {
    if (items?.length) {
      const selectedIds = items?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    } else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
      }))
    }
  }

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getTenantsList(
      profile?.company,
      rows,
      page,
      "",
      filters?.tenant_id,
      filters?.tenant_name,
      filters?.tenant_type,
      filters?.status,
      baseStatus
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getTenantsList(
      profile?.company,
      rows,
      page,
      "",
      field === "tenant_id" ? "" : filters?.tenant_id,
      field === "tenant_name" ? "" : filters?.tenant_name,
      field === "tenant_type" ? "" : filters?.tenant_type,
      field === "status" ? "" : filters?.status,
      baseStatus
    );
    options.filterClearCallback("")
  };

  return (
    <div>
      <CustomCard title="Tenants List" header={header()} classNameBar="mb-4">
        <CustomTableContainer
          list={tenantsList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={tenant?.id}
          slice={setTenantSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Tenant ID"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleArchiveUnarchiveTenant}
        loading={loading}
      />
    </div>
  );
}
