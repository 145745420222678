import { handleFileConversion } from "utils/commonFunctions";
import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";

const addBuildingAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        max_floor: data?.buildingMaxFloor,
        type: data?.buildingType,
        staircase: data?.staircaseAccess,
        size: data?.size,
        company: company,
        longitude: data?.longitude,
        latitude: data?.latitude,
        address: data?.address,
        zipcode: data?.zipCode,
        city: data?.city,
        house_number: data?.houseNo,
        street: data?.street,
        city_division: data?.cityDivision,
        country: data?.country,
    };
    if (data?.document?.length) {
        const documents = await Promise.all(data?.document.map(async (document) => {
            if(typeof document.document == "object"){
                return { type: document?.type, document: await handleFileConversion(document?.document) }
            }else {
                return document
            }
        }))
        payload.building_document = documents;
    }

    if (data?.address) {
        payload.address = data.address;
    }

    if (data?.building !== "add_new" && data?.building) {
        payload.id = data?.building;
    }

    const res = await api(
        "post",
        endPoints?.BUILDING + `${company}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)

};

// const searchBuildingAction = (id, onRes) => async () => {
//     const res = await api(
//         "get",
//         endPoints?.GET_BUILDING + `?building_id=${id}`,
//     )
//     onRes(res)
// }

const searchBuildingAction = (company, lng, lat, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.BUILDING + `${company}/?longitude=${lng}&latitude=${lat}&base_status=active`,
    )
    onRes(res)
}

const updateBuildingAction = (company, buildingId, data, onRes) => async () => {
    const res = await api(
        "patch",
        endPoints?.BUILDING + `${company}/${buildingId}/`,
        data
    );
    onRes(res)
};

const getBuildingListAction = (company, rows, page, search, name, buildingType, address, staircase, baseStatus, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.BUILDING + `${company}/?limit=${rows}&page=${page}&search=${search}&name=${name || ""}&type=${buildingType || ""}&address=${address || ""}&staircase=${staircase || ""}&base_status=${baseStatus || "active"}`,
    );

    if (res?.success) {
        // let options = res?.data?.results?.map((item) => {
        //     return { ...item, landlord_name: item?.landlord_name }
        // })
        onRes({ options: res?.data?.results, count: res?.data?.count })
    }
};

const deleteBuildingAction = (company, buildingId, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.BUILDING + `${company}/${buildingId}`,
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
    setDeleteModal(false)
};

const archiveBuildingAction = (company, buildings, action, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "patch",
        endPoints?.BUILDING + `${company}/${action}/`,
        {ids: buildings}
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
    setDeleteModal(false)
};

const getBuildingAction = (company, building, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.BUILDING + `${company}/${building}`,
    );

    if (res?.success) {
        onRes(res?.data?.results)
    }
};

export {
    addBuildingAction,
    searchBuildingAction,
    updateBuildingAction,
    getBuildingListAction,
    deleteBuildingAction,
    getBuildingAction,
    archiveBuildingAction
}