import React, { useRef, useState } from "react";
import { CustomAutoComplete, CustomCalander, CustomForm } from "shared/AllInputs";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { searchOfficeAction } from "store/actions/officeActions";
import { searchEmployeesAction } from "store/actions/EmployeeActions";
import { searchServiceAction } from "store/actions/ServicesSetupActions";

export default function SocialOffice({ data, setData, handleChange }) {
    const dispatch = useDispatch()
    const { profile } = useSelector((state) => state?.user)
    const [officeOptions, setOfficeOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])

    const officeSearch = (company, value) => {
        dispatch(
            searchOfficeAction(company, value, setOfficeOptions, (error) => {
                const formErrors = ({ ["socialOffice"]: error })
                setData((prev) => ({ ...prev, ["socialOffice"]: value, formErrors }));
            })
        );
    };
    
    const officeChange = useRef(debounce(officeSearch, 1000));

    const employeeSearch = (company, value) => {
        dispatch(searchEmployeesAction(company, value, "", setEmployeeOptions, (error) => {
            const formErrors = ({ ["employee"]: error })
            setData((prev) => ({ ...prev, ["employee"]: value, formErrors }));
        }));
      };

    const employeeChange = useRef(debounce(employeeSearch, 1000));

    const serviceSearch = (company, value) => {
        dispatch(searchServiceAction(company, value, "", setServiceOptions, (error) => {
            const formErrors = ({ ["service"]: error })
            setData((prev) => ({ ...prev, ["service"]: value, formErrors }));
        }));
      };

    const serviceChange = useRef(debounce(serviceSearch, 1000));

    return (
        <CustomForm title="Social Office">
            <CustomAutoComplete
                field={"name"}
                data={data}
                onChange={handleChange}
                name="socialOffice"
                placeholder={"Search Office"}
                suggestions={officeOptions}
                search={(e) => officeChange.current(profile?.company, e.query)}
                required
            />
            <CustomAutoComplete
                field={"name"}
                data={data}
                onChange={handleChange}
                name="employee"
                placeholder={"Search Employee"}
                suggestions={employeeOptions}
                label={"Search Employee"}
                required
                search={(e) => employeeChange.current(profile?.company, e.query)}
            />
            <CustomAutoComplete
                field={"name"}
                data={data}
                onChange={handleChange}
                name="service"
                placeholder={"Search Service"}
                suggestions={serviceOptions}
                required
                search={(e) => serviceChange.current(profile?.company, e.query)}
            />
            <CustomCalander
                data={data}
                onChange={handleChange}
                name="contractDate"
                required
            />
        </CustomForm>
    )
}