import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import {
  // CustomCalander,
  CustomInput,
  CustomSearch,
  CustomYearInput,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { canAccess } from "services/auth";
import { useDispatch, useSelector } from "react-redux";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { getAnnualStatementListAction } from "store/actions/AnnualStatementActions";
import { getStatusColors, handleFormatDate } from "utils/commonFunctions";
import { setAnnualStatementSlice } from "store/slices/annualStatementSlice";
import { debounce } from "lodash";
import ArchiveIcon from "assets/images/icons/archive.svg"

const AnnualStatementList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [search, setSearch] = useState("");
  const [statementList, setStatementList] = useState([]);
  const globalFilters = ["year_of_statement", "property_id"];
  const [filters, setFilters] = useState({
    year_of_statement: "",
    property_id: "",
  });
  const [filters2, setFilters2] = useState({
    year_of_statement: { value: "" },
    property_id: { value: "" },
  });
  const [first, setFirst] = useState(0);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const { annualStatement } = useSelector((state) => state?.annualStatement);
  const [baseStatus, setBaseStatus] = useState("")

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-7 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.year_of_statement,
                filters?.property_id,
                baseStatus
              );
            }}
            isSearch={isSearch}
            placeholder="Search Statement"
          />
        </div>
        {canAccess("add_annualstatement") && <CustomButton
          size="small"
          icon="pi pi-plus"
          extraClassNames="mr-3"
          label="Add Statement"
          onClick={() => navigate("/real-estate/annualstatement/add")}
        // disabled
        />}
        <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-download text-dark"></i>
              Import
            </li>
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-upload text-dark"></i> Export
            </li>
            {/* {baseStatus === "archived" ? <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                getAnnualStatementList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.year_of_statement,
                  filters?.property_id,
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li> : <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("archived")
                getAnnualStatementList(
                  profile?.company,
                  10,
                  1,
                  "",
                  filters?.year_of_statement,
                  filters?.property_id,
                  "archived"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Show Archived
            </li>} */}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() =>
              navigate(`/real-estate/annualstatement/view/${d.id}`)
            }
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {/* <li className="flex gap-2 text-xs font-medium mb-3">
            <i
              className="pi pi-trash cursor-pointer text-dark"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li> */}
        </ul>
      </CustomOverlay>
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p className="flex gap-2 align-items-center">Action</p>
      </>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected employee from the list?
        This action cannot be undone.
      </span>
    );
  };

  const statusBodyTemplate = (status) => {
    return <span className={getStatusColors(status)}>{status}</span>;
  };

  const ReportedDateFilter = (options) => {
    return (
      <div>
        <CustomYearInput
          col={12}
          // data={filters}
          value={options?.value}
          name="year_of_statement"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
        />
        {/* <CustomCalander
          col={12}
          data={filters}
          onChange={handleFilterChange}
          name="year_of_statement"
        /> */}
      </div>
    );
  };

  const PropertyIdFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          // data={filters}
          value={options?.value}
          name="property_id"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value });
            options.filterCallback(value);
          }}
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Year",
        accessor: "year_of_statement",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        dataType: "date",
        filterElement: (options) => ReportedDateFilter(options),
      },
      {
        name: "In-status",
        accessor: "status",
        value: true,
        body: (options) => statusBodyTemplate(options?.status),
      },
      {
        name: "Out-status",
        accessor: "",
        value: true,
      },
      {
        name: "Property ID",
        accessor: "property_id",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => PropertyIdFilter(options),
      },
      {
        name: "Type",
        accessor: "type",
        value: true,
      },
      {
        name: "Print Date",
        accessor: "print_date",
        value: true,
        body: (options) => handleFormatDate(options?.print_date),
      },
    ],
    [statementList, filters,]
  );

  const getAnnualStatementList = (
    company,
    rows,
    page,
    search,
    year,
    property_id,
    baseStatus
  ) => {
    dispatch(
      getAnnualStatementListAction(
        company,
        rows,
        page,
        search,
        year,
        property_id,
        baseStatus,
        (res) => {
          setStatementList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/annualstatement/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value || "" }));
  };

  const handleSearch = (company, rows, page, search, year, propertyId, baseStatus) => {
    setIsSearch(true);
    getAnnualStatementList(company, rows, page, search, year, propertyId, baseStatus);
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const onPageChange = (e) => {
    // navigate(`/real-estate/annualstatement/list?page=${e.page + 1}&rows=${e.rows}`);
    getAnnualStatementList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.year_of_statement,
      filters?.property_id,
      baseStatus
    );
    setFirst(e.first);
  };

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getAnnualStatementList(
      profile?.company,
      rows,
      page,
      "",
      filters?.year_of_statement,
      filters?.property_id,
      baseStatus
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getAnnualStatementList(
      profile?.company,
      rows,
      page,
      "",
      field === "year_of_statement" ? "" : filters?.year_of_statement,
      field === "property_id" ? "" : filters?.property_id,
      baseStatus
    );
    options.filterClearCallback("");
  };

  useEffect(() => {
    if (profile?.company) {
      getAnnualStatementList(
        profile?.company,
        rows,
        page,
        "",
        filters?.year_of_statement,
        filters?.property_id,
        baseStatus
      );
    }
  }, [profile?.company]);

  // useEffect(() => {
  //   if (profile?.company) {
  //     getAnnualStatementList(
  //       profile?.company,
  //       rows,
  //       page,
  //       "",
  //       filters?.year_of_statement,
  //       filters?.property_id
  //     );
  //   }
  // }, [profile?.company, rows, page]);

  return (
    <div>
      <CustomCard title="Received Statement" header={header()}>
        <CustomTableContainer
          list={statementList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={annualStatement?.id}
          slice={setAnnualStatementSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        // onSubmit={handleEmployeeDelete}
        loading={loading}
      />
    </div>
  );
};

export default AnnualStatementList;
