import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomCheckbox, CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { canAccess, getUserInfo } from "services/auth";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "shared/Modals/CustomModal";
import {
  propertiesListAction,
  propertyArchiveAction,
  // propertyArchiveMultiAction,
  // propertyDeleteAction,
} from "store/actions/propertyActions";
import { debounce } from "lodash";
import { OverlayPanel } from "primereact/overlaypanel";
import PropertyTableContainer from "./PropertyTableContainer";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import {
  BuildingStatus,
  BuildingTypeOptions,
  OutStatusOptions,
  StairAccessOptions,
  UnitLocationOptions,
  UnitTypeOptions,
} from "shared/AllDropDownOptions";
import CustomOverlay from "shared/CustomOverlay";
import ArchiveIcon from "assets/images/icons/archive.svg"
// import UnarchiveIcon from "assets/images/icons/unarchive.svg"
import { showToastAction } from "store/slices/commonSlice";
const PropertyList = () => {
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const msgs = useRef(null);
  const { profile } = useSelector((state) => state.user);
  const [propertiesList, setPropertiesList] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const userProfileRef = useRef(userProfile);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", action: "" });
  // const [rows, setRows] = useState(10);
  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [sortField, setSortField] = useState({ field: "", order: "" });
  // const [data, setData] = useState({
  //   searchKey: "",
  // });
  // const searchref = useRef("");
  const [searchKey, setSearchKey] = useState("");
  const [columns, setColumns] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [baseStatus, setBaseStatus] = useState("")

  const [filters, setFilters] = useState({
    property_id: { value: "" },
    // address: { value: "" },
    building__type: { value: "" },
    building__staircase: { value: "" },
    status: { value: "" },
    location: { value: "" },
    heatingDevice: { value: "" },
    unitType: { value: "" },
    outStatus: { value: "" },
  });

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  useEffect(() => {
    userProfileRef.current = userProfile; // Update the ref with the latest userProfile
  }, [userProfile]);

  // useEffect(() => {
  //   searchref.current = data?.searchKey;
  // }, [data?.searchKey]);

  const PropertyIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Property ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  // const AddressFilter = (options) => {
  //   return (
  //     <div>
  //       <CustomInput
  //         col={12}
  //         value={options.value}
  //         name="address"
  //         onChange={(e) => {
  //           options.filterCallback(e.value);
  //         }}
  //         optionLabel="name"
  //         placeholder="Address"
  //         className="p-column-filter"
  //         ignoreLabel
  //       />
  //     </div>
  //   );
  // };

  const BuildingTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={BuildingTypeOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Building Type"
          className="p-column-filter"
        />
        {/* <div className="flex flex-column gap-2 mt-3">
          <CustomButtonOutlined
            label="Clear"
            extraClassNames="w-full"
            onClick={() => {
              options.filterCallback();
            }}
          />
          <CustomButton
            extraClassNames="w-full"
            onClick={() => handlebuildingFilter(options.value)}
            label="Apply"
            // loading={loading}
          />
        </div> */}
      </div>
    );
  };

  const StairCaseFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={StairAccessOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Straircase Access"
          className="p-column-filter"
        />
      </div>
    );
  };

  const StatusFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={BuildingStatus}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Status"
          className="p-column-filter"
        />
      </div>
    );
  };

  const LocationFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={UnitLocationOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Location"
          className="p-column-filter"
        />
      </div>
    );
  };

  const HeatingDeviceFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={UnitLocationOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Location"
          className="p-column-filter"
        />
      </div>
    );
  };

  const UnitTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={UnitTypeOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Location"
          className="p-column-filter"
        />
      </div>
    );
  };

  const OutStatusFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={OutStatusOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Out-Status"
          className="p-column-filter"
        />
      </div>
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Vacant":
      case "Fully":
        return "text-yellow-500";

      case "Expiring":
      case "Moving Out":
      case "Pending":
        return "text-red-600";

      case "Occupied":
      case "Partially":
        return "text-green-600";

      default:
        return "";
    }
  };

  const statusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.status)}>{options?.status}</span>
    );
  };

  const outstatusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.outStatus)}>
        {options?.outStatus}
      </span>
    );
  };

  const getStairCaseName = (staircase) => {
    switch (staircase) {
      case "Direct":
        return "Direct Access";

      case "Common":
        return "Access to Common Floor";

      case "Combined":
        return "Combined Access";

      default:
        return staircase;
    }
  };

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Property ID",
        accessor: "property_id",
        value: true,
        frozen: true,
        sortable: true,
        filter: true,
        disabled: true,
        filterElement: (option) => PropertyIDFilter(option),
      },
      // {
      //   name: "Address",
      //   accessor: "address",
      //   value: true,
      //   filter: true,
      //   filterElement: (option) => AddressFilter(option),
      // },
      {
        name: "Out-Status",
        accessor: "outStatus",
        value: true,
        filter: true,
        body: (options) => outstatusBodyTemplate(options),
        filterElement: (option) => OutStatusFilter(option),
      },
      {
        name: "Building Type",
        accessor: "building__type",
        value: true,
        filter: true,
        filterElement: (options) => BuildingTypeFilter(options),
      },
      {
        name: "Staircase Access",
        accessor: "building__staircase",
        value: true,
        filter: true,
        body: (options) => getStairCaseName(options?.building__staircase),
        filterElement: (options) => StairCaseFilter(options),
      },
      {
        name: "Status",
        accessor: "status",
        value: true,
        filter: true,
        disabled: true,
        body: (options) => statusBodyTemplate(options),
        filterElement: (options) => StatusFilter(options),
      },

      {
        name: "Heating Device",
        accessor: "heatingDevice",
        value: false,
        filter: true,
        filterElement: (options) => HeatingDeviceFilter(options),
      },
      { name: "Building Max Floor", accessor: "buildingMaxFloor", value: true },
      // { name: "Property Name", accessor: "propertyName", value: false },
      {
        name: "Unit Type",
        accessor: "unitType",
        value: false,
        filter: true,
        filterElement: (options) => UnitTypeFilter(options),
      },
      { name: "No. of Floor", accessor: "floor", value: false },
      {
        name: "Location",
        accessor: "location",
        value: true,
        filter: true,
        filterElement: (option) => LocationFilter(option),
      },
      { name: "No. of Rooms", accessor: "rooms", value: false },
      // { name: "Room Details", accessor: "roomDetails", value: false },
      { name: "No. of Doors", accessor: "doors", value: false },
      // { name: "Energy Meter", accessor: "energyMeter", value: false },
      { name: "Tenant Name", accessor: "tenantName", value: false },
      { name: "Base Rent", accessor: "baseRent", value: false },
      { name: "Move in Date", accessor: "moveInDate", value: false },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zipcode", value: true },
      { name: "City", accessor: "city", value: false },
      { name: "City Division", accessor: "cityDivision", value: false },
      { name: "Country", accessor: "country", value: false },
    ],
    [filters]
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    setUserProfile(info);
  }, [profile]);

  const getPropertiesList = (
    company,
    rows,
    page,
    search,
    order,
    buildingType,
    staircase,
    status,
    location,
    heatingDevice,
    unitType,
    outStatus,
    // address,
    property_id,
    baseStatus
  ) => {
    dispatch(
      propertiesListAction(
        company,
        rows,
        page,
        search,
        order,
        buildingType,
        staircase,
        status,
        location,
        heatingDevice,
        unitType,
        outStatus,
        // address,
        property_id,
        baseStatus,
        (res) => {
          setPropertiesList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(`/real-estate/property/list?page=${page}&rows=${rows}`);
        }
      )
    );
  };

  // const handleChange = ({ name, value }) => {
  //   setData((prev) => ({ ...prev, [name]: value }));
  //   // setRows(10);
  //   // setPage(1);
  //   setFirst(0);
  // };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    order,
    buildingType,
    staircase,
    status,
    location,
    heatingDevice,
    unitType,
    outStatus,
    // address,
    property_id,
    baseStatus
  ) => {
    setIsSearch(true);
    getPropertiesList(
      company,
      rows,
      page,
      search,
      order,
      buildingType,
      staircase,
      status,
      location,
      heatingDevice,
      unitType,
      outStatus,
      // address,
      property_id,
      baseStatus
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex md:justify-content-end flex-wrap align-items-center">
        <div className="w-8 md:w-8 mr-3">
          <CustomSearch
            name="searchKey"
            value={searchKey}
            onChange={({ value }) => {
              setSearchKey(value);
              debouncedOnChange.current(
                userProfile?.company,
                10,
                1,
                value,
                sortField?.order + sortField?.field,
                filters?.building__type?.value,
                filters?.building__staircase?.value,
                filters?.status?.value,
                filters?.location?.value,
                filters?.heatingDevice?.value,
                filters?.unitType?.value,
                filters?.outStatus?.value,
                // filters?.address?.value,
                filters?.property_id?.value,
                baseStatus,
              );
              setFirst(0);
            }}
            isSearch={isSearch}
            placeholder={"Search Properties"}
          />
        </div>
        {canAccess("add_property") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Property"
            onClick={() =>
              navigate("/real-estate/property/add/building-structure")
            }
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedProperties([])
                getPropertiesList(userProfile?.company, 10, 1, "", "", "", "", "", "", "", "", "", "", "active");
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedProperties, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedProperties?.length || 0})`}
              </li>
            </> : <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("archived")
                setSelectedProperties([])
                getPropertiesList(userProfile?.company, 10, 1, "", "", "", "", "", "", "", "", "", "", "archived");
              }}
            >
              <img src={ArchiveIcon} />
              Show Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedProperties, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedProperties?.length || 0})`}
              </li>
            </>}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const handlePropertyDelete = () => {
    dispatch(
      propertyArchiveAction(
        userProfile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getPropertiesList(
            userProfile?.company,
            rows,
            page,
            searchKey,
            sortField?.order + sortField?.field,
            filters?.building__type?.value,
            filters?.building__staircase?.value,
            filters?.status?.value,
            filters?.location?.value,
            filters?.heatingDevice?.value,
            filters?.unitType?.value,
            filters?.outStatus?.value,
            // filters?.address?.value,
            filters?.property_id?.value,
            baseStatus
          );
          setSelectedProperties([])
        }
      )
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "properties" : "property"} from the list?`}
      </span>
    );
  };

  useEffect(() => {
    if (userProfile?.company) {
      getPropertiesList(
        userProfile?.company,
        rows,
        page,
        "",
        sortField?.order + sortField?.field,
        filters?.building__type?.value,
        filters?.building__staircase?.value,
        filters?.status?.value,
        filters?.location?.value,
        filters?.heatingDevice?.value,
        filters?.unitType?.value,
        filters?.outStatus?.value,
        // filters?.address?.value,
        filters?.property_id?.value,
        baseStatus
      );
      // }
    }
  }, [
    // rows,
    // page,
    // first,
    userProfile?.company,
    sortField,
    filters?.building__type?.value,
    filters?.building__staircase?.value,
    filters?.status?.value,
    filters?.location?.value,
    filters?.heatingDevice?.value,
    filters?.unitType?.value,
    filters?.outStatus?.value,
    // filters?.address?.value,
    filters?.property_id?.value,
  ]);

  // const handleSort = (e) => {
  //   if (sortField?.order === "-") {
  //     setSortField({ field: e?.sortField, order: "" });
  //   } else {
  //     setSortField({ field: e?.sortField, order: "-" });
  //   }
  // };

  // const ActionTemplate = () => {
  //   return (
  //     <>
  //       <p
  //         className="flex gap-2 align-items-center"
  //         onClick={(e) => op.current.toggle(e)}
  //       >
  //         <i className="pi pi-filter cursor-pointer"></i>
  //         <i className="pi pi-chevron-down"></i>
  //       </p>
  //     </>
  //   );
  // };

  const handleCheckboxChange = (name, value) => {
    setColumnOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.accessor === name ? { ...option, value: value } : option
      )
    );
  };

  const handleChangeColumns = (e) => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
    openAction.current.toggle(e);
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  const onPageChange = (e) => {
    // setRows(e.rows);
    // setPage(e.page + 1);
    // navigate(`/real-estate/property/list?page=${e.page + 1}&rows=${e.rows}`)
    getPropertiesList(
      userProfile?.company,
      e.rows,
      e.page + 1,
      searchKey,
      sortField?.order + sortField?.field,
      filters?.building__type?.value,
      filters?.building__staircase?.value,
      filters?.status?.value,
      filters?.location?.value,
      filters?.heatingDevice?.value,
      filters?.unitType?.value,
      filters?.outStatus?.value,
      // filters?.address?.value,
      filters?.property_id?.value,
      baseStatus
    );
    setFirst(e.first);
  };

  const archiveUnarchiveMultiple = (selectedItems, action) => {
    if (selectedItems?.length) {
      const selectedIds = selectedItems?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    }else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
    }))
    }
  }

  return (
    <div>
      <CustomCard title="Property List" header={header()}>
        <Toast ref={msgs} />
        <PropertyTableContainer
          data={propertiesList}
          columns={columns}
          setDeleteModal={setDeleteModal}
          sortField={sortField}
          setSortField={setSortField}
          // setPage={setPage}
          first={first}
          setFirst={setFirst}
          setIdToDelete={setIdToDelete}
          rows={rows}
          // setRows={setRows}
          count={count}
          setCount={setCount}
          openAction={openAction}
          filters={filters}
          setFilters={setFilters}
          onPageChange={onPageChange}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
        />

        <OverlayPanel className="filter-overlay-panel" ref={openAction}>
          <h4 className="p-2">Manage Column</h4>
          <ul className="no-style p-0">
            {columnOptions
              ?.sort((a, b) => {
                if (a.name === "Property ID") return -1;
                if (b.name === "Property ID") return 1;

                if (a.value !== b.value) {
                  return b.value - a.value;
                }
                return a.name.localeCompare(b.name);
              })
              ?.map((column, index) => {
                if (!column.disabled) {
                  return (
                    <li
                      className="flex gap-2 text-xs font-medium cursor-pointer"
                      key={index}
                    >
                      <CustomCheckbox
                        label={column.name}
                        extraClassName="flex align-items-center"
                        col={12}
                        value={column.value}
                        name={column.accessor}
                        onChange={({ name, value }) =>
                          handleCheckboxChange(name, value)
                        }
                      // disabled={
                      //   column.accessor === "property_id" ||
                      //   column.accessor === "status"
                      // }
                      />
                    </li>
                  );
                }
              })}
          </ul>
          <CustomButton
            extraClassNames="mx-auto block"
            label="Apply"
            onClick={(e) => handleChangeColumns(e)}
          />
        </OverlayPanel>
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handlePropertyDelete}
        loading={loading}
      />
    </div>
  );
};

export default PropertyList;
