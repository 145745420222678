import React, { useEffect, useState } from "react";
import CustomCard from "shared/CustomCard";
import {
    CustomCalander,
    CustomDropDown,
    CustomForm,
    CustomInput,
    CustomPhoneInput,
} from "shared/AllInputs";
import { TenantTypes } from "shared/AllDropDownOptions";
import GooglePlacesInput from "shared/GooglePlacesInput";

export default function Tenant({ data, handleChange, isDisabled }) {
    const [address, setAddress] = useState("")
    useEffect(() => {
        if(address?.address){
            handleChange({name: "addressBeforeMovingIn", value: address?.address})
        }
    }, [address])

    return (
        <CustomCard>
            <CustomForm title="General Info">
                <CustomInput data={data} onChange={handleChange} label="Tenant ID" placeholder="Enter Tenant ID" name="tenantId" required disabled={isDisabled} />
                <CustomInput data={data} onChange={handleChange} name="firstName" required disabled={isDisabled} />
                <CustomInput data={data} onChange={handleChange} name="lastName" required disabled={isDisabled} />
                <CustomDropDown
                    data={data}
                    onChange={handleChange}
                    name="tenantType"
                    options={TenantTypes}
                    required
                    disabled={isDisabled}
                />
                <CustomCalander
                    maxDate={new Date()}
                    data={data}
                    onChange={handleChange}
                    name="dateOfBirth"
                    required
                    disabled={isDisabled}
                />
                <CustomInput data={data} onChange={handleChange} name="email" required disabled={isDisabled} />
                <CustomPhoneInput
                    data={data}
                    onChange={handleChange}
                    name="phoneNumber"
                    required
                    disabled={isDisabled}
                />
                <GooglePlacesInput
                    id={"tenant_address"}
                    data={data}
                    onChange={handleChange}
                    name="addressBeforeMovingIn"
                    required
                    setAddress={setAddress}
                    disabled={isDisabled}
                />
                {/* <CustomInput
                    data={data}
                    onChange={handleChange}
                    name="addressBeforeMovingIn"
                    required
                    disabled={isDisabled}
                /> */}
            </CustomForm>
        </CustomCard>
    );
}
