import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setOfficeSlice } from "../slices/officeSlice";

const addOfficeAction = (company, data, loading, navigate, dispatch) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.ADD_OFFICE + `${company}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setOfficeSlice(res?.data?.results?.office))
        navigate("/administrator/office/list")
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    loading(false)
};

const getOfficeListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/?base_status=active`,
    );
    onRes(res);
};

const searchOfficeAction = (company, name, setOptions, onError) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/?base_status=active&name=${name ||""}`,
    );
    if(res?.success){
        if(res?.data?.results?.length){
            setOptions(res?.data?.results) 
        }else {
            setOptions([]) 
            onError("Office does't exist.")
        }
    }
};

const officeListAction = (company, rows, page, search, name, address, unit, rep, employee, officeId, email, baseStatus, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&name=${name}&address=${address}&office_unit=${unit}&office_rep=${rep}&employee_count=${employee}&office_id=${officeId}&office_email=${email}&base_status=${baseStatus || "active"}`,
    );
    onRes(res);
};

const getOfficeInfoAction = (company, officeId, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/${officeId}/`,
    );
    if (res?.success) {
        onRes(res?.data?.results)
        dispatch(setOfficeSlice(res?.data?.results))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const updateOfficeAction = (company, officeId, data, loading, navigate, dispatch) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.ADD_OFFICE + `${company}/${officeId}/`,
        data
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate(`/administrator/office/view/${officeId}`)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    loading(false)
};

const officeDeleteAction = (company, officeId, loading, deleteModal, dispatch, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.OFFICE_LIST + `${company}/${officeId}`,
    );
    if (res?.success) {
        onRes(res);
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    loading(false)
    deleteModal(false)
};

const officeArchiveAction = (company, offices, action, loading, deleteModal, dispatch, onRes) => async () => {
    loading(true)
    const res = await api(
        "patch",
        endPoints?.OFFICE_LIST + `${company}/${action}/`,
        {ids: offices},
    );
    if (res?.success) {
        onRes(res);
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    loading(false)
    deleteModal(false)
};

const updateOfficeBankAccountAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.OFFICE_BANK_ACCOUNTS + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteOfficeBankAccountAction = (id, dispatch, onRes) => async () => {
    const res = await api(
        "delete",
        endPoints?.OFFICE_BANK_ACCOUNTS + `${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const updateOfficeEmployeeAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.OFFICE_EMPLOYEE + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteOfficeEmployeeAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.OFFICE_EMPLOYEE + `${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    onRes(res)
    setLoading(false)
}

const getOfficeEmpDetailsAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_EMPLOYEE + `/${id}/`,
    );
    if (res.success) {
        const info = res?.data?.results;
        onRes(info)
    }
}

export {
    addOfficeAction,
    getOfficeListAction,
    getOfficeInfoAction,
    updateOfficeAction,
    officeDeleteAction,
    officeListAction,
    updateOfficeBankAccountAction,
    deleteOfficeBankAccountAction,
    updateOfficeEmployeeAction,
    deleteOfficeEmployeeAction,
    getOfficeEmpDetailsAction,
    officeArchiveAction,
    searchOfficeAction,
};
