import React, { useEffect, useMemo, useRef, useState } from "react";
import formValidation from "utils/validations";
import { checkIsValueGreater, handleSetAddress, isFirstDayOfMonth, showFormErrors } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { addPropertyAction, searchPropertyIdAction, updatePropertyAction } from "store/actions/propertyActions";
import { addBuildingAction, searchBuildingAction } from "store/actions/buildingActions";
import { debounce } from "lodash";
import { addBuildingUnitAction, updateBuildingUnitAction } from "store/actions/buildingUnitActions";
import { addLandlordAction, landlordDropdownAction, updateLandlordAction } from "store/actions/landlordActions";
import { addContractAction, addRoomAndCostAction, searchContractAction, updateContractAction } from "store/actions/contractActions";
import {
    checkBankErrors,
    checkContactErrors,
    checkEnergyMeterErrors,
    checkExtraCostErrors,
    checkFurnitureErrors,
    checkOptionalMaturtiesErrors,
    checkRoomErrors,
} from "shared/Components/commonFunctions";
import {
    handleSetBuildingOptions,
    handleSetBuildingSturctureData,
    handleSetBuildingUnitData,
    handleSetContractData,
    handleSetLandlordData,
    handleSetPropertyData,
    handleSetRentData,
} from "../PropertyCommonFunctions";
import { setPropertyContractSlice, setPropertySlice } from "store/slices/propertySlice";
import { initialContactsInput } from "shared/initialInputs";

const PropertyContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate();
    const { profile } = useSelector((state) => state.user);
    const [userProfile, setUserProfile] = useState();
    const userProfileRef = useRef(userProfile);
    const [loading, setLoading] = useState(false);
    // const skipAble = [1]
    const [activeIndex, setActiveIndex] = useState(0)
    const [disableTabs, setDisableTabs] = useState([1, 2, 3, 4, 5])
    // const [disableTabs, setDisableTabs] = useState([])
    const [propertyId, setPropertyId] = useState("")
    const [isWarning, setIsWarning] = useState(false)
    const routes = [
        { index: 0, route: "building-structure" },
        { index: 1, route: "building-unit" },
        { index: 2, route: "landlord-info" },
        { index: 3, route: "contractual-info" },
        { index: 4, route: "rent-and-cost" },
        { index: 5, route: "annual-statement" },
    ]
    const [tabToActive, setTabToActive] = useState("");

    useEffect(() => {
        let info = "";
        const userInfo = getUserInfo();
        profile?.firstName ? (info = profile) : (info = userInfo);
        setUserProfile(info);
    }, [profile]);

    useEffect(() => {
        userProfileRef.current = userProfile; // Update the ref with the latest userProfile
    }, [userProfile]);

    useEffect(() => {
        let path = location?.pathname.split("/")
        let lastSegment = path[path.length - 1];
        routes?.map((item) => {
            if (item.route === lastSegment && activeIndex !== item?.index) {
                setActiveIndex(item?.index)
            }
        })
    }, [location?.pathname])

    useEffect(() => {
        if (activeIndex !== 0 && !propertyId) {
            navigate("/dashboard")
        }
    }, [activeIndex, propertyId])

    const handleTabChange = (index) => {
        routes?.map((item) => {
            if (item?.index === index) {
                navigate(`/real-estate/property/add/${item?.route}`)
                setActiveIndex(item?.index)
            }
        })

        // if (propertyId) {
        //     getPropertyData()
        // }

        const updatedTabs = disableTabs.filter(tab => tab !== index)
        setDisableTabs(updatedTabs)
    }

    //property from
    const [propertyIdOptions, setPropertyIdOptions] = useState([])
    const [selectedAddress, setSelectedAddress] = useState()
    const [data, setData] = useState({
        propertyId: "",
        address: "",
        zipCode: "",
        city: "",
        houseNo: "",
        street: "",
        cityDivision: "",
        country: "",
    });
    const [buildingOptions, setBuildingOptions] = useState([{ name: "Add New", value: "add_new" }])
    const [buildingStuctureData, setBuildingStuctureData] = useState({
        building: "",
        buildingType: "",
        buildingMaxFloor: "",
        staircaseAccess: "",
        size: "",
        longitude: "",
        latitude: "",
        address: "",
        street: "",
        houseNo: "",
        zipCode: "",
        country: "",
        city: "",
        cityDivision: "",
        document: [],
    });
    const [fileKey, setFileKey] = useState(0)
    const [sturcureDocuments, setStructureDocuments] = useState({
        buildingEnergyCertificate: [],
    })

    // const handleStructureFileChange = async ({ name, file }) => {
    //     // const file = event.target.files[0];
    //     // setStructureDocuments([...sturcureDocuments, file]);
    //     setStructureDocuments(prevDocuments => ({ ...prevDocuments, [name]: [...prevDocuments[name], file] }))
    //     const convertedFile = await handleFileConversion(file)
    //     let douments = [...buildingStuctureData.document]
    //     douments.push({ type: name, "document": convertedFile })
    //     setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }))
    // }

    const handleStructureFileDelete = (index) => {
        // let arr = [...sturcureDocuments];
        // arr?.splice(index, 1);
        // setStructureDocuments(arr);

        let douments = [...buildingStuctureData.document]
        douments?.splice(index, 1);
        setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }))

        // if (sturcureDocuments.length == 1) {
        //     setStructureDocuments([]);
        //     setFileKey((prevKey) => prevKey + 1);
        // }
    }

    const handleBuildingSearch = (company, lng, lat) => {
        let buildingOptions = [{ name: "Add New", value: "add_new" }]

        dispatch(
            searchBuildingAction(company, lng, lat, (res) => {
                if (res?.success) {
                    const info = res?.data?.results
                    info?.map((item) => {
                        buildingOptions.push({
                            ...item,
                            name: item?.name,
                            value: item?.id
                        })

                    })
                }
                setBuildingOptions(buildingOptions)

            })
        );
    };

    const buildingIdChange = useRef(debounce(handleBuildingSearch, 1000));

    useMemo(() => {
        if (selectedAddress) {
            handleSetAddress(selectedAddress, data, setData)
            handleSetAddress(selectedAddress, buildingStuctureData, setBuildingStuctureData)
            if (selectedAddress?.lng && selectedAddress?.lat) {
                buildingIdChange.current(userProfile?.company, selectedAddress?.lng, selectedAddress?.lat);
                // setBuildingStuctureData(prevState => ({
                //     ...prevState,
                //     id: ""
                // }));
            }
        }

    }, [selectedAddress])

    const handleChange = ({ name, value }) => {
        // if (name === "propertyId" && value) {
        //     propertyIdChange.current(value);
        // }
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));

        if (name === "address") {
            setSelectedAddress("")
        }

        if (name == "houseNo" || name == "street" || name == "zipCode" || name == "city" || name == "cityDivision" || name == "country") {
            setBuildingStuctureData((prev) => ({ ...prev, [name]: value, }));
        }
    };

    const handlePropertySuggestions = (value) => {
        let payload = {
            property_id: value,
        };

        dispatch(
            searchPropertyIdAction(payload, (res) => {
                if (!res?.success) {
                    const formErrors = ({ ["propertyId"]: res?.message })
                    setData((prev) => ({ ...prev, ["propertyId"]: value, formErrors }));
                    if (res?.data?.results) {
                        setPropertyIdOptions(res?.data?.results)
                    }
                } else {
                    setPropertyIdOptions([])
                }
            })
        );
    };

    const propertyIdChange = useRef(debounce(handlePropertySuggestions, 1000));

    const handleAddProperty = (buildingId) => {
        if (propertyId) {
            dispatch(
                updatePropertyAction(
                    userProfile?.company,
                    propertyId,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        setPropertyId(res?.id)
                        handleSetPropertyData(res, setData)
                        handleTabChange(activeIndex + 1)
                    }
                )
            );
        } else {
            dispatch(
                addPropertyAction(
                    userProfile?.company,
                    data,
                    buildingId,
                    setLoading,
                    dispatch,
                    (res) => {
                        handleTabChange(activeIndex + 1)
                        setPropertyId(res?.id)
                        handleSetPropertyData(res, setData)
                    })
            );
        }
    }

    //building structure
    const BuildingStuctureHandleChange = ({ name, value, ...rest }) => {
        const { type } = rest?.target
        const formErrors = formValidation(name, value, buildingStuctureData);

        if (type === "file" && value) {
            // setBuildingStuctureData((prev) => ({ ...prev, [name]: files[0], formErrors }))
            let douments = [...buildingStuctureData.document]
            douments.push({ type: rest?.documentType, "document": value })
            setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }))
        } else if (name === "building") {
            const selectedBuilding = buildingOptions.find((item) => item?.id === value)
            if (selectedBuilding) {
                handleSetBuildingSturctureData(value, selectedBuilding, setBuildingStuctureData, setStructureDocuments)
            } else {
                setBuildingStuctureData((prev) => ({ ...prev, [name]: value, formErrors }));
            }
        } else {
            setBuildingStuctureData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const BuildingStuctureSubmit = async () => {
        setIsWarning(false)
        if (showFormErrors(buildingStuctureData, setBuildingStuctureData,
            ["document", "longitude", "latitude", "address", "street", "houseNo", "zipCode", "country", "city", "cityDivision"]
        ) && (showFormErrors(data, setData, ["id"]))) {
            // let data = {
            //     ...buildingStuctureData,
            //     // ...selectedAddress,
            //     // houseNo: selectedAddress.house,
            //     // longitude: selectedAddress?.lng,
            //     // latitude: selectedAddress?.lat,
            //     // address: selectedAddress?.address,

            // }
            dispatch(
                addBuildingAction(
                    userProfile?.company,
                    buildingStuctureData,
                    setLoading,
                    dispatch,
                    (res) => {
                        handleAddProperty(res?.id)
                        handleSetBuildingSturctureData(res?.id, res, setBuildingStuctureData, setStructureDocuments)
                        handleSetBuildingOptions(res, buildingOptions, setBuildingOptions)
                        dispatch(setPropertySlice(res))
                    }
                )
            )
        }
    }

    const [allRooms, setAllRooms] = useState([
        { id: null, doors: 1, size: null, name: null, status: null },
    ]);
    const [allEnergyMeters, setAllEnergyMeters] = useState(
        [{ id: null, purpose: "", type: "", meter_id: "", location: "", access: "", amount: "", status: "" }]
    )
    //building unit
    const [buildingUnitData, setBuildingUnitdata] = useState({
        id: "",
        // name: "",
        unitType: "Residential",
        location: "",
        floor: "",
        waterHeating: "",
        size: "",
        heatingTechnology: "",
        building_document: [
            { handover_protocol: [] },
            { key_protocol: [] },
            { floor_plan: [] },
            { photos: [] },
            { energy_bill: [] },
            { energy_contract: [] },
            { energymeter_replacement: [] },
        ]
    })
    // const [unitfileKey, setUnitFileKey] = useState(0)
    // const [buildingUnitDocuments, setBuildingUniteDocuments] = useState({
    //     handoverProtocol: [],
    //     keyProtocol: [],
    //     floorPlan: [],
    //     photos: [],
    //     energyBill: [],
    //     energyContract: [],
    //     certificateOfEnergyReplacement: [],
    // })

    const BuildingUnitChange = ({ name, value, ...rest }) => {
        const { type } = rest?.target
        const { documentType } = rest
        let formErrors = formValidation(name, value, buildingUnitData, ["id"]);
        if (type === "file" && value) {
            const documentIndex = buildingUnitData.building_document.findIndex(
                (doc) => Object.keys(doc)[0] === documentType
            );

            if (documentIndex !== -1) {
                const updatedBuildingDocument = [...buildingUnitData.building_document];
                updatedBuildingDocument[documentIndex] = {
                    [documentType]: [...updatedBuildingDocument[documentIndex][documentType], { type: documentType, "document": value }]
                };

                setBuildingUnitdata((prev) => ({
                    ...prev,
                    building_document: updatedBuildingDocument
                }));
            }
        } else {
            if (name === "floor") {
                let isGreater = checkIsValueGreater(value, buildingStuctureData.buildingMaxFloor)
                if (isGreater) {
                    formErrors = ({ ["floor"]: "The floor number must be lower than the maximum floor of the building." })
                } else {
                    formErrors = formValidation(name, value, buildingUnitData, ["id"]);
                }
            } else if (name === "size") {
                let isGreater = checkIsValueGreater(value, buildingStuctureData.size)
                if (isGreater) {
                    formErrors = ({ ["size"]: "The size must be lower than the maximum size of the building." })
                } else {
                    formErrors = formValidation(name, value, buildingUnitData, ["id"]);
                }
            }
            setBuildingUnitdata((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const handleUnitFileDelete = (index, documentType) => {
        const updatedBuildingDocument = [...buildingUnitData.building_document];

        const documentIndex = buildingUnitData.building_document.findIndex(
            (doc) => Object.keys(doc)[0] === documentType
        );

        const documents = buildingUnitData?.building_document[documentIndex][documentType]
        documents.splice(index, 1);
        updatedBuildingDocument[documentIndex] = { [documentType]: [...updatedBuildingDocument[documentIndex][documentType]], documents }

        setBuildingUnitdata((prev) => ({ ...prev, ["building_document"]: updatedBuildingDocument }))
    }

    const BuildingUnitSubmit = () => {
        const rooms = checkRoomErrors(allRooms, setAllRooms, buildingUnitData?.size)
        const energyMeters = checkEnergyMeterErrors(allEnergyMeters, setAllEnergyMeters)
        if (showFormErrors(buildingUnitData, setBuildingUnitdata, ["id"]) && !rooms?.hasErrors && !energyMeters?.hasErrors) {
            let data = {
                ...buildingUnitData,
                propertyId,
                rooms: rooms?.rooms,
                energy_meters: energyMeters?.energyMeters
            }
            setLoading(true)
            if (buildingUnitData.id) {
                dispatch(
                    updateBuildingUnitAction(buildingUnitData?.id, data, setLoading, dispatch, (res) => {
                        handleSetBuildingUnitData(res, setBuildingUnitdata, setAllRooms, setAllEnergyMeters)
                        handleTabChange(activeIndex + 1)
                    })
                );
            } else {
                dispatch(
                    addBuildingUnitAction(
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            handleTabChange(activeIndex + 1)
                            handleSetBuildingUnitData(res, setBuildingUnitdata, setAllRooms, setAllEnergyMeters)
                        }
                    )
                )
            }
        }
    }

    //bankaccounts
    const [allBankAccounts, setAllBankAccounts] = useState([
        { id: null, purpose: "Combined", direct_debit: "", owner_name: "", iban: "", bic: "", status: "" },
    ])

    //furniture 
    const [allFurniture, setAllFurniture] = useState([]);
    //contacts
    const [allContacts, setAllContacts] = useState([initialContactsInput])

    //landlord
    const [landlordOptions, setLandlordOptions] = useState([])
    const [landlordAddress, setLandlordAddress] = useState()
    const [landLordData, setLandLordData] = useState({
        id: "",
        existingLandlord: "",
        landlordName: "",
        landlordType: "",
        address: "",
        houseNo: "",
        street: "",
        zipCode: "",
        city: "",
        cityDivision: "",
        country: "",
        email: "",
        phoneNumber: "",
        // directDebit: "",
        // furnitureProvided: "",
        // landlord_furniture: [],
        landlord_documents: [
            { contact_person: [] },
            { change_of_payments: [] },
            { change_of_property_management: [] },
        ]
    })

    useMemo(() => {
        if (landlordAddress) {
            handleSetAddress(landlordAddress, landLordData, setLandLordData, ["longitude", "latitude"])
        }
    }, [landlordAddress])

    // useEffect(() => {
    //     if (!landLordData?.landlord_furniture.length) {
    //         if (landLordData?.furnitureProvided === "yes" && allFurniture.length === 0) {
    //             setAllFurniture([{ id: null, type: "Kitchen", item: "", status: "" }])
    //         } else if (landLordData?.furnitureProvided === "no") {
    //             setAllFurniture([])
    //         }
    //     }
    // }, [landLordData])

    const LandlordChange = ({ name, value, ...rest }) => {
        const formErrors = formValidation(name, value, landLordData, ["existingLandlord"], ["phoneNumber", 'email']);
        if (name === "landlord_documents" && value) {
            const { documentType } = rest
            const documentIndex = landLordData.landlord_documents.findIndex(
                (doc) => Object.keys(doc)[0] === documentType
            );

            if (documentIndex !== -1) {
                const updatedBuildingDocument = [...landLordData.landlord_documents];
                updatedBuildingDocument[documentIndex] = {
                    [documentType]: [...updatedBuildingDocument[documentIndex][documentType], { type: documentType, "document": value }]
                };

                setLandLordData((prev) => ({
                    ...prev,
                    landlord_documents: updatedBuildingDocument
                }));
            }
        } else {
            if (name === "existingLandlord") {
                if (value?.landlord_id) {
                    const landlord = value
                    // handleSetLandlordData(landlord, setLandLordData, setAllContacts)
                    handleSetLandlordData(landlord, setLandLordData)
                    setAllContacts([initialContactsInput]);
                }
                if (value?.landlord_name && !value?.id) {
                    setLandLordData({
                        id: "",
                        existingLandlord: value?.landlord_name,
                        landlordName: value?.landlord_name,
                        landlordType: "",
                        address: "",
                        houseNo: "",
                        street: "",
                        zipCode: "",
                        city: "",
                        cityDivision: "",
                        country: "",
                        email: "",
                        phoneNumber: "",
                        landlord_documents: [
                            { contact_person: [] },
                            { change_of_payments: [] },
                            { change_of_property_management: [] },
                        ],
                    });
                    setAllContacts([initialContactsInput]);
                }
                setLandLordData((prev) => ({ ...prev, [name]: value?.landlord_name || value, formErrors }));
            } else {
                setLandLordData((prev) => ({ ...prev, [name]: value, formErrors }));
            }
        }

    };

    const handleLandlordFileDelete = (index, documentType) => {
        const updatedBuildingDocument = [...landLordData.landlord_documents];

        const documentIndex = landLordData.landlord_documents.findIndex(
            (doc) => Object.keys(doc)[0] === documentType
        );

        const documents = landLordData?.landlord_documents[documentIndex][documentType]
        documents.splice(index, 1);
        updatedBuildingDocument[documentIndex] = { [documentType]: [...updatedBuildingDocument[documentIndex][documentType]], documents }

        setLandLordData((prev) => ({ ...prev, ["landlord_documents"]: updatedBuildingDocument }))
    }

    const handleLandlordSearch = (company, value) => {
        dispatch(
            // searchLandlordAction(company, value, (res) => {
            //     setLandlordOptions(res)
            // if (!res.length) {
            //     const formErrors = ({ ["landlord"]: "Landlord does not exist!." })
            //     setData((prev) => ({ ...prev, formErrors }));
            // }
            // })
            landlordDropdownAction(company, value, "", (res) => {
                if (res?.length) {
                    const options = [{ landlordName: `${"Add New: " + value}`, landlord_name: value }]
                    res?.map((item) => {
                        options.push({ ...item, landlordName: `${item?.landlord_name + " (" + item?.landlord_id + ")"}` })
                    })
                    setLandlordOptions(options)
                } else {
                    const options = [{ landlordName: `${"Add New: " + value}`, landlord_name: value }]
                    setLandlordOptions(options)
                }
            })
        );
    };

    const landlordIdChange = useRef(debounce((value) => {
        handleLandlordSearch(userProfileRef.current?.company, value)
    }, 1000));

    const LandlordSubmit = () => {
        // const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts)
        // const furniture = checkFurnitureErrors(allFurniture, setAllFurniture)
        //&& !bankAccounts?.hasErrors  && !furniture?.hasErrors
        const contacts = checkContactErrors(allContacts, setAllContacts)
        if (showFormErrors(landLordData, setLandLordData, ["existingLandlord"], ["phoneNumber", 'email']) && !contacts?.hasErrors) {

            let data = {
                ...landLordData,
                propertyId,
                // bank_accounts: bankAccounts?.bankAccounts,
                contacts: contacts?.contacts,
                // landlord_furniture: furniture?.furniture,
            }
            setLoading(true)
            if (landLordData?.id) {
                dispatch(
                    updateLandlordAction(
                        userProfile?.company,
                        landLordData?.id,
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            // handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
                            handleSetLandlordData(res, setLandLordData)
                            handleTabChange(activeIndex + 1)
                        }
                    )
                )
            } else {
                dispatch(
                    addLandlordAction(
                        userProfile?.company,
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            // handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
                            handleSetLandlordData(res, setLandLordData)
                            handleTabChange(activeIndex + 1)
                        }
                    )
                )
            }
        }
    }

    // useEffect(() => {
    //     if (landLordData?.existingLandlord?.landlord_id) {
    //         const landlord = landLordData?.existingLandlord
    //         handleSetLandlordData(landlord, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
    //     }
    // }, [landLordData?.existingLandlord])

    // useEffect(() => {
    //     if (landLordData?.existingLandlord?.landlord_id) {
    //         const landlord = landLordData?.existingLandlord
    //         // handleSetLandlordData(landlord, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
    //         handleSetLandlordData(landlord, setLandLordData, setAllContacts)
    //     }
    // }, [landLordData?.existingLandlord?.landlord_id])

    // useEffect(() => {
    //     if (!landLordData?.existingLandlord?.landlord_id && landLordData?.existingLandlord?.landlordName) {
    //         setLandLordData({
    //             id: "",
    //             existingLandlord: landLordData?.existingLandlord?.landlord_name,
    //             landlordName: landLordData?.existingLandlord?.landlord_name,
    //             landlordType: "",
    //             address: "",
    //             houseNo: "",
    //             street: "",
    //             zipCode: "",
    //             city: "",
    //             cityDivision: "",
    //             country: "",
    //             email: "",
    //             phoneNumber: "",
    //             // directDebit: "",
    //             // furnitureProvided: "",
    //             // landlord_furniture: [],
    //             landlord_documents: [
    //                 { contact_person: [] },
    //                 { change_of_payments: [] },
    //                 { change_of_property_management: [] },
    //             ]
    //         })
    //     }
    // }, [landLordData?.existingLandlord?.landlordName])

    //contractual
    const [allMaturities, setAllMaturities] = useState([
        { id: "", extension: "", notice_period: "", status: "" }
    ])

    const [maturityErrors, setMaturityErrors] = useState([])
    // const [isContactExist, setIsContactExist] = useState("")

    const [ContractOptions, setContractOptions] = useState([])
    const [contractualData, setContractualData] = useState({
        id: "",
        existingContract: "",
        movingDate: "",
        // estateCompany: "",
        contractualSize: "",
        contractualNo: 0,
        maturityType: "",
        maturityDate: "",
        maturityExtension: "",
        noticePeriod: "",
        depositType: "",
        amount: "",
        furnitureProvided: "",
        // landlord_furniture: [],
        contract_documents: [
            { handover_protocol: [] },
            { key_protocol: [] },
            { floor_plan: [] },
            { photos: [] },
            { energy_bill: [] },
            { energy_contract: [] },
            { energymeter_replacement: [] },
        ]
    });

    const ContractualChange = ({ name, value, ...rest }) => {
        let ignoreInputs = []
        const formErrors = formValidation(name, value, contractualData, ignoreInputs);
        if (name === "document" && value) {
            const { documentType } = rest

            const documentIndex = contractualData.contract_documents.findIndex(
                (doc) => Object.keys(doc)[0] === documentType
            );
            if (documentIndex !== -1) {
                const updatedBuildingDocument = [...contractualData.contract_documents];
                updatedBuildingDocument[documentIndex] = {
                    [documentType]: [...updatedBuildingDocument[documentIndex][documentType], { type: documentType, "document": value }]
                };

                setContractualData((prev) => ({
                    ...prev,
                    contract_documents: updatedBuildingDocument
                }));
            }
        } else {
            if (name === "maturityType" && (value === "None" || !value)) {
                ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod")
                delete formErrors.maturityDate
                delete formErrors.maturityExtension
                delete formErrors.noticePeriod
            }

            if (data?.maturityType === "Optional Renewal") {
                ignoreInputs.push("noticePeriod")
                ignoreInputs.push("maturityExtension")
                delete formErrors.noticePeriod
                delete formErrors.maturityExtension
            }

            if (contractualData.depositType === "None") {
                ignoreInputs.push("amount")
                delete formErrors.amount
                setContractualData((prev) => ({ ...prev, ["amount"]: null, formErrors }));
            } else if (name === "maturityType" && value === "Automatic Renewal") {
                setContractualData((prev) => ({ ...prev, ["maturityExtension"]: 12, formErrors }));
            }

            if (name === "existingContract") {
                if (value?.id) {
                    const contract = value
                    handleSetContractData(contract, setContractualData, setAllMaturities, setAllBankAccounts)
                } else if (!value?.id && value?.contract_id) {
                    setContractualData({
                        id: "",
                        existingContract: value?.contract_id,
                        movingDate: "",
                        // estateCompany: "",
                        contractualSize: "",
                        contractualNo: 0,
                        maturityType: "",
                        maturityDate: "",
                        maturityExtension: "",
                        noticePeriod: "",
                        depositType: "",
                        amount: "",
                        furnitureProvided: "",
                        // landlord_furniture: [],
                        contract_documents: [
                            { handover_protocol: [] },
                            { key_protocol: [] },
                            { floor_plan: [] },
                            { photos: [] },
                            { energy_bill: [] },
                            { energy_contract: [] },
                            { energymeter_replacement: [] },
                        ],
                    })
                    setAllMaturities([])
                    setAllBankAccounts([])
                    setAllFurniture([])

                }
                setContractualData((prev) => ({ ...prev, [name]: value?.contract_id || value, formErrors }));

            } else {
                setContractualData((prev) => ({ ...prev, [name]: value, formErrors }));
            }

        }


        // if (name === "existingContract" && value) {
        //     // contractIdChange.current(value);
        //     setContractualData(prevState => ({
        //         ...prevState,
        //         id: ""
        //     }));
        // }

    };

    const handleContractFileDelete = (index, documentType) => {
        const updatedBuildingDocument = [...contractualData.contract_documents];

        const documentIndex = contractualData.contract_documents.findIndex(
            (doc) => Object.keys(doc)[0] === documentType
        );

        const documents = contractualData?.contract_documents[documentIndex][documentType]
        documents.splice(index, 1);
        updatedBuildingDocument[documentIndex] = { [documentType]: [...updatedBuildingDocument[documentIndex][documentType]], documents }

        setContractualData((prev) => ({ ...prev, ["contract_documents"]: updatedBuildingDocument }))
    }

    const handleContractSearch = (company, value) => {
        dispatch(
            searchContractAction(company, value, (res) => {
                setContractOptions(res)
                // if (res?.success) {
                //     const info = res?.data?.results


                //     // if (!info.length) {
                //     //     setIsContactExist(false)
                //     // } else {
                //     //     setIsContactExist(true)
                //     // }
                //     setContractOptions(info)
                // }
            })
        );
    };

    const contractIdChange = useRef(debounce((value, e) => {
        handleContractSearch(userProfileRef.current?.company, value)
    }, 1000));

    useEffect(() => {
        if (contractualData?.furnitureProvided === "yes" && allFurniture.length === 0) {
            setAllFurniture([{ id: null, type: "Kitchen", item: "", status: "", property: propertyId || "", landlord: landLordData?.id || "" }])
        } else if (contractualData?.furnitureProvided === "no") {
            setAllFurniture([])
        }
    }, [contractualData])

    const ContractualSubmit = () => {
        
        const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts)
        const furniture = checkFurnitureErrors(allFurniture, setAllFurniture)
        const maturities = checkOptionalMaturtiesErrors(allMaturities, setAllMaturities)
        let ignoreInputs = []
        if (contractualData?.maturityType === "None" || !contractualData?.maturityType && contractualData?.formErrors) {
            ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod")
            delete contractualData?.formErrors?.maturityDate
            delete contractualData?.formErrors?.maturityExtension
            delete contractualData?.formErrors?.noticePeriod
        }

        if (contractualData?.maturityType === "Optional Renewal") {
            ignoreInputs.push("noticePeriod", "maturityExtension")
            delete contractualData?.formErrors?.noticePeriod
        }

        if (contractualData.depositType === "None") {
            ignoreInputs.push("amount")
        }

        if (showFormErrors(contractualData, setContractualData, ignoreInputs) && !maturities?.hasErrors && !bankAccounts?.hasErrors && !furniture?.hasErrors) {
            setLoading(true)
            let data = {
                ...contractualData,
                landlordId: landLordData?.id,
                property: propertyId,
                optional_maturities: maturities?.optionalMaturities,
                bank_accounts: bankAccounts?.bankAccounts,
                landlord_furniture: furniture?.furniture,
            }
            if (contractualData?.id) {
                dispatch(
                    updateContractAction(userProfile?.company, contractualData?.id, data, setLoading, dispatch, (res) => {
                        dispatch(setPropertyContractSlice(res))
                        handleSetContractData(res, setContractualData, setAllMaturities, setAllBankAccounts)
                        handleTabChange(activeIndex + 1)
                    }))
            } else {
                dispatch(
                    addContractAction(userProfile?.company, data, setLoading, dispatch, (res) => {
                        handleSetContractData(res, setContractualData, setAllMaturities, setAllBankAccounts)
                        dispatch(setPropertyContractSlice(res))
                        handleTabChange(activeIndex + 1)
                    }))
            }
            // setIsContactExist(true)
        }

    }

    // useEffect(() => {
    //     if (contractualData?.existingContract?.contract_id) {
    //         const contract = contractualData.existingContract
    //         handleSetContractData(contract, setContractualData, setAllMaturities, setAllBankAccounts)
    //     }
    // }, [contractualData?.existingContract])

    //extra cost
    const [allExtraCost, setAllExtraCost] = useState([{
        id: "",
        type: "",
        payment_type: "",
        amount: "",
        amount_first_month: "",
        amount_last_month: "",
        applicable_tenant_rent: "",
        status: "",
    }])

    const [costErrors, setCostErrors] = useState([])

    //rent and cost
    const [rentAndCostData, setRentAndCostData] = useState({
        amount: "",
        amountFirstMonth: "",
        rentRiseType: "",
        intervalType: "",
        riseInterval: "",
        startDate: "",
        endDate: "",
        riseAmount: "",
        propertyRent: "Size",
    });

    const RentAndCostChange = ({ name, value, }) => {
        let isFirstDay = isFirstDayOfMonth(contractualData?.movingDate)
        let ignoreInputs = []
        if (name === "rentRiseType" && (value === "None" || !value)) {
            ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate")
        }

        if (isFirstDay) {
            ignoreInputs.push("amountFirstMonth")
        } else {
            ignoreInputs = ignoreInputs.filter((item) => item != "amountFirstMonth")
        }

        const formErrors = formValidation(name, value, rentAndCostData, ignoreInputs);
        setRentAndCostData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const RentAndCostSubmit = () => {
        let isFirstDay = isFirstDayOfMonth(contractualData?.movingDate)
        const extraCosts = checkExtraCostErrors(allExtraCost, setAllExtraCost)
        let ignoreInputs = []
        if (rentAndCostData?.rentRiseType === "None" || !rentAndCostData?.rentRiseType && rentAndCostData?.formErrors) {
            ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate")
            delete rentAndCostData?.formErrors?.intervalType
            delete rentAndCostData?.formErrors?.riseInterval
            delete rentAndCostData?.formErrors?.startDate
            delete rentAndCostData?.formErrors?.riseAmount
            delete rentAndCostData?.formErrors?.endDate
        }

        if (isFirstDay) {
            ignoreInputs.push("amountFirstMonth")
            delete rentAndCostData?.formErrors?.amountFirstMonth
        } else {
            ignoreInputs = ignoreInputs.filter((item) => item != "amountFirstMonth")
        }

        if (showFormErrors(rentAndCostData, setRentAndCostData, ignoreInputs) && !extraCosts?.hasErrors) {
            let data = {
                ...rentAndCostData,
                extra_costs: extraCosts?.extraCosts
            }

            dispatch(
                addRoomAndCostAction(
                    userProfile.company,
                    contractualData?.id,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        handleSetRentData(res, setRentAndCostData, setAllExtraCost)
                        handleTabChange(activeIndex + 1)
                    }))

        }
    }

    const onSubmit = () => {
        if (activeIndex === 0) {
            const propertyError = showFormErrors(data, setData)
            const buildingError = showFormErrors(buildingStuctureData, setBuildingStuctureData, ["document", "longitude", "latitude", "address", "street", "houseNo", "zipCode", "country", "city", "cityDivision"])

            // BuildingStuctureSubmit()
            if (propertyError && buildingError) {
                setIsWarning(true)
            }
        } else if (activeIndex === 1) {
            BuildingUnitSubmit()
        } else if (activeIndex === 2) {
            LandlordSubmit()
        } else if (activeIndex === 3) {
            ContractualSubmit()
        } else if (activeIndex === 4) {
            RentAndCostSubmit()
        } else if (activeIndex === 5) {
            navigate("/real-estate/property/list")
        }
    };

    return {
        //common
        loading,
        // skipAble,
        activeIndex,
        handleTabChange,
        disableTabs,
        setTabToActive,

        //add property
        data,
        setData,
        handleChange,
        onSubmit,
        propertyIdOptions,
        propertyId,
        setSelectedAddress,
        propertyIdChange,

        //building structure
        buildingStuctureData,
        BuildingStuctureHandleChange,
        buildingOptions,
        fileKey,
        sturcureDocuments,
        // handleStructureFileChange,
        handleStructureFileDelete,
        isWarning,
        setIsWarning,
        BuildingStuctureSubmit,

        //building unit
        buildingUnitData,
        BuildingUnitChange,
        handleUnitFileDelete,
        // buildingUnitDocuments,
        // setBuildingUniteDocuments,
        // unitfileKey,
        //rooms
        allRooms,
        setAllRooms,

        //energy metters
        allEnergyMeters,
        setAllEnergyMeters,

        //landlord
        landlordOptions,
        LandlordChange,
        landLordData,
        setLandlordAddress,
        landlordIdChange,
        handleLandlordFileDelete,

        //bank accounts
        allBankAccounts,
        setAllBankAccounts,

        //contacts
        allContacts,
        setAllContacts,

        //furniture
        allFurniture,
        setAllFurniture,

        //contractual
        ContractOptions,
        contractualData,
        ContractualChange,
        allMaturities,
        setAllMaturities,
        maturityErrors,
        setMaturityErrors,
        contractIdChange,
        // isContactExist,
        handleContractFileDelete,

        //rise and cost
        rentAndCostData,
        RentAndCostChange,
        RentAndCostSubmit,

        //extra cost
        allExtraCost,
        setAllExtraCost,
        costErrors,
        setCostErrors,
        navigate
    }
}
export default PropertyContainer