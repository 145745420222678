import React, { useEffect } from "react";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { capitalizeCamelCase } from "../utils/commonFunctions";

const GooglePlacesInput = ({
  id,
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  setAddress,
  ...props
}) => {
  useEffect(() => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      initMap();
    }
  }, []);

  const initMap = () => {

    var options = {
      // types: ['(cities)'],
      componentRestrictions: {country: "de"}
     };

    let autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(id), options
    );
    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();
      let lat = "";
      let lng = "";
      let address = "";
      let zipCode = "";
      let city = "";
      let cityDivision = "";
      let street = "";
      // let state = "";
      // let countryShort = "";
      let countryLong = "";
      let house = "";

      if (selectedPlace.address_components) {
        lat = selectedPlace?.geometry?.location.lat();
        lng = selectedPlace?.geometry?.location.lng();

        if (selectedPlace?.formatted_address?.includes(selectedPlace?.name)) {
          address = selectedPlace?.formatted_address;
        } else {
          address = `${selectedPlace?.name}, ${selectedPlace?.formatted_address}`;
        }

        selectedPlace?.address_components?.forEach((component) => {
          if (component?.types?.includes("postal_code")) {
            zipCode = component?.short_name;
          } else if (component?.types?.includes("locality")) {
            city = component?.long_name;
          } else if (component?.types?.includes("country")) {
            // countryShort = component?.short_name;
            countryLong = component?.long_name;
          } else if (component?.types?.includes("sublocality")) {
            cityDivision = component?.long_name;
            // state = component?.long_name;
          } else if (component?.types?.includes("route")) {
            street = component?.long_name;
            // state = component?.long_name;
          } else if (component?.types?.includes("street_number")){
            house = component?.long_name
          }
        });
        if(zipCode.length < 5 && zipCode.length > 0){
          const zipString = String(zipCode);
          zipCode = zipString.padStart(5, '0')
        }
        setAddress && setAddress({
          address: address,
          lng: lng,
          lat: lat,
          zipCode: zipCode || "",
          city: city,
          country: countryLong,
          cityDivision: cityDivision,
          street: street,
          house: house
        });
      }
    });
  };

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <InputText
        id={id}
        name={name}
        value={value || data?.[name] || ""}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        disabled={disabled}
        {...props}
      />
      {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
    </InputLayout>
  );
};

export default React.memo(GooglePlacesInput);
