import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { checkErrors } from "shared/Components/commonFunctions";
import { addTenantFamilyAction, getTenantDetailsAction, getTenantFamilyListAction, updateTenantAction } from "store/actions/TenantsActions";
import { handleFormatDate, reformatDateString, showFormErrors } from "utils/commonFunctions";
import formValidation from "utils/validations";

const ViewTenantsContainer = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const { tenant } = useSelector((state) => state.tenant)
    const { profile } = useSelector((state) => state.user)
    const [activeIndex, setActiveIndex] = useState(0)
    const [data, setData] = useState({
        tenantId: "",
        firstName: "",
        lastName: "",
        tenantType: "",
        dateOfBirth: "",
        email: "",
        phoneNumber: "",
        addressBeforeMovingIn: "",
    });

    const [socialOfficeData, setSocialOfficeData] = useState({
        socialOffice: "",
        employee: "",
        service: "",
        contractDate: ""
    })
    const [allFamilies, setAllFamilies] = useState([])
    const [isEdit, setIsEdit] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (params?.id && profile?.company) {
            if (tenant) {
                setData({
                    tenantId: tenant?.tenant_id,
                    firstName: tenant?.first_name,
                    lastName: tenant?.last_name,
                    tenantType: tenant?.tenant_type,
                    dateOfBirth: reformatDateString(tenant?.date_of_birth),
                    email: tenant?.email,
                    phoneNumber: tenant?.phone_number,
                    addressBeforeMovingIn: tenant?.address_before_moving_in,
                })
                setSocialOfficeData({
                    socialOffice: tenant?.social_office,
                    employee: tenant?.employee,
                    service: tenant?.service,
                    contractDate: reformatDateString(tenant?.starting_date_of_contract)
                })
            } else {
                getTenantDetails(profile?.company, params?.id)
            }
            getTenantFamilies(params?.id)
        }
    }, [params?.id, profile])

    const getTenantDetails = (company, id) => {
        dispatch(getTenantDetailsAction(company, id, dispatch, (res) => {
            setData({
                tenantId: res?.tenant_id,
                firstName: res?.first_name,
                lastName: res?.last_name,
                tenantType: res?.tenant_type,
                dateOfBirth: reformatDateString(res?.date_of_birth),
                email: res?.email,
                phoneNumber: res?.phone_number,
                addressBeforeMovingIn: res?.address_before_moving_in,
            })
            setSocialOfficeData({
                socialOffice: res?.social_office,
                employee: {id: res?.employee?.id, name: `${res?.employee?.name} (${res?.employee?.email})`},
                service: res?.service,
                contractDate: reformatDateString(res?.starting_date_of_contract)
            })
        }))
    }

    const getTenantFamilies = (id) => {
        dispatch(getTenantFamilyListAction(id, dispatch, (res) => {
            const options = res?.map((item) => {
                return {
                    ...item,
                    date_of_birth: reformatDateString(item?.date_of_birth)
                }
            })
            setAllFamilies(options)
        }))
    }

    const handleTabChange = (index) => {
        setActiveIndex(index)
    }

    const onEdit = (section) => {
        setIsEdit(section)
    }

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }

    const handleSocialOfficeChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, socialOfficeData);
        setSocialOfficeData((prev) => ({ ...prev, [name]: value, formErrors }));
    }

    const tenantSubmit = () => {
        if (showFormErrors(data, setData, ["id"])) {
            if (params?.id) {
                const payload = {
                    tenant_id: data?.tenantId,
                    first_name: data?.firstName,
                    last_name: data?.lastName,
                    tenant_type: data?.tenantType,
                    date_of_birth: handleFormatDate(data?.dateOfBirth),
                    email: data?.email,
                    phone_number: data?.phoneNumber,
                    starting_date_of_contract: handleFormatDate(data?.contractDate),
                    address_before_moving_in: data?.addressBeforeMovingIn
                }
                dispatch(
                    updateTenantAction(profile?.company, params?.id, payload, setLoading, dispatch, (res) => {
                        setIsEdit("")
                    }))
            }
        }
    }

    const socialOfficeSubmit = () => {
        if (showFormErrors(socialOfficeData, setSocialOfficeData)) {
            if (params?.id) {
                const payload = {
                    starting_date_of_contract: handleFormatDate(socialOfficeData?.contractDate),
                    social_office: socialOfficeData?.socialOffice?.id,
                    employee: socialOfficeData?.employee?.id,
                    service: socialOfficeData?.service?.id,
                }

                dispatch(
                    updateTenantAction(profile?.company, params?.id, payload, setLoading, dispatch, (res) => {
                        setIsEdit("")
                    }))
            }
        }
    }

    const familySubmit = () => {
        const famlies = checkErrors(allFamilies, setAllFamilies, ["first_name", "last_name", "date_of_birth"])
        if (!famlies.hasErrors && famlies?.values?.length) {
            dispatch(addTenantFamilyAction(famlies?.values, setLoading, dispatch, (res) => {
                setIsEdit("")
            }))
        }else {
            setIsEdit("")
        }
    }

    const onSubmit = () => {
        if (isEdit === "tenant") {
            tenantSubmit()
        } else if (isEdit === "socialOffice") {
            socialOfficeSubmit()
        } else if (isEdit === "family") {
            familySubmit()
        }
    };

    return {
        data,
        activeIndex,
        handleTabChange,
        onEdit,
        isEdit,
        handleChange,
        loading,
        onSubmit,
        allFamilies,
        setAllFamilies,
        socialOfficeData,
        setSocialOfficeData,
        handleSocialOfficeChange,
        tenantId: params?.id
    }
}

export default ViewTenantsContainer