import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const tenantSlice = createSlice({
    name: "tenant",
    initialState,
    reducers: {
        setTenantSlice: (state, action) => {
            state.tenant = action.payload
        },
    },

});

export const { setTenantSlice } = tenantSlice.actions;
export default tenantSlice.reducer;