import React, { useEffect, useState } from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomCalander, CustomDropDown, CustomForm } from "shared/AllInputs";
import { CustomDateViewInput, CustomViewInput } from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/images/icons/editicon.png";
import {
    getOfficeInfoAction,
} from "store/actions/officeActions";
import { MaturityType } from "shared/AllDropDownOptions";
import formValidation from "utils/validations";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "shared/CustomButton";

export default function TerminateContract() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState();
    const [terminationDetails, setTerminationDetails] = useState({
        who: "",
        expectedDate: "",
        readjustedDate: "",
    })
    const { profile } = useSelector((state) => state.user);

    useEffect(() => {
        if (profile?.company) {
            getOfficeInfo(profile?.company);
        }
    }, [profile]);

    const header = () => {
        return (
            <div className="flex justify-content-around cursor-pointer">
                <img
                    src={EditIcon}
                    alt=""
                    onClick={() => navigate(`/administrator/office/edit/${params?.id}`)}
                ></img>
            </div>
        );
    };

    const getOfficeInfo = (company) => {
        dispatch(
            getOfficeInfoAction(company, params?.id, dispatch, (res) => {
                let employees = res?.employees?.map((emp) => {
                    return {
                        employee: { name: `${emp?.employee?.name} (${emp?.employee?.email})`, value: emp?.employee?.id },
                        role: { name: emp?.employee?.role, value: emp?.employee?.role_id },
                        position: emp?.position,
                        id: emp?.id,
                    };
                });
                let info = {
                    officeId: res?.office_id,
                    officeName: res?.name,
                    // officeNickName: res?.nick_name,
                    officeEmail: res?.office_email,
                    phoneNumber: res?.phone_number,
                    unit: res?.office_unit?.name,
                    // officePurpose: res?.purpose,
                    // officeRepresentative: res?.office_rep?.name,

                    address: res?.address,
                    street: res?.street,
                    zipCode: res?.zipcode,
                    city: res?.city,
                    cityDivision: res?.city_division,
                    country: res?.country,
                    houseNo: res?.house_number,
                    costCenter: res?.cost_center,
                    banks: res?.bank_accounts,
                    // employees: employees,
                    invitedEmployees: res?.invite_employee,
                };
                setData(info);
            })
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, terminationDetails)
        setTerminationDetails((prev) => ({ ...prev, [name]: value, formErrors }))
    }

    const onSubmit = ({}) => {

    }
    
    return (
        <Page title="Termination Details" description={`Let’s add your Termination Details`} navigateLink={"/administrator/office/list"}>
            <CustomCard>
                <CustomForm title="Property Details" header={header}>
                    <CustomViewInput name="propertyId" data={data} />
                    <CustomViewInput name="address" data={data} />
                    <CustomViewInput name="landlordName" data={data} />
                    <CustomViewInput name="typeOfMaturity" data={data} />
                    <CustomViewInput name="status" data={data} />
                    <CustomDateViewInput name="maturityDate" data={data} />
                    <CustomViewInput name="keyOverHanding" data={data} />
                </CustomForm>
                <CustomForm title="Tenant Info">
                    <CustomViewInput name="tenantName" data={data} />
                    <CustomDateViewInput name="movingInDate" data={data} />
                    <CustomDateViewInput name="movingOutDate" data={data} />
                    <CustomViewInput name="rent" data={data} />
                </CustomForm>
                <CustomForm title="Termination Details">
                    <CustomDropDown
                        data={terminationDetails}
                        onChange={handleChange}
                        name="maturityType"
                        label={"Who initiated the termination?"}
                        options={MaturityType}
                        required
                    />
                    <CustomCalander
                        minDate={new Date()}
                        data={terminationDetails}
                        onChange={handleChange}
                        name="expectedDate"
                        required
                    />
                    <CustomCalander
                        minDate={new Date()}
                        data={terminationDetails}
                        onChange={handleChange}
                        name="readjustedDate"
                        required
                    />
                </CustomForm>
            </CustomCard>
            <Buttonlayout>
                <CustomButton onClick={onSubmit} label={"Save"} loading={loading} />
                <CustomButtonOutlined label="Cancel" onClick={() => navigate("/administrator/office/list")} />
            </Buttonlayout>
        </Page>
    );
}
