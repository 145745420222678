import React from "react";
import LandlordContainer from "./LandlordContainer";
import CustomCard from "shared/CustomCard";
import Page from "shared/Page";
import ViewLandlordInfo from "views/CommonViewComponents/LandlordInfo";
import CustomLandlordContactTable from "views/Tables/CustomLandlordContactTable";
import CustomBankAccountTable from "views/Tables/CustomBankAccountTable";

export default function ViewLandlord() {
  const {
    data,
    allContacts,
    setAllContacts,
    allBankAccounts,
    setAllBankAccounts,
  } = LandlordContainer();

  return (
    <Page
      title="View Details"
      description={`Let’s View your Landlord Details`}
      navigateLink={"/real-estate/landlord/list"}
    >
      <CustomCard>
        <ViewLandlordInfo
          type="landlord"
          data={data}
        />
        <CustomLandlordContactTable
          // action="landlord"
          landlordId={data?.id}
          title={"Contacts"}
          allContacts={allContacts}
          setAllContacts={setAllContacts}
        />
        <CustomBankAccountTable
          action={"landlord"}
          id={data?.id}
          title="Banking Details"
          allBankAccounts={allBankAccounts}
          setAllBankAccounts={setAllBankAccounts}
        />
      </CustomCard>
    </Page>
  );
}
