import { entries, notEqual, values } from "./javascript";
import formValidation from "./validations";
import constants from "../constants";
import { KEYS } from "./keys";
import { truncateText, zipCodeValidation } from "./regex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const commentime = (createdAt) => {
  return dayjs(createdAt).fromNow();
};

export const parseDateString = (datetimeStr) => {
  const [datePart, timePart] = datetimeStr.split(" ");
  const [day, month, year] = datePart.split(".").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);
  return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
};

export const timeAgo = (createdAt) => {
  const utcDateD = parseDateString(createdAt);
  const utcDate = new Date(utcDateD);
  const targetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert to IST
  const istDate = new Date(
    utcDate.toLocaleString("en-US", { timeZone: targetTimezone })
  );
  const localTime = commentime(istDate);
  return localTime;
};

export const showFormErrors = (data, setData, ignore, errorName) => {
  let formErrors = {};
  entries(data).forEach(([key, value]) => {
    formErrors = {
      ...formErrors,
      ...formValidation(key, value, data, ignore, errorName),
    };
  });

  setData({ ...data, formErrors });
  return !values(formErrors).some((v) => notEqual(v, ""));
};

export const capitalizeCamelCase = (str) => {
  if (str) {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Convert camelCase to space-separated
      .replace(/_/g, " ") // Convert snake_case to spaces
      .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize the first letter
      .replace(/\b\w/g, (char) => char.toUpperCase());
    // let words = str.split(/(?=[A-Z])/);
    // let capitalizedWords = words.map(function (word) {
    //   return word.charAt(0).toUpperCase() + word.slice(1);
    // });
    // let capitalizedString = capitalizedWords.join(" ");
    // return capitalizedString;
  }
};

export const getImageUrl = (image) => {
  if (image.includes("http")) {
    return image;
  } else {
    return constants.baseUrl + image;
  }
};

export const storePropertyId = (propertyId) => {
  if (propertyId) {
    localStorage.setItem(KEYS?.PropertyId, propertyId);
  }
};

export const handleFileConversion = (file) => {
  return new Promise((resolve, reject) => {
    if (file && typeof file === "object") {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(`${file?.name};base64,${base64String}`);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    } else {
      resolve(file); // or reject with an appropriate message if needed
    }
  });
};

export const handleFormatDate = (value, action) => {
  if (value) {
    let date = new Date(value);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let formattedDate = "";

    if (action === "year") {
      formattedDate = year;
    } else {
      // formattedDate = year + "-" + month + "-" + dt;
      // formattedDate = dt + "-" + month + "-" + year;
      formattedDate = dt + "." + month + "." + year;
    }
    return formattedDate;
  }
};

export const reformatDateString = (value) => {
  if (value) {
    const [datePart, timePart] = value.split(" ");
    // Split the string by dots and rearrange it to "YYYY-MM-DD"
    const [day, month, year] = datePart.split(".");
    const formattedDate = `${year}-${month}-${day}`;
    // // Now use the formatted date with the Date constructor
    const date = new Date(formattedDate);
    return date;
  } else {
    return "";
  }
};

export const setDateRangeForPreviousYear = (year) => {
  const selectedYear = year.getFullYear();
  // const previousYear = selectedYear - 1;
  const previousYear = selectedYear;
  const startDate = new Date(previousYear, 0, 1);
  const endDate = new Date(previousYear, 11, 31);
  return { startDate: startDate, endDate: endDate };
};

export const handleSetAddress = (
  selectedAddress,
  data,
  setData,
  ignore = []
) => {
  const {
    address,
    zipCode,
    city,
    country,
    cityDivision,
    street,
    lng,
    lat,
    house,
  } = selectedAddress;
  let newFormErrors = { ...data.formErrors };

  if (address) {
    newFormErrors.address = "";
  }
  if (zipCode) {
    if (zipCodeValidation(zipCode)) {
      newFormErrors.zipCode = "";
    } else {
      newFormErrors.zipCode = "Please enter valid Zip Code!";
    }
  }
  if (city) {
    newFormErrors.city = "";
  }
  if (country) {
    newFormErrors.country = "";
  }
  if (cityDivision) {
    newFormErrors.cityDivision = "";
  }
  if (street) {
    newFormErrors.street = "";
  }

  if (house) {
    newFormErrors.houseNo = "";
  }

  setData((prev) => ({
    ...prev,
    ["address"]: address,
    ["zipCode"]: zipCode || "",
    ["city"]: city || "",
    ["country"]: country || "",
    ["cityDivision"]: cityDivision || "",
    ["street"]: street || "",
    ["houseNo"]: house || "",
    formErrors: newFormErrors,

    ...(!ignore.includes("longitude") && { longitude: lng }),
    ...(!ignore.includes("latitude") && { latitude: lat }),
  }));
};

export const checkIsValueGreater = (value, maxValue) => {
  const numericValue = Number(value);
  const numericMaxSize = Number(maxValue);

  if (numericValue > numericMaxSize) {
    return true;
  } else {
    return false;
  }
};

export const convertAmountInGerman = (value) => {
  let val = Number(value);
  if (val) {
    return val.toLocaleString("de-DE", {
      // style: "currency",
      // currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return "-----";
  }
};

export const getFileExtension = (filePath) => {
  if (filePath) {
    return filePath?.split(".").pop().split(/\#|\?/)[0];
  }
};

export const getFileName = (filePath) => {
  if (filePath) {
    return filePath?.split("/").pop().split(".")[0];
  }
};

export const getTimeOnly = (value) => {
  const date = new Date(value);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const time = `${hours}:${minutes}`;
  return time;
};

export const getStatusColors = (status) => {
  switch (status) {
    case "Initiated":
    case "New":
    case "Inactive":
    case "inactive":
      return "text-red-600";

    case "Fully":
    case "Back Office":
    case "Residing":
      return "text-yellow-500";

    case "Social Worker":
    case "Moving Out":
      return "text-blue-600";

    case "Follow-up":
      return "text-orange-500";

    case "Partially":
    case "Pending":
    case "Offered":
    case "Fixed":
      return "text-green-600";

    case "Handyman":
      return "text-cyan-500";

    default:
      return "";
  }
};

// export const hoursAgo = (dateString) => {
//   const createdAt = new Date(dateString);
//   const now = new Date();
//   const differenceInMilliseconds = now - createdAt;
//   const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
//   if (differenceInHours === -1) {
//     return 0;
//   } else {
//     return differenceInHours;
//   }
// };

// export const timesAgo = (timestamp) => {
//   return dayjs(timestamp).fromNow();
// };

// export const timesAgo = (dateString) => {
//   const format = "DD.MM.YYYY HH:mm:ss";

// const createdAt = dayjs.utc(dateString, format).local();

// if (!createdAt.isValid()) {
//   return "Invalid date";
// }

//   return
//   const createdAt = dayjs.utc(dateString, format).local();

//   const now = dayjs();

//   const differenceInMilliseconds = now.diff(createdAt);
//   // const differenceInMilliseconds = now.diff(createdAt);

//   const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
//   const differenceInMinutes = Math.floor(
//     differenceInMilliseconds / (1000 * 60)
//   );
//   const differenceInHours = Math.floor(
//     differenceInMilliseconds / (1000 * 60 * 60)
//   );
//   const differenceInDays = Math.floor(
//     differenceInMilliseconds / (1000 * 60 * 60 * 24)
//   );
//   const differenceInWeeks = Math.floor(
//     differenceInMilliseconds / (1000 * 60 * 60 * 24 * 7)
//   );
//   const differenceInMonths = Math.floor(differenceInDays / 30.42);
//   const differenceInYears = Math.floor(differenceInDays / 365);

//   if (differenceInSeconds < 60) {
//     return `${differenceInSeconds} seconds ago`;
//   } else if (differenceInMinutes < 60) {
//     return `${differenceInMinutes} minutes ago`;
//   } else if (differenceInHours < 24) {
//     return `${differenceInHours} hours ago`;
//   } else if (differenceInDays < 7) {
//     return `${differenceInDays} days ago`;
//   } else if (differenceInWeeks < 5) {
//     return `${differenceInWeeks} weeks ago`;
//   } else if (differenceInMonths < 12) {
//     return `${differenceInMonths} months ago`;
//   } else {
//     return `${differenceInYears} years ago`;
//   }
// };

export const getUserAvtar = (firstName, lastName) => {
  return firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase();
};

export const getFileNameExtension = (name) => {
  if (name) {
    let fileName = getFileName(name);
    const extension = getFileExtension(name);
    return truncateText(fileName, 20) + extension;
  }
};

//check selected date is 1st of month or not
export const isFirstDayOfMonth = (date) => {
  if (date) {
    const userDate = new Date(date);
    return userDate.getDate() === 1;
  } else {
    return false;
  }
};
