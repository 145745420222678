import { handleFormatDate, reformatDateString } from "utils/commonFunctions";
import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setTenantSlice } from "store/slices/tenantSlice";

const addTenantAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        tenant_id: data?.tenantId,
        first_name: data?.firstName,
        last_name: data?.lastName,
        tenant_type: data?.tenantType,
        date_of_birth: handleFormatDate(data?.dateOfBirth),
        email: data?.email,
        phone_number: data?.phoneNumber,
        starting_date_of_contract: handleFormatDate(data?.contractDate),
        social_office: data?.socialOffice?.id,
        employee: data?.employee?.id,
        service: data?.service?.id,
        address_before_moving_in: data?.addressBeforeMovingIn
    }

    const res = await api(
        "post",
        endPoints?.TENANT + `/${company}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
        setTenantSlice(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const addTenantFamilyAction = (families, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const updatedFamlies = families?.map((family) => {
        return {
            ...family,
            date_of_birth: handleFormatDate(family?.date_of_birth)
        }
    })
    const res = await api(
        "post",
        endPoints?.TENANT_FAMILY_MULTIPLE,
        { tenant_families: updatedFamlies }
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const getTenantListAction = (company, rows, page, search, tenantId, tenantName, tenantType, status, baseStatus, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT + `/${company}/?limit=${rows}&page=${page}&search=${search}&tenant_id=${tenantId || ""}&tenant_name=${tenantName || ""}&tenant_type=${tenantType || ""}&status=${status || ""}&base_status=${baseStatus || "active"}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        const options = info?.map((item) => {
            return {
                ...item,
                tenant_name: `${item?.first_name} ${item?.last_name}`,
            }
        })

        onRes({ options: options, count: res?.data?.count })
    }
};

const getTenantDetailsAction = (company, tenant, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT + `/${company}/${tenant}`,
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(setTenantSlice(info))
        onRes(info)
    }
}

const getTenantFamilyListAction = (tenant, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT_FAMILY + `?tenant=${tenant}`,
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
    }
}

const archiveTenantAction = (company, ids, action, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "patch",
        endPoints?.TENANT + `/${company}/${action}/`,
        { ids: ids },
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    setDeleteModal(false)
};

const updateTenantAction = (company, tenant, payload, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "put",
        endPoints?.TENANT + `/${company}/${tenant}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
        setTenantSlice(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const updateTenantFamilyAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.TENANT_FAMILY + `/${id}/`,
        data
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const deleteTenantFamilyAction = (id, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.TENANT_FAMILY + `${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    setDeleteModal(false)
};

const getTenantSocialHistoryAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT_SOCIAL_HISTORY + `?tenant=${id}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        const options = info?.map((item) => {
            return {
                ...item,
                tenant_name: `${item?.first_name} ${item?.last_name}`,
            }
        })

        onRes({ options: options, count: res?.data?.count })
    }
};

const getTenantFamilyDetailsAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT_FAMILY + `/${id}/`,
    );
    if (res?.success) {
        let info = res?.data?.results

        info = {
            ...info,
            date_of_birth: reformatDateString(info?.date_of_birth)
        }
        onRes(info)
    }
};

export {
    addTenantAction,
    addTenantFamilyAction,
    getTenantListAction,
    getTenantDetailsAction,
    getTenantFamilyListAction,
    archiveTenantAction,
    updateTenantAction,
    updateTenantFamilyAction,
    deleteTenantFamilyAction,
    getTenantSocialHistoryAction,
    getTenantFamilyDetailsAction
};
