import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";

const getServicesListAction = (company, rows, page, search, name, email, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT_SERVICE_SETUP + `/${company}/?limit=${rows}&page=${page}&search=${search || ""}`,
        //&search=${search || ""}&name=${name || ""}&email=${email || ""}
    );

    if (res?.success) {
        const options = res?.data?.results?.map((item) => {
            return {
                ...item,
                name: item?.first_name + " " + item?.last_name
            }
        })

        onRes({ options: options, count: res?.data?.count || res?.data?.results?.length })
    }
};

const serviceActiveAction = (company, service, dispatch, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.TENANT_SERVICE_SETUP + `/${company}/`,
        {
            service: service,
            company: company
        }
    );
    if (res.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const serviceInactiveAction = (company, service, dispatch, onRes) => async () => {
    const res = await api(
        "delete",
        endPoints?.TENANT_SERVICE_SETUP + `/${company}/${service}/`,
    );
    if (res.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const searchServiceAction = (company, name, isSearch, setOptions, onError) => async () => {
    isSearch && isSearch(true)
    const res = await api(
        "get",
        endPoints?.TENANT_SERVICE_SETUP + `/${company}/linked-services/?service_service_name=${name}`,
    );

    if (res.success) {

        if (res?.data?.results.length) {
            const options = res?.data?.results?.map((item) => {
                return {
                    ...item, 
                    name: item?.service_name
                }
            })
            setOptions(options)
        } else {
            setOptions([])
            onError && onError("Service does't exist.")
        }
    } else {
        setOptions([])
    }
    isSearch && isSearch(true)

};

export {
    serviceActiveAction,
    serviceInactiveAction,
    getServicesListAction,
    searchServiceAction,
}