import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setHandymanSlice, setHandymanUpdateSlice } from "../slices/handymanSlice";

const addHandymanAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        name: data?.name,
        address: data?.address,
        house_number: data?.houseNo,
        city: data?.city,
        division: data?.cityDivision,
        street: data?.street,
        zipcode: data?.zipCode,
        country: data?.country,
        feedback: data?.feedback,
        services: data?.services,
    }
    const res = await api(
        "post",
        endPoints?.HANDYMAN + `/${company}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const updateHandymanAction = (company, handyman, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        name: data?.name,
        address: data?.address,
        house_number: data?.houseNo,
        city: data?.city,
        division: data?.cityDivision,
        street: data?.street,
        zipcode: data?.zipCode,
        country: data?.country,
        feedback: data?.feedback,
        services: data?.services,
    }
    const res = await api(
        "put",
        endPoints?.HANDYMAN + `/${company}/${handyman}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const updateHandymanServiceAction = (company, id, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        name: data?.serviceName
    }
    const res = await api(
        "put",
        endPoints?.HANDYMAN_SERVICE + `/${company}/${id}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const deleteHandymanAction = (company, id, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.HANDYMAN + `/${company}/${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    setDeleteModal(false)
};

const archiveHandymanAction = (company, ids, action, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "patch",
        endPoints?.HANDYMAN + `/${company}/${action}/`,
        {ids: ids},
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    setDeleteModal(false)
};

const getHandymanListAction = (company, rows, page, search, name, service, baseStatus, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.HANDYMAN + `/${company}/?limit=${rows}&page=${page}&search=${search}&name=${name || ""}&service=${service || ""}&base_status=${baseStatus || "active"}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes({ options: info, count: res?.data?.count })
    }
};

const searchHandymanByNameAction = (company, name, setNameOptions, setIsSearch, onError) => async () => {
    setIsSearch(true)
    const res = await api(
        "get",
        endPoints?.SEARCH_HANDYMAN + `/${company}/${name}?base_status=active`,
    );

    if (res?.success) {
        setNameOptions(res?.data?.results)
        onError(`${res?.message}!`)
    } else {
        setNameOptions([])
    }

    setIsSearch(false)

};

const getHandymanAction = (company, handyman, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.HANDYMAN + `/${company}/${handyman}`,
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(setHandymanSlice(info))
        dispatch(setHandymanUpdateSlice(false))
        onRes(info)
    }
}

const handleAddServiceActon = (handyman, data, setLoading, dispatch, onRes) => async () => {
    let payload = {
        handyman: handyman,
        service: data?.service,
        email: data?.email,
        work_phone: data.work_phone,
        home_phone: data?.home_phone
    }
    setLoading(true)
    const res = await api(
        "post",
        endPoints?.SERVICE,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
        dispatch(setHandymanUpdateSlice(true))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))

    }
    setLoading(false)
}

const handleUpdateServiceActon = (handyman, data, setLoading, dispatch, onRes) => async () => {
    let payload = {
        handyman: handyman,
        service: data?.service,
        email: data?.email,
        work_phone: data.work_phone,
        home_phone: data?.home_phone
    }
    setLoading(true)
    const res = await api(
        "put",
        endPoints?.SERVICE + `${data?.id}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
        dispatch(setHandymanUpdateSlice(true))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))

    }
    setLoading(false)
}

const changeServiceStatusActon = (id, status, reason, setLoading, dispatch, onRes) => async () => {
    let payload = {
        status: status,
    }

    if (reason) {
        payload.reason = reason
    }

    setLoading(true)
    const res = await api(
        "patch",
        endPoints?.SERVICE + `${id}/block-unblock/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
        dispatch(setHandymanUpdateSlice(true))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))

    }
    setLoading(false)
}

const deleteHandymanServiceActon = (service, setLoading, setIsDeleteModal, setSelectedService, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.SERVICE + `${service}/`
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
        dispatch(setHandymanUpdateSlice(true))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))

    }
    setLoading(false)
    setIsDeleteModal(false)
    setSelectedService("")
}

export {
    addHandymanAction,
    getHandymanListAction,
    updateHandymanServiceAction,
    deleteHandymanAction,
    getHandymanAction,
    updateHandymanAction,
    searchHandymanByNameAction,
    archiveHandymanAction,

    //service
    handleAddServiceActon,
    handleUpdateServiceActon,
    changeServiceStatusActon,
    deleteHandymanServiceActon
};
