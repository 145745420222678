import React, { useState, useRef } from "react";
import {
    CustomCalander,
    CustomForm,
    CustomInput,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
    handleCancelNew,
    handleDeleteNew,
    handlEdit,
    HandleAddNew,
    handleChangeNew,
    handleSaveNew
} from "shared/Components/commonFunctions";
import { useDispatch } from "react-redux";
import CrossIcon from "assets/images/svg/cross-icon.svg"
import SaveIcon from "assets/images/svg/Save-icon.svg"
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { InitialFamilyInputs } from "shared/initialInputs";
import { deleteTenantFamilyAction, getTenantFamilyDetailsAction } from "store/actions/TenantsActions";

const AddFamily = ({
    tenantId,
    allValues,
    setAllValues,
}) => {
    const dispatch = useDispatch();
    const openAction = useRef(null);
    const ignoreInputs = ["status", "id"];
    const optional = [];
    const [deleteModal, setDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

    const modalContent = () => {
        return (
            <span>
                Are you sure you want to delete the selected family from the list?
                This action cannot be undone.
            </span>
        );
    };

    const onDelete = () => {
        if (idToDelete?.id) {
            dispatch(
                deleteTenantFamilyAction(idToDelete?.id, setLoading, setDeleteModal, dispatch, (res) => {
                    handleDeleteNew(allValues, setAllValues, idToDelete?.index);
                    setIdToDelete({ id: "", index: "" });
                })
            );
        } else {
            handleDeleteNew(allValues, setAllValues, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
        }
    };

    const getFamilyDetails = (id, index) => {
        dispatch(
            getTenantFamilyDetailsAction(id, (res) => {
                    handleCancelNew(res, index, allValues, setAllValues);
            })
        );
    };

    const header = (
        <div className="w-12 flex justify-content-end flex-wrap">
            <CustomButton
                type="button"
                label="Add"
                onClick={() => HandleAddNew(InitialFamilyInputs(tenantId), allValues, setAllValues)}
            />
        </div>
    );

    return (
        <>
            <CustomForm title={"Family"} header={header}>
                <div className="overflow-auto">
                    <table className="financial-table editor-container w-12 mt-3 table-error-align">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Date of Birth (or expected date of birth) </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody id="" className="cell-width">
                            {allValues?.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <CustomInput
                                                    data={item}
                                                    col={12}
                                                    onChange={(e) =>
                                                        handleChangeNew(
                                                            e?.target?.name,
                                                            e?.target?.value,
                                                            index,
                                                            allValues,
                                                            setAllValues,
                                                            ignoreInputs,
                                                            optional,
                                                        )
                                                    }
                                                    name="first_name"
                                                    disabled={item?.status === "save" ? true : false}
                                                    ignoreLabel
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    data={item}
                                                    col={12}
                                                    onChange={(e) => {
                                                        handleChangeNew(
                                                            e?.target?.name,
                                                            e?.target?.value,
                                                            index,
                                                            allValues,
                                                            setAllValues,
                                                            ignoreInputs,
                                                            optional,
                                                        );
                                                    }}
                                                    name="last_name"
                                                    disabled={item?.status === "save" ? true : false}
                                                    ignoreLabel
                                                />
                                            </td>
                                            <td>
                                                <CustomCalander
                                                    maxDate={new Date()}
                                                    data={item}
                                                    col={12}
                                                    onChange={(e) =>
                                                        handleChangeNew(
                                                            e?.target?.name,
                                                            e?.target?.value,
                                                            index,
                                                            allValues,
                                                            setAllValues,
                                                            ignoreInputs,
                                                            optional,
                                                        )
                                                    }
                                                    name="date_of_birth"
                                                    disabled={item?.status === "save" ? true : false}
                                                    ignoreLabel
                                                />
                                            </td>
                                            {item?.status === "save" ? (
                                                <td style={{ "minWidth": "20px" }}>
                                                    <CustomOverlay ref={openAction}>
                                                        <ul className="no-style p-0">
                                                            {/* {type === "Edit" && ( */}
                                                            <li
                                                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                                                onClick={() =>
                                                                    handlEdit(allValues, setAllValues, index)
                                                                }
                                                            >
                                                                <i className="pi pi-file-edit text-dark"></i>
                                                                Edit
                                                            </li>
                                                            {/* )} */}
                                                            <li
                                                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                                                onClick={() => {
                                                                    setIdToDelete({ id: item?.id, index: index });
                                                                    setDeleteModal(true);
                                                                }}
                                                            >
                                                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                                                Delete
                                                            </li>
                                                        </ul>
                                                    </CustomOverlay>
                                                </td>
                                            ) : (
                                                <td style={{ "minWidth": "20px" }}>
                                                    <div className="d-flex gap-3">
                                                        <a
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleSaveNew(
                                                                    index,
                                                                    allValues,
                                                                    setAllValues,
                                                                    ignoreInputs,
                                                                    optional,
                                                                )
                                                            }
                                                        >
                                                            <img src={SaveIcon} />
                                                        </a>
                                                        <a
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                if (item.id) {
                                                                    getFamilyDetails(item.id, index);
                                                                } else {
                                                                    handleDeleteNew(allValues, setAllValues, index);
                                                                }
                                                            }}
                                                        >
                                                            <img src={CrossIcon} />
                                                        </a>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                        <CustomModal
                                            isOpen={deleteModal}
                                            setIsOpen={setDeleteModal}
                                            heading={"Delete"}
                                            body={modalContent}
                                            submitLabel={"Confirm"}
                                            onSubmit={onDelete}
                                            loading={loading}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <br />
            </CustomForm>
        </>
    );
};

export default AddFamily;
