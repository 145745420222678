import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import {
  // CustomAutoComplete,
  CustomForm,
  CustomInput,
  CustomNumberInput2,
  // CustomSearch,
} from "shared/AllInputs";
import GooglePlacesInput from "shared/GooglePlacesInput";
import HandymanContainer from "./HandymanContainer";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import AddService from "shared/Components/AddService";
import AddressComponent from "shared/Components/AddressComponent";

const HandymanForms = ({ type }) => {
  const {
    header,
    data,
    handleChange,
    setSelectedAddress,
    onSubmit,
    loading,
    navigate,
    allServices,
    setAllServices,
    nameOptions,
    nameChange,
    isSearch,
    setData
  } = HandymanContainer();

  const FormHeader = () => {
    return (
      <div className="flex align-items-center col-12 justify-content-between">
        <h1 className="title">{`${type} Handyman`}</h1>
        {header()}
      </div>
    );
  };


  return (
    <Page
      title={`${type} Handyman`}
      description={`Let’s ${type} your Handyman Details`}
      navigateLink={"/real-estate/maintenance/handyman/list"}
    >
      <CustomCard>
        <CustomForm col={12} header={FormHeader()}>
          <CustomInput
            data={data}
            onChange={({ name, value }) => {
              const note = ({ ["name"]: "" })
              setData((prev) => ({ ...prev, note }));
              handleChange({ name: name, value: value })
              nameChange.current(value)
            }}
            name="name"
            required
            isSearch={isSearch}
            tooltipContent={nameOptions?.length > 0 ? nameOptions.map((item, index) => (
              <li key={index}>{item?.name} {`(${item?.address})`}</li> 
            )) : null}
          />
          {/* <CustomAutoComplete
            data={data}
            onChange={handleChange}
            name="name"
            suggestions={nameOptions}
            search={(e) => nameChange.current(e.query)}
            required
            editable
            max={9}
          /> */}
          {/* <GooglePlacesInput
            id="property"
            data={data}
            onChange={handleChange}
            name="address"
            required
            setAddress={setSelectedAddress}
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="street"
            required
          />
          <CustomNumberInput2
            data={data}
            onChange={handleChange}
            name="zipCode"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="city"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="cityDivision"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="country"
            required
          /> */}
          <AddressComponent
            id="handyman"
            data={data}
            handleChange={handleChange}
            setAddress={setSelectedAddress}
            isDisabled={false}
          />
        </CustomForm>
        <AddService
          title="Services"
          allValues={allServices}
          setAllValues={setAllServices}
        />
      </CustomCard>

      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined
          label="Cancel"
          onClick={() => {
            navigate("/real-estate/maintenance/handyman/list");
          }}
        />
      </Buttonlayout>
    </Page>
  );
};

export default HandymanForms;
