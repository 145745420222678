import React, { useState } from "react";
import {
    CustomCalander,
    CustomForm,
    CustomInput,
} from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import { useDispatch } from "react-redux";
import { handleDelete } from "shared/Components/commonFunctions";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import { handleFormatDate } from "utils/commonFunctions";
import { deleteTenantFamilyAction, updateTenantFamilyAction } from "store/actions/TenantsActions";
import DeleteModal from "shared/Modals/DeleteModal";
export default function AddFamilyCustomTable({
    title,
    allValues,
    setAllValues,
    header
}) {
    const dispatch = useDispatch();
    const [editingRows, setEditingRows] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

    const rowEditValidator = async (rowData, options) => {
        const { first_name, last_name, date_of_birth } = rowData;
        const firstNameError = formValidation("first_name", first_name, allValues);
        const lastNameError = formValidation("last_name", last_name, allValues);
        const dateOfBirthError = formValidation("date_of_birth", last_name, allValues);

        if (!firstNameError["first_name"] && !lastNameError["last_name"] && !dateOfBirthError["date_of_birth"]) {
            let payload = {
                first_name: first_name,
                last_name: last_name,
                date_of_birth: handleFormatDate(date_of_birth),
            };
            dispatch(
                updateTenantFamilyAction(rowData?.id, payload, dispatch, (res) => {
                    let values = [...allValues];
                    values[options.rowIndex] = rowData;
                    setAllValues(values);
                    setEditingRows({});
                    return true;
                })
            );
        } else {
            return false;
        }
    };

    const handleEditRow = (rowData) => {
        const val = { ...{ [`${rowData?.id}`]: true } };
        setEditingRows(val);
    };

    const FirstNameEditor = (options) => {
        const formErrors = formValidation("first_name", options.value, allValues);
        return (
            <CustomInput
                value={options.value}
                col={12}
                onChange={(e) => {
                    options.editorCallback(e.target.value);
                }}
                name="first_name"
                ignoreLabel
                errorMessage={formErrors["first_name"]}
                max={9}
            />
        );
    };

    const LastNameEditor = (options) => {
        const formErrors = formValidation("last_name", options.value, allValues);
        return (
            <CustomInput
                value={options.value}
                col={12}
                onChange={(e) => {
                    options.editorCallback(e.target.value);
                }}
                name="last_name"
                ignoreLabel
                errorMessage={formErrors["last_name"]}
                max={9}
            />
        );
    };

    const DateEditor = (options) => {
        const formErrors = formValidation(
            "date_of_birth",
            options.value,
            allValues,
            [""],
            ["date_of_birth"]
        );
        return (
            <CustomCalander
                value={options.value}
                col={12}
                onChange={(e) => {
                    options.editorCallback(e.target.value);
                }}
                name="date_of_birth"
                ignoreLabel
                errorMessage={formErrors["date_of_birth"]}
            />
        );
    };

    const handleSave = async (rowData, options, e) => {
        options?.rowEditor?.onSaveClick(e);
        const val = { ...{ [`${rowData?.id}`]: true } };
        setEditingRows(val);
    };

    const actions = (rowData, options) => {
        return (
            <>
                {options.rowEditor?.editing ? (
                    <>
                        <a
                            className="cursor-pointer"
                            onClick={(e) => handleSave(rowData, options, e)}
                        >
                            <img src={SaveIcon} />
                        </a>
                        <a
                            className="cursor-pointer"
                            onClick={(e) =>
                                options.rowEditor?.onCancelClick &&
                                options.rowEditor?.onCancelClick(e)
                            }
                        >
                            {" "}
                            <img src={CrossIcon} />
                        </a>
                    </>
                ) : (
                    <>
                        <CustomOverlay>
                            <ul className="no-style p-0">
                                <li
                                    className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                    onClick={() => handleEditRow(rowData)}
                                >
                                    <i className="pi pi-file-edit text-dark"></i>
                                    Edit
                                </li>
                                <li
                                    className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                    onClick={() => {
                                        setIdToDelete({
                                            id: rowData?.id,
                                            index: options?.rowIndex,
                                        });
                                        setDeleteModal(true);
                                    }}
                                >
                                    <i className="pi pi-trash  text-dark"></i> Delete
                                </li>
                            </ul>
                        </CustomOverlay>
                    </>
                )}
            </>
        );
    };

    const onRowEditChange = (e) => {
        setEditingRows(e.data);
    };

    const onDelete = () => {
        setLoading(true);
        dispatch(
            deleteTenantFamilyAction(idToDelete?.id, setLoading, setDeleteModal, dispatch, (res) => {
                handleDelete(allValues, setAllValues, "", "", idToDelete?.index);
                setIdToDelete({ id: "", index: "" });
            })
        );
    };

    return (
        <CustomForm title={title || "Family"} header={header()}>
            <DataTable
                className="col-12 properties-table-list table-error-align"
                value={allValues}
                editMode="row"
                dataKey="id"
                editingRows={editingRows}
                onRowEditChange={onRowEditChange}
                rowEditValidator={rowEditValidator}
                onRowEditSave={handleSave}
                tableStyle={{ minWidth: "50rem" }}
            >
                <Column
                    field="first_name"
                    header="First Name"
                    editor={(options) => FirstNameEditor(options)}
                    headerClassName="custom-header"
                    style={{ width: "30%" }}
                ></Column>
                <Column
                    field="last_name"
                    header="Last Name"
                    headerClassName="custom-header"
                    editor={(options) => LastNameEditor(options)}
                    style={{ width: "30%" }}
                ></Column>
                <Column
                    field="date_of_birth"
                    header="Date of Birth (or expected date of birth)"
                    headerClassName="custom-header"
                    body={(options) => handleFormatDate(options?.date_of_birth)}
                    editor={(options) => DateEditor(options)}
                    style={{ width: "30%" }}
                ></Column>
                <Column
                    header="Action"
                    rowEditor
                    headerClassName="custom-header"
                    body={(rowData, options) => actions(rowData, options)}
                ></Column>
            </DataTable>
            <DeleteModal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                onSubmit={onDelete}
                loading={loading}
                item={"family"}
            />
        </CustomForm>
    );
}
