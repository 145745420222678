import React, { useEffect, useState } from "react";
import {
  CustomDropDown,
  CustomFileInput,
  CustomForm,
  CustomTextArea,
} from "shared/AllInputs";
import { damageCauseopt } from "shared/AllDropDownOptions";
import { getAllHandymanServiceListAction } from "store/actions/handymanServiceAction";
import { useDispatch, useSelector } from "react-redux";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import formValidation from "utils/validations";
import {
  getFileExtension,
  // getFileName,
  showFormErrors,
} from "utils/commonFunctions";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  addDamageAction,
  getDamageDetailAction,
  updateDamageAction,
} from "store/actions/damageActions";
import { showToastAction } from "store/slices/commonSlice";
import { truncateText } from "utils/regex";
// import { setIsDamageUpdateSlice } from "store/slices/damageSlice";

const AddDamageForm = ({
  allDamages,
  setAllDamages,
  setIsAddDamage,
  reportId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { profile } = useSelector((state) => state?.user);
  const [serviceList, setServicesList] = useState([]);
  const [damageDocuments, setDamageDocuments] = useState([]);
  const [fileKey, setFileKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const dataInitialState = {
    index: "",
    id: "",
    damageCause: "",
    damageType: "",
    damageTypeName: "",
    damageDescription: "",
    document: [],
    damage_report: reportId || "",
  };

  const [data, setData] = useState(dataInitialState);

  useEffect(() => {
    if (profile?.company) {
      getServiceList();
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.company && params?.id && !reportId) {
      getDamageDetails(profile?.company, params?.id);
    }
  }, [profile, params, reportId]);

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data, [
      "index",
      "id",
      "damage_report",
      "damageTypeName",
    ]);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));

    if (name === "damageType") {
      const serviceName =
        serviceList.find((item) => item?.value === value)?.name ?? "";
      setData((prev) => ({
        ...prev,
        ["damageTypeName"]: serviceName,
        formErrors,
      }));
    }
  };

  const handleFileChange = async (event) => {
    // const file = event.target.files[0];
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);

      const myFiles = filesArray?.map((file) => {
        return { document: file };
      });

      // const filesArray = Array.from(files);
      const newDamageDocuments = [...damageDocuments, ...filesArray];
      if (newDamageDocuments.length > 7) {
        dispatch(
          showToastAction({
            type: "error",
            detail: "You can only upload up to 7 files!",
          })
        );
      } else {
        setDamageDocuments(newDamageDocuments);
        let douments = [...data.document, ...myFiles];
        const formErrors = formValidation("document", douments, data);
        setData((prev) => ({ ...prev, ["document"]: douments, formErrors }));
      }

      // setDamageDocuments([...damageDocuments, files]);
      // // const convertedFile = await handleFileConversion(file);
      // douments.push({ document: files });
    }
  };

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   setDamageDocuments([...damageDocuments, file]);

  //   // const convertedFile = await handleFileConversion(file);
  //   let douments = [...data.document];
  //   const formErrors = formValidation("document", file, data);
  //   douments.push({ document: file });
  //   setData((prev) => ({ ...prev, ["document"]: douments, formErrors }));
  // };

  const handleFileDelete = (index) => {
    let arr = [...damageDocuments];
    arr?.splice(index, 1);
    setDamageDocuments(arr);

    let douments = [...data.document];
    douments?.splice(index, 1);
    setData((prev) => ({ ...prev, ["document"]: douments }));

    if (damageDocuments.length == 1) {
      setDamageDocuments([]);
      setFileKey((prevKey) => prevKey + 1);
    }
  };

  const onSubmit = () => {
    if (
      showFormErrors(data, setData, [
        "index",
        "id",
        "damage_report",
        "damageTypeName",
      ])
    ) {
      if (reportId) {
        dispatch(
          addDamageAction(
            profile?.company,
            data,
            setLoading,
            dispatch,
            (res) => {
              setDamageDocuments([]);
              setData(dataInitialState);
              setIsAddDamage ? setIsAddDamage(false) : navigate(-1);
              const damages = [...allDamages];
              damages.push({
                damageCause: data.damageCause,
                damageDescription: data.damageDescription,
                damageType: data.damageType,
                document: data.document,
                damageTypeName: data?.damageTypeName,
                is_requested: res?.is_requested,
                id: res?.id,
                processedDate: res?.updated_at,
              });
              setAllDamages(damages);
            }
          )
        );
      } else if (params?.id) {
        dispatch(
          updateDamageAction(
            profile?.company,
            params?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              navigate(-1);
              // dispatch(setIsDamageUpdateSlice(true));
              setDamageDocuments([]);
              setData(dataInitialState);
            }
          )
        );
      } else {
        const damages = [...allDamages];
        if (data?.index === 0 || data?.index > 0) {
          damages[data.index] = { ...data };
        } else {
          damages.push({
            damageCause: data.damageCause,
            damageDescription: data.damageDescription,
            damageType: data.damageType,
            document: data.document,
            damageTypeName: data?.damageTypeName,
          });
        }

        // Update state
        setAllDamages(damages);
        setIsAddDamage ? setIsAddDamage(false) : navigate(-1);
        setDamageDocuments([]);
        setData(dataInitialState);
      }
    }
  };

  const onDamageCancel = () => {
    setDamageDocuments([]);
    setData(dataInitialState);
    setIsAddDamage ? setIsAddDamage(false) : navigate(-1);
  };

  const getDamageDetails = (companyId, damageId) => {
    dispatch(
      getDamageDetailAction(companyId, damageId, (res) => {
        let documents = res?.images?.map((file) => {
          return { document: file?.image, id: file?.id };
        });

        setData({
          index: "",
          id: res?.id,
          damageCause: res?.damage_cause,
          damageType: res?.damage_type?.id,
          damageDescription: res?.description,
          document: documents,
        });

        setDamageDocuments(res?.images);
      })
    );
  };

  return (
    <Page
      title={`Report Damage`}
      description={`Let’s ${params?.id ? "Edit" : "Add"} your Damage Details`}
    >
      <CustomCard>
        <CustomForm title={`${params?.id ? "Edit" : "Add"} Damage`}>
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="damageCause"
            options={damageCauseopt}
            label="Damage Cause"
            placeholder="Select Cause"
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="damageType"
            options={serviceList}
            required
          />
          <CustomTextArea
            col={12}
            data={data}
            onChange={handleChange}
            name="damageDescription"
            max={9}
            required
          />
        </CustomForm>
        <CustomForm>
          <CustomFileInput
            data={data}
            selectedFiles={data?.document}
            inputKey={fileKey}
            type="file"
            name="document"
            label="Add Damage Photo "
            onChange={handleFileChange}
            required
            disabled={data?.document?.length >= 7}
            isMulitple
          />
          {damageDocuments.length > 0 && (
            <div>
              <h5>Selected files:</h5>
              {damageDocuments.map((file, index) =>
                file?.name || file?.image ? (
                  <li className="upload-list" key={index}>
                    {file?.name
                      ? `${truncateText(file?.name, 20)}.${getFileExtension(
                          file?.name
                        )}`
                      : `${truncateText(file?.image, 20)}.${getFileExtension(
                          file?.image
                        )}`}{" "}
                    <i
                      onClick={() => handleFileDelete(index)}
                      className="pi pi-times"
                      style={{
                        color: "red",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    ></i>
                  </li>
                ) : null
              )}
            </div>
          )}
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined label="Cancel" onClick={onDamageCancel} />
      </Buttonlayout>
    </Page>
  );
};

export default AddDamageForm;
