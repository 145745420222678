import React, { useEffect, useMemo, useState } from "react";
import { CustomForm } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import { canAccess } from "services/auth";
import EditIcon from "assets/images/icons/editicon.png";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { getTenantSocialHistoryAction } from "store/actions/TenantsActions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleFormatDate, reformatDateString } from "utils/commonFunctions";

const SocialOfficeView = ({ data, onEdit }) => {
    const params = useParams()
    const dispatch = useDispatch()
    const [historyList, setHistoryList] = useState([])
    const [count, setCount] = useState(0)

    const header = () => {
        return (
            <div className="flex justify-content-around cursor-pointer">
                <img
                    src={EditIcon}
                    alt=""
                    onClick={() =>
                        onEdit(`socialOffice`)
                    }
                ></img>
            </div>
        );
    };

    const columns = useMemo(() => [
        {
            name: "Date",
            accessor: "created_at",
            value: true,
            body: (options) => { return <span>{handleFormatDate(reformatDateString(options?.created_at))}</span> }
        },
        {
            name: "Social Office",
            accessor: "social_office.name",
            value: true,
        },
        {
            name: "Service",
            accessor: "service.name",
            value: true,
        },
    ])

    useEffect(() => {
        if (params?.id) {
            getSocialHistory(params?.id)
        }
    }, [params?.id])

    const getSocialHistory = (id) => {
        dispatch(
            getTenantSocialHistoryAction(id, (res) => {
                setHistoryList(res?.options);
                setCount(res?.count);
            })
        );
    }

    return (
        <>
            <CustomForm title=" " header={canAccess("change_building") && header()}>
                <CustomViewInput name="socialOffice" value={data?.socialOffice?.name} />
                <CustomViewInput name="service" value={data?.service?.name} />
            </CustomForm>
            <CustomForm title="Office & Service History">
                <CustomTableContainer
                    list={historyList}
                    columns={columns}
                    notShowSelection
                />
            </CustomForm>
        </>
    )
}

export default SocialOfficeView