import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useMemo, useState } from "react";
import CustomOverlay from "shared/CustomOverlay";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { TabPanel, TabView } from "primereact/tabview";
import CommonViewTable from "views/Tables/CommonViewTable";
import { useDispatch, useSelector } from "react-redux";
import { setPropertySlice } from "store/slices/propertySlice";
// import { truncateText } from "utils/regex";
// import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { canAccess } from "services/auth";
import ViewRoomIcon from "assets/images/svg/ViewRoomsIcon.svg"
import TerminateContractIcon from "assets/images/svg/TerminateContractIcon.svg"
import ArchiveIcon from "assets/images/icons/archive.svg"
const PropertyTableContainer = ({
  data,
  columns,
  setDeleteModal,
  sortField,
  setSortField,
  // setPage,
  first,
  setFirst,
  setIdToDelete,
  rows,
  // setRows,
  count,
  openAction,
  filters,
  setFilters,
  onPageChange,
  selectedProperties,
  setSelectedProperties,
}) => {
  const navigate = useNavigate();
  const [rowClick, setRowClick] = useState(true);

  const [openRightbar, setOpenRightbar] = useState({
    isOpen: false,
    action: "",
    id: "",
  });
  const [activeIndex, setActiveIndex] = useState();
  const [timer, setTimer] = useState(5);
  const property = useSelector((state) => state.property.property);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (timer > 0) {
        let newTime = timer - 1;
        setTimer(newTime);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [timer]);

  useEffect(() => {
    if (timer === 0) {
      dispatch(setPropertySlice(""));
    }
  }, [timer]);

  useEffect(() => {
    if (openRightbar?.isOpen) {
      if (openRightbar?.action === "energyMeter") {
        setActiveIndex(1);
      } else {
        setActiveIndex(0);
      }
    }
  }, [openRightbar]);

  const handleSort = (e) => {
    if (sortField?.order === "-") {
      setSortField({ field: e?.sortField, order: "" });
    } else {
      setSortField({ field: e?.sortField, order: "-" });
    }
  };

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  const rowClass = (data) => {
    return {
      // "bg-primary-color": data?.id == property?.id,
      "bg-grey-color": data?.id == property?.id,
    };
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/property/view/building-structure/${d.id}`);
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              const rooms = d?.allRooms?.map((room, index) => {
                return {
                  ...room,
                  srNo: index + 1,
                };
              });
              setOpenRightbar({
                isOpen: true,
                action: "energyMeter",
                id: d.id,
                energyMeters: d?.allEenergyMeters || [],
                rooms: rooms || [],
              });
            }}
          >
            <i className="pi pi-bolt text-dark"></i>
            View Energy Meter
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              const rooms = d?.allRooms?.map((room, index) => {
                return {
                  ...room,
                  srNo: index + 1,
                };
              });
              setOpenRightbar({
                isOpen: true,
                action: "rooms",
                id: d.id,
                energyMeters: d?.allEenergyMeters || [],
                rooms: rooms || [],
              });
            }}
          >
            <img src={ViewRoomIcon} alt="" />
            View Rooms
          </li>
          {/* {canAccess("delete_property") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            >
              <i className="pi pi-trash text-dark"></i> Delete
            </li>
          )} */}
          {canAccess("delete_property") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete({id: [d?.id], action: d?.base_status === "archived"? "restore" : "archived"});
                setDeleteModal(true);
              }}
            >
              <img src={ArchiveIcon} />
              {d?.base_status === "archived"? "Unarchive" : "Archive"}
            </li>
          )}
          <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                navigate(`/real-estate/terminate-contract/${d.id}`);
              }}
            >
              <img src={TerminateContractIcon} alt="" /> 
              Terminate Contract
            </li>
        </ul>
      </CustomOverlay>
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <i className="pi pi-filter cursor-pointer"></i> */}
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
        </p>
      </>
    );
  };

  const roomColumns = useMemo(
    () => [
      {
        name: "Room ID",
        accessor: "srNo",
      },
      {
        name: "Doors",
        accessor: "doors",
      },
      {
        name: "Size",
        accessor: "size",
        body: (rowData) => (
          <span className="lowercase-text">
            {rowData.size} m<sup>2</sup>
          </span>
        ),
      },
      {
        name: "Name",
        accessor: "name",
      },
    ],
    []
  );

  const meterColumns = useMemo(
    () => [
      {
        name: "Type",
        accessor: "type",
      },
      {
        name: "Purpose",
        accessor: "purpose",
      },
      {
        name: "Meter ID",
        accessor: "meter_id",
      },
      {
        name: "Location",
        accessor: "location",
      },
      {
        name: "Access",
        accessor: "access",
      },
      {
        name: "Amount",
        accessor: "amount",
        body: (rowData) => {
          return rowData?.type == "Gas" ? (
            <span className="lowercase-text">
              {rowData.amount} m<sup>3</sup>
            </span>
          ) : (
            <span className="lowercase-text">{rowData.amount} kWh</span>
          );
        },
      },
    ],
    []
  );

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        // icon="pi pi-times"
        onClick={() => options.filterClearCallback("")}
        className="clear-button-outlined"
      >
        Clear
      </Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        // icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="bg-primary-color"
      >
        Apply
      </Button>
    );
  };

  const customSortIcon = (options) => {
    // if(sortField?.order == "-"){
    //   return <i className="pi pi-arrow-up"></i>
    // }else {
    //   return <i className="pi pi-arrow-down"></i>
    // }
    //   // let icon = options.sorted ? (
    //   //   options.sortOrder < 0 ? (
    //   //     <ChevronDownIcon {...options.iconProps} />
    //   //   ) : (
    //   //     <ChevronUpIcon {...options.iconProps} />
    //   //   )
    //   // ) : (
    //   //   <SortAltIcon {...options.iconProps} />
    //   // );
    //   // return icon;
  };

  return (
    <>
      {/* <Tooltip target="tooltip" /> */}
      <DataTable
        className="mt-4 table-list capitalize"
        value={data}
        tableStyle={{ minWidth: "50rem" }}
        // selectionMode={rowClick ? null : "checkbox"}
        selectionMode={"checkbox"}
        selection={selectedProperties}
        onSelectionChange={(e) => setSelectedProperties(e.value)}
        dataKey="id"
        onSort={handleSort}
        rowClassName={rowClass}
        scrollable
        filters={filters}
        globalFilterFields={[
          "property_id",
          "address",
          "building__type",
          "building__staircase",
          "status",
          "location",
          "heatingDevice",
          "unityType",
          "outStatus",
        ]}
        // emptyMessage="No properties found."
        onFilter={(e) => setFilters(e.filters)}
        // sortIcon={sortField?.order === "-"? <i className="pi pi-arrow-up"></i> : <i className="pi pi-arrow-down"></i>}
        sortIcon={customSortIcon}
      >
        <Column
          headerClassName="custom-header"
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
          style={{ minWidth: "80px" }}
          frozen={true}
        ></Column>
        {columns
          ?.sort((a, b) => {
            if (a.value !== b.value) {
              return b.value - a.value;
            }
          })
          ?.map((col, index) => {
            if (col.value) {
              // if (col.name === "Address") {
              //   return (
              //     <Column
              //       headerClassName="custom-header"
              //       field={col.accessor}
              //       header={col.name}
              //       body={(rowData) => (
              //         <div>
              //           <Tooltip
              //             className="tooltip"
              //             target={`.tooltip-${rowData.id}`}
              //             content={rowData.address}
              //           />
              //           <span
              //             className={`tooltip-${rowData.id}`}
              //             data-pr-classname="tooltip"
              //             style={{ cursor: "pointer" }}
              //           >
              //             <span
              //               // style={{
              //               //   color: rowData.id === property?.id ? "white" : null,
              //               // }}
              //             >
              //               {truncateText(rowData.address, 20)}
              //             </span>
              //           </span>
              //         </div>
              //       )}
              //       // sortable
              //       sortable={col.sortable}
              //       frozen={col.frozen}
              //       style={{ minWidth: "80px" }}
              //       filter={col.filter}
              //       filterElement={col.filterElement} // Correctly use the custom filter template
              //       filterClear={filterClearTemplate}
              //       filterApply={filterApplyTemplate}
              //       showFilterMatchModes={false}
              //     ></Column>
              //   );
              // } else {
              return (
                <Column
                  key={index}
                  headerClassName="custom-header"
                  filterField={col.accessor}
                  field={col.accessor}
                  header={col.name}
                  frozen={col.frozen}
                  sortable={col.sortable}
                  // showFilterMenuOptions={false}
                  showFilterMatchModes={false}
                  filter={col.filter}
                  filterElement={col.filterElement} // Correctly use the custom filter template
                  filterMenuStyle={{ width: "14rem" }}
                  style={{ minWidth: "180px" }}
                  filterClear={filterClearTemplate}
                  filterApply={filterApplyTemplate}
                  body={col?.body}

                  // filterApply
                  // filterClear
                ></Column>
              );
              // }
            }
          })}
        <Column
          headerClassName="custom-header"
          body={actions}
          alignFrozen="right"
          frozen={true}
          style={{ minWidth: "80px" }}
          header={ActionTemplate}
        ></Column>
      </DataTable>
      <div className="flex align-items-center">
        <span className="text-gray-600">
          {selectedProperties?.length} out of {count}
        </span>
        <Paginator
          className="mx-auto justify-content-center"
          first={first}
          rows={Number(rows)}
          totalRecords={count}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={onPageChange}
          // lazy
        ></Paginator>
      </div>
      <Sidebar
        className="view-enery-meter-sidebar"
        visible={openRightbar.isOpen}
        position="right"
        onHide={() => setOpenRightbar({ isOpen: false, action: "", id: "" })}
      >
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => {
            handleTabChange(e?.index);
          }}
        >
          <TabPanel header="Rooms">
            <CommonViewTable list={openRightbar?.rooms} columns={roomColumns} />
          </TabPanel>
          <TabPanel header="Energy Meter">
            <CommonViewTable
              list={openRightbar?.energyMeters}
              columns={meterColumns}
            />
          </TabPanel>
        </TabView>
      </Sidebar>
    </>
  );
};

export default PropertyTableContainer;
