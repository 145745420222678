import React from "react";
import { CustomForm } from "shared/AllInputs";
import { CustomDateViewInput, CustomViewInput } from "shared/AllViewInputs";
import { canAccess } from "services/auth";
import EditIcon from "assets/images/icons/editicon.png";

const TenantView = ({ data, onEdit }) => {
    const header = () => {
        return (
            <div className="flex justify-content-around cursor-pointer">
                <img
                    src={EditIcon}
                    alt=""
                    onClick={() =>
                        onEdit("tenant")
                    }
                ></img>
            </div>
        );
    };

    return (
        <CustomForm title="General Info" header={canAccess("change_building") && header()}>
            <CustomViewInput name="tenantId" data={data} label={"Tenant ID"}/>
            <CustomViewInput name="firstName" data={data} />
            <CustomViewInput name="lastName" data={data} />
            <CustomViewInput name="tenantType" data={data} />
            <CustomDateViewInput name="dateOfBirth" data={data} />
            <CustomViewInput name="email" data={data} />
            <CustomViewInput name="phoneNumber" data={data} />
            <CustomViewInput name="addressBeforeMovingIn" data={data} />
        </CustomForm>
    )
}

export default TenantView