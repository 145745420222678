import React from "react";
import CustomCard from "shared/CustomCard";
import Page from "shared/Page";
import ViewTenantsContainer from "./ViewTenantsContainer";
import { TabPanel, TabView } from "primereact/tabview";
import AddFamilyCustomTable from "views/Tables/AddFamilyCustomTable";
import TenantView from "../TenantsForm/TenantView";
import SocialOfficeView from "../TenantsForm/SocialOfficeView";
import Tenant from "../TenantsForm/Tenant";
import SocialOffice from "../TenantsForm/SocialOffice";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "shared/CustomButton";
import EditIcon from "assets/images/icons/editicon.png";
import AddFamily from "views/CommonComponents/AddFamily";

const ViewTenantForm = () => {
    const {
        data,
        activeIndex,
        handleTabChange,
        onEdit,
        isEdit,
        handleChange,
        onSubmit,
        loading,
        allFamilies,
        setAllFamilies,
        socialOfficeData,
        setSocialOfficeData,
        handleSocialOfficeChange,
        tenantId
    } = ViewTenantsContainer();

    const header = () => {
        return (
            <div className="flex justify-content-around cursor-pointer">
                <img
                    src={EditIcon}
                    alt=""
                    onClick={() =>
                        onEdit("family")
                    }
                ></img>
            </div>
        );
    };

    return (
        <Page
            title={`View Tenant`}
            description={`Let’s View your tenant Details`}
        >
            <CustomCard>
                {(isEdit === "tenant") ? <Tenant data={data} handleChange={handleChange} activeIndex={activeIndex} /> : <TenantView data={data} onEdit={onEdit} />}
                <TabView
                    className="tabs-design"
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                        handleTabChange(e?.index);
                        onEdit("")
                    }}
                >
                    <TabPanel header="Social Office">
                        {isEdit === "socialOffice" ? <SocialOffice data={socialOfficeData} setData={setSocialOfficeData} handleChange={handleSocialOfficeChange} /> : <SocialOfficeView data={socialOfficeData} onEdit={onEdit} />}
                    </TabPanel>
                    <TabPanel header="Family">
                        {isEdit === "family"? <AddFamily allValues={allFamilies} setAllValues={setAllFamilies} tenantId={tenantId}/> : <AddFamilyCustomTable allValues={allFamilies} setAllValues={setAllFamilies} header={header}/>}
                    </TabPanel>
                    <TabPanel header="Contracts">
                        <>work in progress</>
                    </TabPanel>
                </TabView>
            </CustomCard>
            {isEdit && <Buttonlayout>
                <CustomButton onClick={onSubmit} label="Save Changes" loading={loading} />
                <CustomButtonOutlined label="Cancel" onClick={() => onEdit("")} />
            </Buttonlayout>}
        </Page>
    )
}

export default ViewTenantForm