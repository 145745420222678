import React, { useEffect, useRef, useState } from "react";
import { CustomAutoComplete, CustomDropDown, CustomForm, CustomInput } from "../AllInputs";
import CustomButton from "../CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import { BankPurposeOpt, YesNoOptions } from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import { useDispatch } from "react-redux";
import CustomModal from "../Modals/CustomModal";
import {
  deleteBankAccountAction,
  getBankAccountInfoAction,
  getBankAccountListAction,
  unlinkBankAccountAction,
} from "../../store/actions/landlordActions";
// import { checkIban } from "../../services/api";
// import { debounce } from "lodash";
// import { KEYS } from "../../utils/keys";
import CrossIcon from "assets/images/svg/cross-icon.svg"
import SaveIcon from "assets/images/svg/Save-icon.svg"
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

const AddBankAccounts = ({
  // type,
  mode,
  title,
  allBankAccounts,
  setAllBankAccounts,
  landlordId,
  contractId,
  // errors,
  // setErrors,
}) => {
  const location = useLocation()
  const pathSegments = location?.pathname.split('/');
  const action = pathSegments[2]
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  // const [isSearch, setIsSearch] = useState(false);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({ index: "", account: "" });
  const [purposeOptions, setPurposeOptions] = useState(BankPurposeOpt)

  const InputObj = {
    id: "",
    purpose: "",
    landlord: landlordId,
    direct_debit: "",
    owner_name: "",
    iban: "",
    bic: "",
    status: "",
  };

  const ignoreInputs = ["landlord", "status", "id"];
  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() =>
          HandleAddNew(InputObj, allBankAccounts, setAllBankAccounts)
        }
      />
    </div>
  );

  useEffect(() => {
    if (landlordId) {
      getBankAccountsList(landlordId)
    }
  }, [landlordId])

  // const handleIban = async (name, value, index, allAccounts) => {
  //   if (value) {
  //     const updatedArray = [...allAccounts];
  //     const payload = {
  //       api_key: KEYS.iBan,
  //       iban: value,
  //     };

  //     const res = await checkIban(payload);
  //     let error = "";

  //     if (res?.data?.is_valid) {
  //       error = "";
  //     } else if (!res?.data?.is_valid) {
  //       error = "Please enter a valid Iban!";
  //     }

  //     if (index > -1 && index < updatedArray.length) {
  //       const updatedAccount = {
  //         ...updatedArray[index],
  //         [name]: value,
  //         formErrors: { ...updatedArray[index].formErrors, [name]: error },
  //       };
  //       updatedArray[index] = updatedAccount;
  //     }
  //     setAllBankAccounts(updatedArray);
  //     setIsSearch(false);
  //   }
  // };

  // const ibanChange = useRef(debounce(handleIban, 1000));

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected bank acount from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteBankAcc = () => {
    if (idToDelete?.id) {
      setLoading(true);
      if (action === "property") {
        dispatch(
          unlinkBankAccountAction(contractId, idToDelete?.id, setLoading, dispatch, (res) => {
            handleDeleteNew(
              allBankAccounts,
              setAllBankAccounts,
              idToDelete?.index
            );
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          })
        );
      } else {
        dispatch(
          deleteBankAccountAction(idToDelete?.id, setLoading, dispatch, (res) => {
            handleDeleteNew(
              allBankAccounts,
              setAllBankAccounts,
              idToDelete?.index
            );
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          })
        );
      }

    } else {
      handleDeleteNew(allBankAccounts, setAllBankAccounts, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getAccountInfo = (id, index) => {
    dispatch(
      getBankAccountInfoAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allBankAccounts, setAllBankAccounts);
        }
      })
    );
  };

  const getBankAccountsList = (landlordId, iban) => {
    dispatch(
      getBankAccountListAction(landlordId, iban, (res) => {
        setBankAccountList(res)
      })
    );
  }

  const ibanChange = useRef(debounce((value) => {
    getBankAccountsList(landlordId, value)
  }, 1000));

  useEffect(() => {
    if ((selectedBankAccount?.index != -1 || selectedBankAccount?.index != "") && selectedBankAccount?.account) {
      const account = selectedBankAccount?.account
      const accounts = [...allBankAccounts]
      accounts[selectedBankAccount?.index] = {
        bic: account?.bic || "",
        direct_debit: account?.direct_debit || "",
        iban: account?.iban,
        id: account?.id || "",
        owner_name: account?.owner_name || "",
        purpose: account?.purpose ? account?.purpose : accounts[selectedBankAccount?.index]?.purpose,
        landlord: landlordId
      }
      setAllBankAccounts(accounts)
      setSelectedBankAccount({ index: "", account: "" })
    }
  }, [selectedBankAccount?.index])

  useEffect(() => {
    setBankOptions(bankAccountList)
  }, [])

  useEffect(() => {
    const options = bankAccountList?.map((option) => {
      const isDisabled = allBankAccounts?.some(account => account?.id === option.id);
      return {
        ...option,
        disabled: isDisabled,
      };
    });
    setBankOptions(options);

    const purposeOpt = BankPurposeOpt?.map((option) => {
      const isDisabled = allBankAccounts?.some(account => account?.purpose === option?.value);
      return {
        ...option,
        disabled: isDisabled,
      };
    });
    setPurposeOptions(purposeOpt)

  }, [allBankAccounts, bankAccountList]);

  return (
    <CustomForm title={title} header={header}>
      <div className="overflow-auto">
        <table className="financial-table editor-container w-full mt-3 table-error-align ">
          <thead>
            <tr>
              <th>Purpose</th>
              <th>IBAN</th>
              <th>Direct Debit</th>
              <th>Owner Name</th>
              <th>BIC</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allBankAccounts?.map((bankAcc, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ width: "250px" }}>
                      <CustomDropDown
                        data={bankAcc}
                        onChange={(e) => {
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allBankAccounts,
                            setAllBankAccounts,
                            ignoreInputs
                          );
                        }}
                        name="purpose"
                        col={12}
                        extraClassName="w-full"
                        options={purposeOptions}
                        disabled={bankAcc?.status === "save"}
                        ignoreLabel
                      />
                    </td>

                    <td style={{ width: "260px" }}>
                      {/* <CustomInput
                      ignoreLabel
                      disabled={bankAcc?.status === "save"}
                      type="text"
                      name="iban"
                      placeholder="Enter IBAN"
                      extraClassName="w-full"
                      data={bankAcc}
                      onChange={(e) => {
                        {
                          const value = e?.target?.value;
                          handleChangeNew(
                            e?.target?.name,
                            value,
                            index,
                            allBankAccounts,
                            setAllBankAccounts,
                            ignoreInputs
                          );
                        }
                      }}
                      isSearch={isSearch}
                    /> */}
                      <CustomAutoComplete
                        field={"name"}
                        data={bankAcc}
                        onChange={(e) => {
                          {
                            const value = e?.target?.value;
                            value?.iban && setSelectedBankAccount({ index: index, account: value })
                            handleChangeNew(
                              e?.target?.name,
                              value?.iban || value,
                              index,
                              allBankAccounts,
                              setAllBankAccounts,
                              ignoreInputs
                            );
                          }
                        }}
                        name="iban"
                        suggestions={bankOptions}
                        editable
                        panelClassName="dropdown-option-color"
                        placeholder="Enter IBAN"
                        ignoreLabel
                        extraClassName="w-full p-0"
                        disabled={bankAcc?.status === "save" || (mode === "edit" && bankAcc?.contract ? bankAcc?.contract.includes(contractId) : false)}
                        // col={12}
                        search={(e) => ibanChange.current(e.query)}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <CustomDropDown
                        data={bankAcc}
                        disabled={bankAcc?.status === "save"}
                        extraClassName="w-full p-0"
                        onChange={(e) => {
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allBankAccounts,
                            setAllBankAccounts,
                            ignoreInputs
                          );
                        }}
                        name="direct_debit"
                        options={YesNoOptions}
                        // required
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomInput
                        ignoreLabel
                        data={bankAcc}
                        disabled={bankAcc?.status === "save"}
                        type="text"
                        name="owner_name"
                        extraClassName="w-full p-0"
                        onChange={(e) => {
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allBankAccounts,
                            setAllBankAccounts,
                            ignoreInputs
                          );
                        }}
                      />
                    </td>
                    <td>
                      <CustomInput
                        ignoreLabel
                        disabled={bankAcc?.status === "save"}
                        type="text"
                        name="bic"
                        placeholder="Enter BIC"
                        extraClassName="w-full p-0"
                        data={bankAcc}
                        onChange={(e) => {
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allBankAccounts,
                            setAllBankAccounts,
                            ignoreInputs
                          );
                        }}
                      />
                    </td>
                    {bankAcc?.status === "save" ? (
                      <td style={{ "minWidth": "20px" }}>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(
                                  allBankAccounts,
                                  setAllBankAccounts,
                                  index
                                )
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            {/* )} */}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({ id: bankAcc?.id, index: index });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td style={{ "minWidth": "20px" }}>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={() =>
                              handleSaveNew(index, allBankAccounts, setAllBankAccounts, ignoreInputs)
                            }
                          >
                            <img src={SaveIcon} />
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (bankAcc.id) {
                                getAccountInfo(bankAcc.id, index);
                              } else {
                                handleDeleteNew(
                                  allBankAccounts,
                                  setAllBankAccounts,
                                  index
                                );
                              }
                            }}
                          >
                            <img src={CrossIcon} />
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                  {/* {(bankAcc?.formErrors?.owner_name ||
                      bankAcc?.formErrors?.iban ||
                      bankAcc?.formErrors?.bic) && (
                      <tr>
                        <td></td>
                        <td>
                          <small className="p-error">
                            {bankAcc?.formErrors?.owner_name}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {bankAcc?.formErrors?.iban}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {bankAcc?.formErrors?.bic}
                          </small>
                        </td>
                      </tr>
                    )} */}
                  <CustomModal
                    isOpen={deleteModal}
                    setIsOpen={setDeleteModal}
                    heading={"Delete"}
                    body={modalContent}
                    submitLabel={"Confirm"}
                    onSubmit={handleDeleteBankAcc}
                    loading={loading}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
    </CustomForm>
  );
};

export default AddBankAccounts;
