import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { TabPanel, TabView } from "primereact/tabview";
import ViewBuildingStructure from "views/CommonViewComponents/BuildingStructure";
import ViewBuildingUnit from "views/CommonViewComponents/BuildingUnit";
import ViewLandlordInfo from "views/CommonViewComponents/LandlordInfo";
import ViewContractualInfo from "views/CommonViewComponents/ContractualInfo";
import ViewRentAndCost from "views/CommonViewComponents/RentAndCost";
import ViewPropertyComponent from "views/CommonViewComponents/ViewPropertyComponent";
import AddPropertyForm from "views/CommonComponents/AddPropertyForm";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
// import { Messages } from "primereact/messages";
import ViewEditPropertyContainer from "./ViewPropertyContainer";
import BuildingStucture from "views/CommonComponents/BuildingStucture";
import BuildingUnit from "views/CommonComponents/BuildingUnit";
import AddRooms from "shared/Components/AddRooms";
import AddEnergyMeters from "shared/Components/AddEnergyMeters";
import LandlordInfo from "views/CommonComponents/LandlordInfo";
import ContractualInfoForm from "views/CommonComponents/ContractualInfoForm";
import RentAndCost from "views/CommonComponents/RentAndCost";
import CustomModal from "shared/Modals/CustomModal";
// import BankingDetailForm from "views/CommonComponents/BankingDetailForm";
// import FurnitureForm from "views/CommonComponents/FurnitureForm";
import AddContacts from "shared/Components/AddContacts";
// import ViewBankDetails from "views/CommonViewComponents/ViewBankDetails";
// import ViewFurniture from "views/CommonViewComponents/ViewFurniture";
import CustomLandlordContactTable from "../../../Tables/CustomLandlordContactTable";
import PropertyAnnualStatementList from "views/CommonViewComponents/AnnualStatement";
import ViewLandlordFiles from "views/CommonViewComponents/ViewLandlordFiles";
import ContractAndUnitFilesComponent from "views/CommonComponents/ContractAndUnitFilesComponents";
import LandlordFilesComponent from "views/CommonComponents/LandlordFilesComponent";

const ViewPropertyForm = () => {
  const {
    action,
    route,
    activeIndex,
    handleTabChange,
    onSubmit,
    loading,
    handleCancel,
    setTabToActive,

    //property
    PropertyData,
    propertyChange,
    propertyIdOptions,
    setSelectedAddress,
    propertyIdChange,

    //Building Structure
    buildingStuctureData,
    BuildingStuctureHandleChange,
    buildingOptions,
    // fileKey,
    // sturcureDocuments,
    // handleStructureFileChange,
    handleStructureFileDelete,
    isWarning,
    setIsWarning,
    BuildingStuctureSubmit,

    //Building Unit
    buildingUnitData,
    BuildingUnitChange,
    allRooms,
    setAllRooms,
    allEnergyMeters,
    setAllEnergyMeters,
    handleUnitFileDelete,

    //landlord
    landlordOptions,
    LandlordChange,
    landLordData,
    setLandlordAddress,
    landlordIdChange,
    handleLandlordFileDelete,

    //bank accounts
    allBankAccounts,
    setAllBankAccounts,

    //contacts
    allContacts,
    setAllContacts,

    //furniture
    allFurniture,
    setAllFurniture,

    //contractual
    ContractOptions,
    contractualData,
    ContractualChange,
    allMaturities,
    setAllMaturities,
    contractIdChange,
    isContactExist,
    handleContractFileDelete,

    //rent and cost
    rentAndCostData,
    RentAndCostChange,

    //extra cost
    allExtraCost,
    setAllExtraCost,

    isOpen,
    setIsOpen,
    modalContent,
    propertyId,
  } = ViewEditPropertyContainer();

  return (
    <Page
      title="View Details"
      description={`Let’s View your Property Details`}
      navigateLink={"/real-estate/property/list"}
    >
      <CustomCard>
        {action === "edit" && route === "property" ? (
          <AddPropertyForm
            type={"Edit"}
            data={PropertyData}
            handleChange={propertyChange}
            propertyIdOptions={propertyIdOptions}
            activeIndex={activeIndex}
            setSelectedAddress={setSelectedAddress}
            propertyIdChange={propertyIdChange}
          />
        ) : (
          <ViewPropertyComponent data={PropertyData} />
        )}
        <TabView
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            // handleTabChange(e?.index);
            // onSubmit(route);
            setTabToActive(e?.index);
            action === "edit" ? setIsOpen(true) : handleTabChange(e?.index);
          }}
        >
          <TabPanel header="Building Structure">
            {action === "edit" && route === "building-structure" ? (
              <BuildingStucture
                data={buildingStuctureData}
                handleChange={BuildingStuctureHandleChange}
                buildingOptions={buildingOptions}
                // fileKey={fileKey}
                // sturcureDocuments={sturcureDocuments}
                // fileChange={handleStructureFileChange}
                fileDelete={handleStructureFileDelete}
              />
            ) : (
              <ViewBuildingStructure data={buildingStuctureData} />
            )}
          </TabPanel>

          <TabPanel header="Building Unit">
            {action === "edit" && route === "building-unit" ? (
              <>
                <BuildingUnit
                  data={buildingUnitData}
                  handleChange={BuildingUnitChange}
                />
                <br />
                <AddRooms
                  maxSize={buildingUnitData?.size}
                  allRooms={allRooms}
                  setAllRooms={setAllRooms}
                />
                <br />
                <AddEnergyMeters
                  unitId={buildingUnitData?.id}
                  allEnergyMeters={allEnergyMeters}
                  setAllEnergyMeters={setAllEnergyMeters}
                />
                <br />
                <ContractAndUnitFilesComponent
                  data={buildingUnitData?.building_document}
                  handleChange={BuildingUnitChange}
                  fileDelete={handleUnitFileDelete}
                />
              </>
            ) : (
              <ViewBuildingUnit
                data={buildingUnitData}
                allRooms={allRooms}
                setAllRooms={setAllRooms}
                allEnergyMeters={allEnergyMeters}
                setAllEnergyMeters={setAllEnergyMeters}
              />
            )}
          </TabPanel>

          <TabPanel header="Landlord Info">
            {action === "edit" && route === "landlord-info" ? (
              <>
                {" "}
                <LandlordInfo
                  data={landLordData}
                  handleChange={LandlordChange}
                  landlordOptions={landlordOptions}
                  landlordIdChange={landlordIdChange}
                  setLandlordAddress={setLandlordAddress}
                />
                <br />
                {/* <BankingDetailForm
                  title="Banking Details"
                  data={landLordData}
                  handleChange={LandlordChange}
                  allBankAccounts={allBankAccounts}
                  setAllBankAccounts={setAllBankAccounts}
                />
                <br />
                <FurnitureForm
                  title="Landlord Furniture"
                  data={landLordData}
                  handleChange={LandlordChange}
                  allFurniture={allFurniture}
                  setAllFurniture={setAllFurniture}
                />
                <br /> */}
                <AddContacts
                  mode={"edit"}
                  title="Add New Contact"
                  allContacts={allContacts}
                  setAllContacts={setAllContacts}
                  landlordId={landLordData?.id}
                />
                <br />
                <LandlordFilesComponent
                  data={landLordData}
                  handleChange={LandlordChange}
                  fileDelete={handleLandlordFileDelete}
                />
              </>
            ) : (
              <>
                <ViewLandlordInfo type="property" data={landLordData} />
                <br />
                {/* <ViewBankDetails
                  type="property"
                  data={landLordData}
                  allBankAccounts={allBankAccounts}
                  setAllBankAccounts={setAllBankAccounts}
                />
                <br />
                <ViewFurniture
                  type="property"
                  data={landLordData}
                  allFurniture={allFurniture}
                  setAllFurniture={setAllFurniture}
                />
                <br /> */}
                <CustomLandlordContactTable
                  // action="property"
                  landlordId={landLordData?.id}
                  title={"Contacts"}
                  allContacts={allContacts}
                  setAllContacts={setAllContacts}
                />
                <br />
                {<ViewLandlordFiles files={landLordData?.landlord_documents} />}
              </>
            )}
          </TabPanel>

          <TabPanel header="Contractual Info">
            {action === "edit" && route === "contractual-info" ? (
              <ContractualInfoForm
                mode="edit"
                landlordId={landLordData?.id}
                propertyId={propertyId}
                ContractOptions={ContractOptions}
                data={contractualData}
                handleChange={ContractualChange}
                allMaturities={allMaturities}
                setAllMaturities={setAllMaturities}
                contractIdChange={contractIdChange}
                isContactExist={isContactExist}
                fileDelete={handleContractFileDelete}
                allBankAccounts={allBankAccounts}
                setAllBankAccounts={setAllBankAccounts}
                allFurniture={allFurniture}
                setAllFurniture={setAllFurniture}
              />
            ) : (
              <ViewContractualInfo
                type="property"
                landlordId={landLordData?.id}
                data={contractualData}
                allMaturities={allMaturities}
                setAllMaturities={setAllMaturities}
                allBankAccounts={allBankAccounts}
                setAllBankAccounts={setAllBankAccounts}
                allFurniture={allFurniture}
                setAllFurniture={setAllFurniture}
              />
            )}
          </TabPanel>

          <TabPanel header="Rent & Cost">
            {action === "edit" && route === "rent-and-cost" ? (
              <RentAndCost
                data={rentAndCostData}
                handleChange={RentAndCostChange}
                allExtraCost={allExtraCost}
                setAllExtraCost={setAllExtraCost}
                movingInDate={contractualData?.movingDate}
              />
            ) : (
              <ViewRentAndCost
                type="property"
                isEditable={true}
                contractId={contractualData?.id}
                data={rentAndCostData}
                allExtraCost={allExtraCost}
                setAllExtraCost={setAllExtraCost}
                movingInDate={contractualData?.movingDate}
              />
            )}
          </TabPanel>

          <TabPanel header="Annual Statement">
            <PropertyAnnualStatementList propertyId={propertyId} />
          </TabPanel>
        </TabView>
      </CustomCard>
      {action === "edit" && (
        <Buttonlayout>
          <CustomButton
            onClick={() => onSubmit(route)}
            label="Save Changes"
            loading={loading}
          />
          <CustomButtonOutlined label="Cancel" onClick={() => handleCancel()} />
        </Buttonlayout>
      )}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading={"Alert!"}
        body={modalContent}
        submitLabel={"Save Changes"}
        onSubmit={() => onSubmit(route)}
        cancelLabel={"Discard"}
        onCancel={() => handleCancel()}
        loading={loading}
      />

      <CustomModal
        isOpen={isWarning}
        setIsOpen={setIsWarning}
        heading={"Warning"}
        body={() => (
          <>Please review and ensure the building information is correct.</>
        )}
        submitLabel={"Continue"}
        onSubmit={BuildingStuctureSubmit}
      />
    </Page>
  );
};

export default React.memo(ViewPropertyForm);
