import React, { useEffect, useRef, useState } from "react";
import {
  CustomAutoComplete,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../AllInputs";
import CustomButton from "../CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import { ContactOptions, ContactRoleOptions } from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import { useDispatch } from "react-redux";
import CustomModal from "../Modals/CustomModal";
import {
  deleteContactAction,
  getContactAction,
  getContactListAction,
  unlinkContactAction,
} from "store/actions/landlordActions";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import { useLocation, useParams } from "react-router-dom";
import { debounce, toNumber } from "lodash";
import { initialContactsInput } from "shared/initialInputs";

const AddContacts = ({ title, allContacts, setAllContacts, landlordId, mode }) => {
  const params = useParams()
  const location = useLocation()
  const pathSegments = location?.pathname.split('/');
  const action = pathSegments[2]
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [contactList, setContactList] = useState([]);
  const [contactOpt, setContactOpt] = useState([]);
  const [selectedContact, setSelectedContact] = useState({ index: "", contact: "" });
  const ignoreInputs = ["status", "id", "property"];
  const [optional, setOptional] = useState([""]);
  // const optional = ["office_phone", "email"];
  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() =>
          HandleAddNew(initialContactsInput, allContacts, setAllContacts)
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected contact from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleDeleteContact = () => {
    if (idToDelete?.id) {
      if (action === "property") {
        dispatch(
          unlinkContactAction(idToDelete?.id, params?.id, setLoading, dispatch, (res) => {
            handleDeleteNew(allContacts, setAllContacts, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          })
        )
      } else {
        dispatch(
          deleteContactAction(idToDelete?.id, setLoading, dispatch, (res) => {
            handleDeleteNew(allContacts, setAllContacts, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          })
        );
      }
    } else {
      handleDeleteNew(allContacts, setAllContacts, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getContactInfo = (id, index) => {
    dispatch(
      getContactAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allContacts, setAllContacts);
        }
      })
    );
  };

  const handleOptionalInputs = ({ index, name, value }) => {
    const values = [...allContacts];
    if (name === "mobile") {
      if (!values[index]?.office_phone) {
        delete values[index]?.formErrors?.office_phone;
      }

      if (!values[index]?.email) {
        delete values[index]?.formErrors?.email;
      }

      setOptional(["office_phone", "email"]);
    } else if (name === "office_phone") {
      if (!values[index]?.office_phone) {
        delete values[index]?.formErrors?.mobile;
      }

      if (!values[index]?.email) {
        delete values[index]?.formErrors?.email;
      }

      setOptional(["mobile", "email"]);
    } else if (name === "email") {
      if (!values[index]?.office_phone) {
        delete values[index]?.formErrors?.office_phone;
      }

      if (!values[index]?.email) {
        delete values[index]?.formErrors?.mobile;
      }
      setOptional(["mobile", "office_phone"]);
    }
    setAllContacts(values);
  };

  const handleSaveContacts = (index) => {
    let innerOptional = [""];
    const myObj = allContacts[index];
    const { type, email, mobile, name, office_phone, role } = myObj;

    if (!email || !mobile || !office_phone || !type || !name || !role) {
      handleSaveNew(index, allContacts, setAllContacts, ignoreInputs);
    }
    if (type && email) {
      innerOptional = ["mobile", "office_phone"];
    } else if (type && mobile) {
      innerOptional = ["email", "office_phone"];
    } else if (type && office_phone) {
      innerOptional = ["email", "mobile"];
    }

    if (type && (email || mobile || office_phone)) {
      handleSaveNew(
        index,
        allContacts,
        setAllContacts,
        ignoreInputs,
        innerOptional
      );
      setOptional([]);
    }
  };

  const nameChange = useRef(debounce((landlordId, value) => {
    getContactList(landlordId, value)
  }, 1000));

  const getContactList = (landlordId, name) => {
    dispatch(
      getContactListAction(landlordId, name, (res) => {
        setContactList(res)
      })
    );
  }

  useEffect(() => {
    setContactOpt(contactList)
  }, [])

  useEffect(() => {
    const options = contactList?.map((option) => {
      const isDisabled = allContacts?.some(contact => contact?.id && contact?.id === option?.id);
      return {
        ...option,
        disabled: isDisabled,
      };
    });
    setContactOpt(options);

  }, [allContacts, contactList]);

  useEffect(() => {
    if ((selectedContact?.index != -1 || selectedContact?.index != "") && selectedContact?.contact) {
      const contact = selectedContact?.contact
      const contacts = [...allContacts]
      contacts[selectedContact?.index] = {
        type: contact?.type || "",
        name: contact?.name || "",
        role: contact?.role,
        id: contact?.id || "",
        mobile: contact?.mobile || "",
        office_phone: contact?.office_phone,
        email: contact?.email,
        property: contact?.property,
      }
      setAllContacts(contacts)
      setSelectedContact({ index: "", contact: "" })
    }
  }, [selectedContact?.index])

  return (
    <CustomForm title={title} header={header}>
      <div className="overflow-auto">
        {/*  error-issue */}
        <table className="financial-table editor-container w-full mt-3 ">
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Type</th>
              <th style={{ width: "200px" }}>Name</th>
              <th style={{ width: "200px" }}>Role</th>
              <th style={{ width: "200px" }}>Mobile</th>
              <th style={{ width: "200px" }}>Office Phone</th>
              <th style={{ width: "200px" }}>Emails</th>
              <th style={{ width: "20px", marginTop: "30px" }}>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allContacts?.map((contact, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ width: "200px" }} className="pb-0 vertical-align-top"> 
                      <CustomDropDown
                        value={contact?.type}
                        onChange={(e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          )
                        }
                        name="type"
                        col={12}
                        extraClassName="p-0"
                        options={ContactOptions}
                        disabled={contact?.status === "save" ? true : false}
                        ignoreLabel
                        // errorMessage={contact?.formErrors?.type}
                      />
                    </td>
                    <td style={{ minWidth: "200px" }} className="pb-0 vertical-align-top">
                      {/* <CustomInput
                        disabled={contact?.status === "save"}
                        type="text"
                        name="name"
                        extraClassName="w-full"
                        value={contact?.name}
                        onChange={(e) => {
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          );
                        }}
                        ignoreLabel
                        errorMessage={contact?.formErrors?.name}
                      /> */}
                      <CustomAutoComplete
                        field={"contact"}
                        value={contact?.name}
                        onChange={(e) => {
                          {
                            const value = e?.target?.value;
                            value?.id && setSelectedContact({ index: index, contact: value })
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value?.name || e?.target?.value,
                              index,
                              allContacts,
                              setAllContacts,
                              ignoreInputs,
                              optional
                            );
                          }
                        }}
                        name="name"
                        suggestions={contactOpt}
                        editable
                        panelClassName="dropdown-option-color"
                        // placeholder="Enter IBAN"
                        ignoreLabel
                        extraClassName="w-full p-0"
                        // disabled={contact?.status === "save"}
                        disabled={contact?.status === "save" || (mode === "edit" && contact?.property? contact?.property.includes(toNumber(params?.id)) : false)}
                        search={(e) => nameChange.current(landlordId, e.query)}
                      />
                    </td>
                    <td style={{ minWidth: "200px" }} className="pb-0 vertical-align-top">
                      <CustomDropDown
                        value={contact?.role}
                        onChange={(e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          )
                        }
                        name="role"
                        extraClassName="w-full p-0"
                        options={ContactRoleOptions}
                        disabled={contact?.status === "save" ? true : false}
                        ignoreLabel
                        // errorMessage={contact?.formErrors?.role}
                      />
                    </td>
                    <td style={{ minWidth: "200px" }} className="pb-0 vertical-align-top">
                      <CustomPhoneInput
                        disabled={contact?.status === "save"}
                        data={contact}
                        extraClassName="w-full p-0"
                        onChange={({ name, value }) => {
                          handleOptionalInputs({
                            index: index,
                            name: name,
                            value: value,
                          });
                          handleChangeNew(
                            name,
                            value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          );
                        }}
                        name="mobile"
                        required
                        ignoreLabel
                        ignoreError
                        // errorMessage={contact?.formErrors?.mobile}
                      />
                    </td>
                    <td style={{ minWidth: "200px" }} className="pb-0 vertical-align-top">
                      <CustomPhoneInput
                        disabled={contact?.status === "save"}
                        data={contact}
                        extraClassName="w-full p-0"
                        onChange={(e) => {
                          handleOptionalInputs({
                            index: index,
                            name: e?.name,
                            value: e.value,
                          });
                          handleChangeNew(
                            e?.name,
                            e?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          );
                        }}
                        name="office_phone"
                        required
                        ignoreLabel
                        ignoreError
                        // errorMessage={contact?.formErrors?.office_phone}
                      />
                    </td>
                    <td style={{ minWidth: "200px" }} className="pb-0 vertical-align-top">
                      <CustomInput
                        disabled={contact?.status === "save"}
                        type="text"
                        name="email"
                        extraClassName="w-full p-0"
                        value={contact?.email}
                        onChange={(e) => {
                          handleOptionalInputs({
                            index: index,
                            name: e?.name,
                            value: e.value,
                          });
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          );
                        }}
                        ignoreLabel
                        // errorMessage={contact?.formErrors?.email}
                      />
                    </td>
                    {contact?.status === "save" ? (
                      <td style={{ minWidth: "20px" }}>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(allContacts, setAllContacts, index)
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({
                                  id: contact?.id,
                                  index: index,
                                });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td style={{ minWidth: "20px" }}>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              handleSaveContacts(index);
                              // let optionalInputs = [""];
                              // if (
                              //   !contact?.mobile &&
                              //   !contact?.office_phone &&
                              //   !contact?.email
                              // ) {
                              //   optionalInputs = [""];
                              // } else {
                              //   optionalInputs = optional;
                              // }
                              // handleSaveNew(
                              //   index,
                              //   allContacts,
                              //   setAllContacts,
                              //   ignoreInputs,
                              //   optionalInputs
                              // );
                            }}
                          >
                            <img src={SaveIcon} />
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (contact?.id) {
                                getContactInfo(contact?.id, index);
                              } else {
                                handleDeleteNew(
                                  allContacts,
                                  setAllContacts,
                                  index
                                );
                              }
                            }}
                          >
                            <img src={CrossIcon} />
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                  {(contact?.formErrors?.email || contact?.formErrors?.mobile || contact?.formErrors?.name || contact?.formErrors?.office_phone || contact?.formErrors?.role || contact?.formErrors?.type) && 
                  <tr>
                    <td><small className="p-error">{contact?.formErrors?.type}</small></td>
                    <td><small className="p-error">{contact?.formErrors?.name}</small></td>
                    <td><small className="p-error">{contact?.formErrors?.role}</small></td>
                    <td><small className="p-error">{contact?.formErrors?.mobile}</small></td>
                    <td><small className="p-error">{contact?.formErrors?.office_phone}</small></td>
                    <td><small className="p-error">{contact?.formErrors?.email}</small></td>
                  </tr>}
                  <CustomModal
                    isOpen={deleteModal}
                    setIsOpen={setDeleteModal}
                    heading={"Delete"}
                    body={modalContent}
                    submitLabel={"Confirm"}
                    onSubmit={handleDeleteContact}
                    loading={loading}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
    </CustomForm>
  );
};

export default AddContacts;
