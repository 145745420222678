import React, { useEffect, useRef, useState } from "react";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";

import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import {
  ExtraCostPayemntType,
  ExtraCostType,
  YesNoOptions,
} from "../AllDropDownOptions";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import {
  deleteExtraCostAction,
  getExtraCostAction,
} from "store/actions/contractActions";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import { isFirstDayOfMonth } from "utils/commonFunctions";

const AddExtraCost = ({ allExtraCost, setAllExtraCost, movingInDate }) => {
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const InputObj = {
    id: "",
    type: "",
    payment_type: "",
    amount: "",
    amount_first_month: "",
    amount_last_month: "",
    applicable_tenant_rent: "",
    status: "",
  };
  const ignoreInputs = ["status", "id"];
  const [optionalInputs, setOptionalInputs] = useState(["amount_last_month"])

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(InputObj, allExtraCost, setAllExtraCost)}
      />
    </div>
  );

  useEffect(() => {
    if (movingInDate) {
      let isFirstDate = isFirstDayOfMonth(movingInDate);
      if(isFirstDate){
        setOptionalInputs(["amount_last_month", "amount_first_month"])
      }else{
        setOptionalInputs(["amount_last_month"])
      }
    }
  }, [movingInDate]);

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected extra cost from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteExtraCost = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteExtraCostAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(allExtraCost, setAllExtraCost, idToDelete?.index);

          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allExtraCost, setAllExtraCost, idToDelete?.index);

      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getCostInfo = (id, index) => {
    dispatch(
      getExtraCostAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allExtraCost, setAllExtraCost);
        }
      })
    );
  };

  return (
    <>
      <CustomForm title={"Add Extra Costs"} header={header}>
        <Toast ref={msgs} />
        <div className="overflow-auto">
          <table className="financial-table editor-container w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th>Type</th>
                <th>Payment Type</th>
                <th>Amount</th>
                <th>Amount First Month</th>
                <th>Amount Last Month</th>
                <th>Applicable for tenant Rent</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="" className="cell-width">
              {allExtraCost?.map((cost, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{minWidth:"225px",maxWidth: "250px"}}>
                        <CustomDropDown
                          value={cost?.type}
                          col={12}
                          extraClassName="w-full p-0"
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost,
                              ignoreInputs,
                              optionalInputs
                            )
                          }
                          name="type"
                          options={ExtraCostType}
                          disabled={cost?.status === "save" ? true : false}
                          ignoreLabel
                          errorMessage={cost?.formErrors?.type}
                        />
                      </td>
                      <td>
                        <CustomDropDown
                          value={cost?.payment_type}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost,
                              ignoreInputs,
                              optionalInputs
                            )
                          }
                          name="payment_type"
                          col={12}
                          extraClassName="w-full p-0"
                          options={ExtraCostPayemntType}
                          disabled={cost?.status === "save" ? true : false}
                          ignoreLabel
                          errorMessage={cost?.formErrors?.payment_type}
                        />
                      </td>
                      <td>
                        <CustomAmountInput
                          value={cost?.amount}
                          col={12}
                          extraClassName="w-full p-0"
                          onChange={(e) =>
                            handleChangeNew(
                              "amount",
                              e?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost,
                              ignoreInputs,
                              optionalInputs
                            )
                          }
                          name="amount"
                          ignoreLabel
                          disabled={cost?.status === "save" ? true : false}
                          errorMessage={cost?.formErrors?.amount}
                        />
                      </td>
                      <td style={{ "minWidth": "220px" }}>
                        <CustomAmountInput
                          value={cost?.amount_first_month}
                          col={12}
                          extraClassName="w-full p-0"
                          onChange={(e) =>
                            handleChangeNew(
                              "amount_first_month",
                              e?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost,
                              ignoreInputs,
                              optionalInputs
                            )
                          }
                          name="amount_first_month"
                          ignoreLabel
                          disabled={cost?.status === "save" ? true : false}
                          errorMessage={cost?.formErrors?.amount_first_month}
                        />
                      </td>
                      <td style={{ "minWidth": "220px" }}>
                        <CustomAmountInput
                          value={cost?.amount_last_month}
                          col={12}
                          extraClassName="w-full p-0"
                          onChange={(e) =>
                            handleChangeNew(
                              "amount_last_month",
                              e.value,
                              index,
                              allExtraCost,
                              setAllExtraCost,
                              ignoreInputs,
                              optionalInputs
                            )
                          }
                          name="amount_last_month"
                          ignoreLabel
                          disabled={cost?.status === "save" ? true : false}
                          errorMessage={cost?.formErrors?.amount_last_month}
                        />
                      </td>
                      <td style={{ "minWidth": "235px" }}>
                        <CustomDropDown
                          value={cost?.applicable_tenant_rent}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost,
                              ignoreInputs,
                              optionalInputs
                            )
                          }
                          name="applicable_tenant_rent"
                          col={12}
                          extraClassName="w-full p-0"
                          options={YesNoOptions}
                          disabled={cost?.status === "save" ? true : false}
                          ignoreLabel
                          errorMessage={
                            cost?.formErrors?.applicable_tenant_rent
                          }
                        />
                      </td>
                      {cost?.status === "save" ? (
                        <td style={{ "minWidth": "20px" }}>
                          <CustomOverlay ref={openAction}>
                            <ul className="no-style p-0">
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(
                                    allExtraCost,
                                    setAllExtraCost,
                                    index
                                  )
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              {/* )} */}
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  setIdToDelete({ id: cost?.id, index: index });
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td style={{ "minWidth": "20px" }}>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() =>
                                handleSaveNew(
                                  index,
                                  allExtraCost,
                                  setAllExtraCost,
                                  ignoreInputs,
                                  optionalInputs
                                )
                              }
                            >
                              <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (cost.id) {
                                  getCostInfo(cost?.id, index);
                                } else {
                                  handleDeleteNew(
                                    allExtraCost,
                                    setAllExtraCost,
                                    index
                                  );
                                }
                              }}
                            >
                              <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>

                    <CustomModal
                      isOpen={deleteModal}
                      setIsOpen={setDeleteModal}
                      heading={"Delete"}
                      body={modalContent}
                      submitLabel={"Confirm"}
                      onSubmit={handleDeleteExtraCost}
                      loading={loading}
                    />
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
    </>
  );
};

export default AddExtraCost;
