import React from "react";
import { CustomViewInput } from "shared/AllViewInputs";
import { CustomForm } from "shared/AllInputs";
import CustomTableContainer from "../../../../Tables/CustomTableContainer";

const MaintenanceLandlordView = ({ data, selectedItem, setSelectedItem }) => {
  const columns = [
    {
      name: "Type",
      accessor: "type",
    },
    {
      name: "Name",
      accessor: "name",
    },
    {
      name: "Role",
      accessor: "role",
    },
    {
      name: "Personal phone",
      accessor: "mobile",
    },
    {
      name: "Office phone",
      accessor: "office_phone",
    },
    {
      name: "Email",
      accessor: "email",
    },
  ];
  return (
    <>
      <CustomForm title="Landlord Info">
        <CustomViewInput data={data} name="email" />
        <CustomViewInput data={data} name="address" />
        <CustomViewInput data={data} name="contact" />
      </CustomForm>
      <div className="title">Landlord Contact</div>
      <CustomTableContainer
        list={data?.contacts}
        columns={columns}
        selectedItems={selectedItem}
        setSelectedItems={setSelectedItem}
        selectionMode={"single"}
      />
    </>
  );
};

export default MaintenanceLandlordView;
