import React, { useEffect, useState, useRef } from "react";
import { CustomDropDown, CustomForm } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAdd,
  handleCancelNew,
  handleDeleteNew,
  handlEdit,
  handleObjChange,
  handleSaveNew,
} from "./commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOfficeEmployeeAction,
  getOfficeEmpDetailsAction,
  getOfficeListAction,
} from "store/actions/officeActions";
import { getUserInfo } from "services/auth";
import { PositionOptions } from "../AllDropDownOptions";
import CustomOverlay from "shared/CustomOverlay";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import CustomModal from "shared/Modals/CustomModal";
const AddOffices = ({ title, allOffices, setAllOffices }) => {
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const { profile } = useSelector((state) => state.user);
  const InputObj = { id: null, office: null, position: null, status: null };
  const errorObj = { office: null, position: null };
  const [errors, setErrors] = useState([]);
  const ignoreInputs = ["status", "id"];
  const [officeList, setOfficeList] = useState([]);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    if (info?.company) {
      getOfficeList(info?.company);
    }
  }, [profile]);

  const getOfficeList = (company) => {
    dispatch(
      getOfficeListAction(company, (res) => {
        if (res?.success) {
          let options = res?.data.results.map((office) => {
            return { name: office?.name, value: office?.id };
          });
          setOfficeList(options);
        }
      })
    );
  };

  useEffect(() => {
    if (allOffices?.length) {
      let allErrors = [...errors];
      let myErrors = allOffices?.map((office, officeIndex) => {
        let error = allErrors.find(
          (err, errorIndex) => errorIndex === officeIndex
        );
        if (error) {
          return error;
        } else {
          return errorObj;
        }
      });
      setErrors(myErrors);
    }
  }, [allOffices]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() =>
          HandleAdd(
            InputObj,
            allOffices,
            setAllOffices,
            errorObj,
            errors,
            setErrors
          )
        }
      />
    </div>
  );

  useEffect(() => {
    setOfficeOptions(officeList)
  }, [])

  const actions = (item, index) => {
    return (
      <>
        {item.status === "save" ? (
          <CustomOverlay ref={openAction}>
            <ul className="no-style p-0">
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => handlEdit(allOffices, setAllOffices, index)}
              >
                <i className="pi pi-file-edit text-dark"></i>
                Edit
              </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setIdToDelete({ id: item?.id, index: index });
                  setDeleteModal(true);
                }}
              >
                <i className="pi pi-trash cursor-pointer text-dark"></i>
                Delete
              </li>
            </ul>
          </CustomOverlay>
        ) : (
          <div className="d-flex gap-3">
            <a
              className="cursor-pointer"
              onClick={() => {
                handleSaveNew(index, allOffices, setAllOffices, ignoreInputs);
                const options = allOffices
                  ?.map((office) => {
                    return officeList?.filter(
                      (opt) => opt?.value !== office?.office
                    );
                  })
                  .flat();
                setOfficeOptions(options);
              }}
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={() => {
                if (item.id) {
                  getItemDetails(item.id, index);
                } else {
                  handleDeleteNew(allOffices, setAllOffices, index);
                }
              }}
            >
              <img src={CrossIcon} />
            </a>
          </div>
        )}
      </>
    );
  };

  const getItemDetails = (id, index) => {
    dispatch(
      getOfficeEmpDetailsAction(id, (res) => {
        let data = {
          id: res?.id,
          position: res?.position,
          employee: res?.employee?.id,
        };
        handleCancelNew(data, index, allOffices, setAllOffices);
      })
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected office from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleDeleteOffice = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteOfficeEmployeeAction(
          idToDelete?.id,
          setLoading,
          dispatch,
          (res) => {
            handleDeleteNew(allOffices, setAllOffices, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          }
        )
      );
    } else {
      handleDeleteNew(allOffices, setAllOffices, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    const options = officeList?.map((option) => {
      const isDisabled = allOffices?.some(office => office?.office === option.value);
      return {
        ...option,
        disabled: isDisabled,
      };
    });
  
    setOfficeOptions(options);
  }, [allOffices, officeList]);


  return (
    <>
      <CustomForm title={title ? title : "Office"} header={header}>
        <div className="overflow-auto">
          <table className="financial-table w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th style={{width: "45%"}}>Select Office</th>
                <th style={{width: "45%"}}>Select Position</th>
                <th style={{width: "10%"}}>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form">
              {allOffices?.map((office, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomDropDown
                          data={office}
                          col={12}
                          onChange={(e) =>
                            handleObjChange(
                              allOffices,
                              setAllOffices,
                              errors,
                              setErrors,
                              ignoreInputs,
                              e?.target?.name,
                              e?.target?.value,
                              index
                            )
                          }
                          name="office"
                          options={officeOptions}
                          disabled={office?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomDropDown
                          data={office}
                          onChange={(e) =>
                            handleObjChange(
                              allOffices,
                              setAllOffices,
                              errors,
                              setErrors,
                              ignoreInputs,
                              e?.target?.name,
                              e?.target?.value,
                              index
                            )
                          }
                          name="position"
                          col={12}
                          options={PositionOptions}
                          disabled={office?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>{actions(office, index)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteOffice}
        loading={loading}
      />
    </>
  );
};

export default AddOffices;
